import { Category, Product } from "../../../api/shop/basic/types";

export function canAddProductWithAnotherAttributes(product: Product) {
	return product.attribute_groups.some(x => x.attributes.length > 1);
}

export function isProductInCategory(product: Product, category: Category): boolean {
	return (
		product.categories?.includes(category.id) ||
		category.children?.some(child => isProductInCategory(product, child)) ||
		false
	);
}
