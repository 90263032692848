import { useEffect, useMemo, useRef, useState } from "react";
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";

import ProductCard, { GridProductCardProps } from "./ProductCard";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import { Category, Product } from "../../../api/shop/basic/types";
import scrollToProductsTop from "../../helpers";
import { useSelectedStoreContext } from "../../SelectedStore/context";
import useScreenService from "../../../services/useScreenService";
import { Alert, Box, Grid, Skeleton, Typography } from "@mui/material";
import { IFavoriteProduct } from "../../../api/shop/favorites/types";
import { ViewType } from "../../../services/useViewService";
import useAppContext from "../../../useAppContext";
import ProductCardSkeleton from "./ProductCardSkeleton";
import useProductViewSize from "./hooks/useProductsViewSize";
import { useRecoilValue } from "recoil";
import ProductsParamsState from "../../services/useNewProductsService/ProductsParamsState";
import {
	canAddProductWithAnotherAttributes,
	isProductInCategory,
} from "../../services/useNewProductsService/helpers";
import useGetProducts from "../../services/useNewProductsService/useGetProducts";
import { getErrorText } from "../../../helpers/errors";

interface ProductsProps {
	view: ViewType;
	isFavorites?: boolean;
}

export default function Products({ view, isFavorites = false }: ProductsProps) {
	const { localisation } = useAppContext();

	const { favoritesService, categoriesService } = useSelectedStoreContext();

	const [lastProductsLength, setLastProductsLength] = useState<number | null>(null);

	const productsQuery = useGetProducts(!isFavorites);

	const products = isFavorites
		? favoritesService.favorites?.favorite_products?.map((x: IFavoriteProduct) => x.product) ||
			[]
		: productsQuery.data?.pages.flatMap(page => page.data) || [];

	const fatherCategoryId = categoriesService.selectedCategoryId || "top";
	const categories = categoriesService.getCategories(fatherCategoryId);

	const firstNotFullIDX =
		categories?.findIndex(category => {
			if (category.products_count < 1) {
				return false;
			}

			return (
				category.products_count >
				products.filter(product => isProductInCategory(product, category)).length
			);
		}) ?? -1;

	const filledCategories =
		(firstNotFullIDX !== -1 ? categories?.slice(0, firstNotFullIDX + 1) : categories) || [];

	useEffect(() => {
		if (products.length) {
			setLastProductsLength(products.length);
		}
	}, [products.length]);

	console.log("products query", { productsQuery });

	return (
		<Box mt={2} width={"100%"} id={"list-container"}>
			{!isFavorites && productsQuery.isPending ? (
				<ProductsSkeleton view={view} count={lastProductsLength || undefined} />
			) : !isFavorites && productsQuery.isError ? (
				<Alert severity={"error"}>{getErrorText(productsQuery.error)}</Alert>
			) : !products.length ? (
				<div className="w-100 text-center small text-muted">
					{localisation.menu.noProducts}
				</div>
			) : (
				<>
					<ProductsList
						view={view}
						products={products}
						isFavorites={isFavorites}
						filledCategories={filledCategories}
					/>
					{!isFavorites && productsQuery.isFetchingNextPage && (
						<ProductsSkeleton view={view} />
					)}
					{!isFavorites && (
						<BackToTopOrLoadMoreButton
							view={view}
							isEndOfList={!productsQuery.hasNextPage}
							hideBackToTop={products.length < 6}
							isFetching={productsQuery.isFetching}
							isFetchingNextPage={productsQuery.isFetchingNextPage}
							fetchNextPage={productsQuery.fetchNextPage}
							fetchStatus={productsQuery.status}
						/>
					)}
				</>
			)}
		</Box>
	);
}

interface BackToTopOrLoadMoreButtonProps {
	view: ViewType;
	isEndOfList?: boolean;
	hideBackToTop?: boolean;
	isFetching?: boolean;
	fetchNextPage: () => void;
	isFetchingNextPage?: boolean;
	fetchStatus?: string;
}

function BackToTopOrLoadMoreButton({
	view,
	isEndOfList,
	hideBackToTop,
	isFetching,
	fetchNextPage,
	isFetchingNextPage,
	fetchStatus,
}: BackToTopOrLoadMoreButtonProps) {
	const { localisation, appearance } = useAppContext();
	const { isMobile } = useScreenService();
	const currentScroll = useRef<number>(0);
	const shopFooterHeight = useRef<number>(0);

	const handleClick = () => {
		if (isEndOfList) {
			return scrollToProductsTop();
		}

		if (!isFetching) {
			currentScroll.current = window.scrollY;
			fetchNextPage();
		}
	};

	const btnRef = useRef<HTMLDivElement | null>(null);
	const observer = useRef<IntersectionObserver | null>(null);

	useEffect(() => {
		shopFooterHeight.current =
			+(document.getElementById("shop-footer")?.getBoundingClientRect()?.height || 0) + 48;
	}, []);

	useEffect(() => {
		if (
			currentScroll &&
			currentScroll.current > 0 &&
			!isFetchingNextPage &&
			fetchStatus === "success" &&
			document?.documentElement?.scrollHeight - window.scrollY <= window.innerHeight * 2 &&
			window.innerHeight - shopFooterHeight?.current <= shopFooterHeight?.current
		) {
			window.scrollTo(0, currentScroll.current);
			console.log("scrolling to", currentScroll.current);
		}
	}, [isFetchingNextPage, fetchStatus, shopFooterHeight]);

	useEffect(() => {
		if (isFetching || !appearance.appearanceQuery?.show_more_infinite) return;

		if (observer.current) observer.current.disconnect();

		observer.current = new IntersectionObserver(
			entries => {
				if (entries[0].isIntersecting) {
					currentScroll.current = window.scrollY;
					fetchNextPage();
				}
			},
			{ threshold: 0.3 }
		);

		if (btnRef.current) {
			observer.current.observe(btnRef.current);
		}

		return () => {
			if (observer.current) observer.current.disconnect();
		};
	}, [fetchNextPage, appearance.appearanceQuery?.show_more_infinite, isFetching]);

	if (isEndOfList && hideBackToTop) {
		return null;
	}

	return (
		<div className={"w-100 mt-2 border"} ref={btnRef}>
			{!appearance.appearanceQuery?.show_more_infinite && (
				<MDBBtn
					outline
					size={"lg"}
					onClick={() => handleClick()}
					className={`w-100 theme-button outline ${
						view === "list" && isMobile ? "" : ""
						// view === "list" && isMobile ? "rounded-0" : ""
					}`}
				>
					{isEndOfList ? (
						<>
							<span>{localisation.global.backToStart} </span>
							<MDBIcon
								fas
								icon="chevron-up"
								className="align-self-center"
								size={"lg"}
							></MDBIcon>
						</>
					) : (
						localisation.global.showMore
					)}
				</MDBBtn>
			)}
		</div>
	);
}

interface ProductsExistsProps extends ProductsProps {
	products: Product[];
	filledCategories: Category[];
}

function ProductsList({ view, products, isFavorites }: ProductsExistsProps) {
	const { categoriesService } = useSelectedStoreContext();
	const productsParamsState = useRecoilValue(ProductsParamsState);

	const fatherCategoryId = categoriesService.selectedCategoryId || "top";
	const categories = categoriesService.getCategories(fatherCategoryId);

	if (
		isFavorites ||
		productsParamsState.sort !== "categories" ||
		(!categories?.length && !categoriesService.selectedCategoryId)
	) {
		return (
			<Grid container columnSpacing={2} rowSpacing={view === "grid" ? 2 : 1} px={2}>
				{products?.map(
					product =>
						!!product && (
							<ProductComponent view={view} key={product.id} product={product} />
						)
				)}
			</Grid>
		);
	}

	return <ProductsByCategories view={view} products={products} />;
}

type ProductsByCategoriesGroupType = { products: Product[] } & (
	| {
			type: "category";
			category: Category;
	  }
	| {
			type: "not-available" | "other";
			category?: null | undefined;
	  }
);

interface ProductsByCategoriesProps {
	view: ViewType;
	products: Product[];
}

function ProductsByCategories({ products, view }: ProductsByCategoriesProps) {
	const { categoriesService } = useSelectedStoreContext();

	const fatherCategoryId = categoriesService.selectedCategoryId || "top";
	const categories = categoriesService.getCategories(fatherCategoryId);

	const { getActiveCategory } = categoriesService;
	const groups = useMemo(() => {
		const result: ProductsByCategoriesGroupType[] = [];

		products.forEach(product => {
			const productCategory = !product.is_available
				? undefined
				: product.list_category && product.list_category.id !== fatherCategoryId
					? categories?.find(category => category.id === product.list_category?.id)
					: !categories?.length && fatherCategoryId
						? getActiveCategory()
						: undefined;

			const productGroupType = productCategory
				? "category"
				: !product.is_available
					? "not-available"
					: "other";

			if (
				result.length &&
				result.at(-1)?.type === productGroupType &&
				(productGroupType !== "category" ||
					result.at(-1)?.category?.id === product.list_category?.id)
			) {
				result.at(-1)?.products.push(product);
			} else {
				result.push({
					type: productGroupType,
					category: productCategory,
					products: [product],
				} as ProductsByCategoriesGroupType);
			}
		});
		return result;
	}, [categories, fatherCategoryId, getActiveCategory, products]);

	return (
		<>
			{groups.map(group => (
				<ProductsByCategoriesGroup
					view={view}
					group={group}
					key={group.type === "category" ? `category-${group.category.id}` : group.type}
				/>
			))}
		</>
	);
}

interface ProductsSkeletonProps {
	view: ViewType;
	count?: number;
	hideHeader?: boolean;
}

function ProductsSkeleton({ view, count, hideHeader }: ProductsSkeletonProps) {
	const { computedGridViewSize } = useProductViewSize();

	if (count === undefined) {
		count = view === "grid" ? 12 / computedGridViewSize : 1;
	}

	return (
		<Grid container columnSpacing={2} rowSpacing={view === "grid" ? 2 : 1} px={2}>
			{!hideHeader && (
				<Grid item xs={12}>
					<Typography
						variant={"h5"}
						fontWeight={"bold"}
						mt={1}
						sx={{ userSelect: "none" }}
					>
						<Skeleton width={"100%"} />
					</Typography>
				</Grid>
			)}

			{[...Array(count).keys()].map(key => (
				<Grid
					item
					key={key}
					xs={view === "list" ? 12 : 6}
					lg={view === "list" ? 12 : computedGridViewSize}
				>
					<ProductCardSkeleton view={view} />
				</Grid>
			))}
		</Grid>
	);
}

type ProductsByCategoriesGroupProps = {
	view: ViewType;
	group: ProductsByCategoriesGroupType;
};

function ProductsByCategoriesGroup({ view, group }: ProductsByCategoriesGroupProps) {
	const { cartService, categoriesService } = useSelectedStoreContext();
	const localisation = useLocalisation();

	const activeCategory = categoriesService.getActiveCategory();
	const computedHeader = useMemo(() => {
		switch (group.type) {
			case "category":
				return group.category.name;
			case "not-available":
				return localisation.cart.notAvailable;
			case "other":
				return localisation.menu.categoryOtherProducts
					.replace("{category_name}", activeCategory?.name || "")
					.trimEnd();
		}
	}, [
		group.type,
		group.category?.name,
		localisation.cart.notAvailable,
		localisation.menu.categoryOtherProducts,
		activeCategory?.name,
	]);

	const getNeedAdditionalMarginBottom = (product: Product, index: number) => {
		if (cartService.getCartProduct(product.id) && canAddProductWithAnotherAttributes(product)) {
			return "";
		}
		if (index % 2 !== 0) {
			const prod = group.products[index - 1];
			if (
				prod &&
				cartService.getCartProduct(prod.id) &&
				canAddProductWithAnotherAttributes(prod)
			) {
				return "calc(0.875rem * 1.43 + 2px)";
			}
		} else {
			const prod = group.products[index + 1];
			if (
				prod &&
				cartService.getCartProduct(prod.id) &&
				canAddProductWithAnotherAttributes(prod)
			) {
				return "calc(0.875rem * 1.43 + 2px)";
			}
		}
		return "";
	};

	return (
		<Grid container columnSpacing={2} rowSpacing={view === "grid" ? 2 : 1} px={2}>
			<Grid item xs={12}>
				<Typography variant={"h5"} fontWeight={"bold"} mt={1} sx={{ userSelect: "none" }}>
					{computedHeader}
				</Typography>
			</Grid>

			{group.products.map((product, index) => (
				<ProductComponent
					view={view}
					key={product.id}
					product={product}
					additionalMarginBottom={getNeedAdditionalMarginBottom(product, index)}
				/>
			))}
		</Grid>
	);
}

interface ProductComponentProps extends GridProductCardProps {
	view: ViewType;
	product: Product;
}

function ProductComponent({ product, view, additionalMarginBottom }: ProductComponentProps) {
	const { computedGridViewSize } = useProductViewSize();

	return (
		<Grid
			id={`product-${product.id}`}
			item
			key={product.id}
			xs={view === "list" ? 12 : 6}
			lg={view === "list" ? 12 : computedGridViewSize}
		>
			<ProductCard
				view={view}
				product={product}
				additionalMarginBottom={additionalMarginBottom}
			/>
		</Grid>
	);
}
