import { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CategoriesServiceType, CategoriesType } from "./types";
import api from "../../../api";
import { Category, GetCategoriesArgs, Store } from "../../../api/shop/basic/types";
import { FiltersServiceType } from "../useFiltersService/types";
import { useShopContext } from "../../context";
import useAppContext from "../../../useAppContext";
import { useQuery } from "@tanstack/react-query";
import useSearchParamsFixed from "../../../features/hooks/useSearchParamsFixed";

export default function useCategoriesService(
	store: Store | null,
	filtersService: FiltersServiceType,
	productsSearch: string | null | undefined
): CategoriesServiceType {
	const {
		lang,
		showError,
		loadingManager: { getIsLoading, setIsLoading: lmSetIsLoading },
	} = useAppContext();
	const { brandInfo } = useShopContext();

	const [selectedCategoryId, _setSelectedCategoryId] = useState<number | null>(null);
	const [prevSelectedCategoryId, setPrevSelectedCategoryId] = useState<number | null>(null);
	const [categories, setCategories] = useState<CategoriesType>({});

	const lastRequestLang = useRef<string | null>(null);
	const [isLoaded, setIsLoaded] = useState(false);

	const [searchParams, setSearchParams] = useSearchParamsFixed();

	const isLangLoading = getIsLoading("categories-lang");
	const setIsLangLoading = useCallback(
		(isLoading: boolean) => {
			lmSetIsLoading("categories-lang", isLoading);
		},
		[lmSetIsLoading]
	);

	const setSelectedCategoryId = useCallback((value: SetStateAction<number | null>) => {
		_setSelectedCategoryId(value);
	}, []);

	useEffect(() => {
		setCategories({});
		if (lang && lastRequestLang.current && lastRequestLang.current !== lang) {
			setIsLangLoading(true);
		}
		setSelectedCategoryId(null);
	}, [store?.id, lang, setIsLangLoading, setSelectedCategoryId]);

	const setSelectedFilters = filtersService.setSelectedFilters;
	useEffect(() => {
		setSelectedFilters(null);
	}, [selectedCategoryId, setSelectedFilters]);

	const fetchCategories = useCallback(async () => {
		if (!store?.id || !lang) {
			return null;
		}

		const args: GetCategoriesArgs = {
			store_id: store.id,
			brand_id: brandInfo.id,
			filters_set_id: filtersService.filtersSetId,
			products_search: productsSearch,
		};

		try {
			lastRequestLang.current = lang;
			const response = await api.shop.basic.getCategoriesTree(args);

			return response.data;
		} catch (err) {
			console.log(err);
			showError(err);
			return null;
		} finally {
			setIsLoaded(true);
			setIsLangLoading(false);
		}
	}, [
		brandInfo.id,
		filtersService.filtersSetId,
		lang,
		productsSearch,
		showError,
		store?.id,
		setIsLangLoading,
	]);

	function buildCategoryMap(categories: Category[]) {
		const categoryMap: Record<string, Category[]> = {};

		function traverse(category: Category, parentId: string | number) {
			if (!categoryMap[parentId]) {
				categoryMap[parentId] = [];
			}
			categoryMap[parentId].push(category);
			if (category.children) {
				for (const child of category.children) {
					traverse(child, category.id);
				}
			}
		}

		for (const category of categories) {
			traverse(category, "top");
		}

		return categoryMap;
	}

	const { isFetching } = useQuery({
		queryKey: [
			"categories",
			brandInfo.id,
			filtersService.filtersSetId,
			lang,
			productsSearch,
			store?.id,
		],
		queryFn: async () => {
			const response = await fetchCategories();
			if (response) {
				const res = buildCategoryMap(response);
				setCategories(res);
				return true;
			}
			setCategories({});
			return false;
		},
		initialData: null,
	});

	const getCategoryById = useCallback(
		(id: number): Category | null => {
			const foundCategories = Object.values(categories)
				.map(x => x.find(cat => cat.id === id))
				.filter(x => !!x);
			return foundCategories[0] ? foundCategories[0] : null;
		},
		[categories]
	);

	const getCategories = useCallback(
		(fatherCategoryId: number | "top"): Category[] | null => {
			if (fatherCategoryId in categories) {
				return categories[fatherCategoryId] || null;
			} else {
				return null;
			}
		},
		[categories]
	);

	const isSelected = useCallback(
		(id: number | "top") => {
			if (id === "top") {
				return selectedCategoryId === null;
			} else {
				return selectedCategoryId === id;
			}
		},
		[selectedCategoryId]
	);

	const getActiveCategory = useCallback(() => {
		if (!selectedCategoryId) return null;
		return getCategoryById(selectedCategoryId);
	}, [getCategoryById, selectedCategoryId]);

	const areActiveChildCategories = useCallback(() => {
		const activeCategory = getActiveCategory();

		if (activeCategory) {
			return activeCategory.has_child_categories;
		}

		return !!categories?.top?.length;
	}, [getActiveCategory, categories]);

	const handleClick = useCallback(
		async (category: Category | "top") => {
			if (isFetching || isLangLoading) return;

			if (category === "top") {
				setSelectedCategoryId(null);
				setPrevSelectedCategoryId(null);
			} else {
				setSelectedCategoryId(prev => {
					setPrevSelectedCategoryId(prev);
					return category.id;
				});
			}
		},
		[isFetching, isLangLoading, setSelectedCategoryId]
	);

	const isExpanded = useCallback(
		(category: Category): boolean => {
			if (!category.has_child_categories) {
				return false;
			}

			if (selectedCategoryId === category.id) {
				return true;
			}

			if (!(category.id in categories)) {
				return false;
			}

			const childCategories = categories[category.id];

			return childCategories.some(childCategory => {
				if (childCategory.has_child_categories) {
					return isExpanded(childCategory);
				} else {
					return isSelected(childCategory.id);
				}
			});
		},
		[isSelected, categories, selectedCategoryId]
	);

	const isClickLoading = useCallback(
		(categoryId: number) => {
			return isFetching && categoryId === selectedCategoryId;
		},
		[isFetching, selectedCategoryId]
	);

	useEffect(() => {
		return () => {
			lmSetIsLoading("categories-lang", false);
		};
	}, [lmSetIsLoading]);

	const isAnyCategories = useMemo(() => {
		const categoriesCount = getCategories("top")?.length || 0;
		return categoriesCount > 0;
	}, [getCategories]);

	useEffect(() => {
		if (searchParams.get("category_id") && !!Object.values(categories).length) {
			handleClick(
				getCategoryById(parseInt(searchParams.get("category_id") as string)) || "top"
			).then(() =>
				setSearchParams(searchParams => {
					searchParams.delete("category_id");
					return searchParams;
				})
			);
		}
	}, [
		categories,
		getCategoryById,
		handleClick,
		searchParams,
		setSearchParams,
		setSelectedCategoryId,
	]);

	return useMemo(
		() => ({
			selectedCategoryId,
			setSelectedCategoryId,
			getCategories,
			getCategoryById,
			getActiveCategory,
			areActiveChildCategories,
			handleClick,
			isExpanded,
			isSelected,
			isClickLoading,
			isLoaded,
			prevSelectedCategoryId,
			isAnyCategories,
		}),
		[
			selectedCategoryId,
			setSelectedCategoryId,
			getCategories,
			getCategoryById,
			getActiveCategory,
			areActiveChildCategories,
			handleClick,
			isExpanded,
			isSelected,
			isClickLoading,
			isLoaded,
			prevSelectedCategoryId,
			isAnyCategories,
		]
	);
}
