import { useCallback, useEffect, useState } from "react";

import api from "../../../api";
import {
	BillingAddress,
	CreateOrderPayload,
	NotAvailableProduct,
	Order,
} from "../../../api/shop/order/types";
import useMergeState from "../../../helpers/useMergedState";
import { TgLink } from "../../../helpers/ThemeComponents";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import { useShopContext } from "../../context";
import FullPage from "../../../features/FullPage";
import Loader from "../../../loader/loader";
import { useSelectedStoreContext } from "../../SelectedStore/context";
import OrderStepper from "./OrderStepper/OrderStepper";
import { Container } from "@mui/material";
import { matchIsValidTel } from "mui-tel-input";
import useAppContext from "../../../useAppContext";
import changePage from "../../../helpers/changePage";
import useSelectedShipmentService from "./useSelectedShipmentService";
import Loyalty from "../../loyalty";
import { IStorageAddressObject } from "../../../api/shop/basic/types";
import useUserIncustCustomerData from "../../../hooks/incust/useUserIncustCustmerData";
import useNewPaymentsService from "../../payments/hooks/useNewPaymentsService";
import useUpdateOrder from "./useUpdateOrder";
import useSearchParamsFixed from "../../../features/hooks/useSearchParamsFixed";

let confirmEmailInterval: any = null;

const initialForm: CreateOrderPayload = {
	store_id: 0,

	shipment_method_id: 0,
	delivery_address: "",

	first_name: "",
	last_name: "",
	phone: "",
	email: "",
	address_comment: "",
	desired_delivery_date: null,
	desired_delivery_time: null,
	products: null,

	address_floor: 0,
	address_house: "",
	address_flat: "",
	address_entrance: 0,
	address_street: "",

	comment: "",
	custom_shipment_comment: "",

	tips_sum: 0,
	price: null,
	free_shipment: false,

	type: "regular",
	marketing_consent: false,
};

const initialBillingAddress: BillingAddress = {
	first_name: "",
	last_name: "",

	company_name: "",
	vat_number: "",
	registration_number: "",

	country: "",
	state: "",
	city: "",
	zip_code: "",

	address_1: "",
	address_2: "",
	phone_number: "",
};

export default function MakeOrder() {
	const [searchParams] = useSearchParamsFixed();
	const localisation = useLocalisation();

	const { customerData } = useUserIncustCustomerData();

	const [errorText, setErrorText] = useState<string | null>(null);

	const [form, setForm] = useMergeState(initialForm);

	const [notAvailableProducts, setNotAvailableProducts] = useState<NotAvailableProduct[]>([]);
	const [needLoginForEmail, setNeedLoginForEmail] = useState<boolean>(false);
	const [billingForm, setBillingForm] = useMergeState<BillingAddress>(initialBillingAddress);
	const [createdOrder, setCreatedOrder] = useState<Order | null>(null);

	const {
		authService: { loadUser, user, client },
		setPageTitle,
		brandInfo,
	} = useAppContext();

	const { setAddressValue, setAddressCoords } = useShopContext();

	const { selectedStore, cartService, computedShopImage, shipmentsService } =
		useSelectedStoreContext();

	useEffect(() => {
		setForm({ store_id: selectedStore.id });
	}, [setForm, selectedStore.id]);

	const totalSum = cartService.calculateCartTotalSum();
	const selectedShipmentService = useSelectedShipmentService(totalSum);
	const paymentsService = useNewPaymentsService(
		selectedStore.id,
		selectedShipmentService.selectedShipment,
		selectedStore.currency,
		cartService
	);

	const updateService = useUpdateOrder(
		form,
		selectedShipmentService,
		billingForm,
		cartService.token,
		searchParams.get("order_token")
	);

	const handleBackToShop = () => {
		changePage(`/shop/${selectedStore.id}/menu`);
	};

	const fillFormWithProfileData = useCallback(() => {
		const lastAddressObj: IStorageAddressObject | null = api.shop.basic.getLastAddressObject();
		const lastPersonalData = api.shop.order.getLastPersonalData();

		let lastAddress: string | null = null;
		let lastCoords: [number, number] | null = null;

		if (lastAddressObj && lastAddressObj.addressValue) {
			lastAddress = lastAddressObj.addressValue;
		}
		if (lastAddressObj && lastAddressObj.addressCoords) {
			lastCoords = lastAddressObj.addressCoords;
		}

		setForm({
			first_name: user?.first_name || lastPersonalData?.first_name || "",
			last_name: user?.last_name || lastPersonalData?.last_name || "",
			address_comment: lastPersonalData?.address_comment || "",
			address_floor: lastPersonalData?.address_floor || 0,
			address_house: lastPersonalData?.address_house || "",
			address_flat: lastPersonalData?.address_flat || "",
			address_entrance: lastPersonalData?.address_entrance || 0,
			address_street: lastPersonalData?.address_street || lastAddress || "",
			comment: lastPersonalData?.comment || "",
			custom_shipment_comment: lastPersonalData?.custom_shipment_comment || "",
			phone:
				(user?.wa_phone && `+${user.wa_phone}`) ||
				(lastPersonalData?.phone &&
					matchIsValidTel(lastPersonalData.phone) &&
					lastPersonalData.phone) ||
				"",
		});

		if (brandInfo?.order_fields_settings[client].email !== "disabled") {
			setForm({ email: user?.email || lastPersonalData?.email || "" });
		}

		setAddressValue(prevState => {
			return prevState || lastAddress || "";
		});
		setAddressCoords(prevState => {
			return prevState || lastCoords || null;
		});
	}, [setAddressValue, setAddressCoords, setForm, user]);

	const waitConfirmEmail = useCallback(() => {
		confirmEmailInterval = setInterval(async () => {
			await loadUser();
		}, 3000);
	}, [loadUser]);

	useEffect(() => {
		fillFormWithProfileData();
	}, [fillFormWithProfileData]);

	useEffect(() => {
		setPageTitle(`${selectedStore.name} - ${localisation.orders.makeOrderHeader}`);
	}, [localisation.orders.makeOrderHeader, selectedStore.name, setPageTitle]);

	const isUser = !!user;
	useEffect(() => {
		if (isUser) {
			setNeedLoginForEmail(false);

			if (!confirmEmailInterval && user?.email && !user.is_confirmed_email) {
				waitConfirmEmail();
			}

			if (user?.is_confirmed_email && confirmEmailInterval) {
				clearInterval(confirmEmailInterval);
			}
		}
	}, [isUser, user?.email, user?.is_confirmed_email, waitConfirmEmail]);

	const setSelectedShipment = selectedShipmentService.setSelectedShipment;
	useEffect(() => {
		if (
			(cartService.computedIsOnlyServiceProductsInCart ||
				(shipmentsService.computedStoreEmptyShipments && !shipmentsService.isPending)) &&
			!!cartService.cart?.cart_products?.length
		) {
			setSelectedShipment(shipmentsService.noDeliveryTypeShipment);
		}
	}, [
		cartService.cart,
		cartService.computedIsOnlyServiceProductsInCart,
		setSelectedShipment,
		shipmentsService.computedStoreEmptyShipments,
		shipmentsService.isPending,
		shipmentsService.noDeliveryTypeShipment,
	]);

	if (cartService.isEmpty && cartService.isLoading) {
		return (
			<FullPage centered>
				<Loader />
			</FullPage>
		);
	}

	return (
		<>
			<Container maxWidth={"sm"}>
				<div>
					<div className={"fs-3 fw-bold theme-text"}>
						{cartService.isEmpty && !createdOrder
							? localisation.cart.cartEmpty
							: localisation.orders.makeOrderHeader}
					</div>

					<div className="d-flex theme-text small mb-2 justify-content-between">
						<div>
							<TgLink className={"cursor-pointer"} onClick={() => handleBackToShop()}>
								{localisation.global.backHome}
							</TgLink>
						</div>

						{!cartService.isEmpty && (
							<div className={"ms-2 me-1"}>
								<TgLink
									className={"cursor-pointer"}
									onClick={() => changePage(`/shop/${selectedStore.id}/cart`)}
								>
									{localisation.global.toCartLink}
								</TgLink>
							</div>
						)}
					</div>

					{(!cartService.isEmpty ||
						(searchParams.get("order_token") && searchParams.get("order_id"))) && (
						<>
							<OrderStepper
								form={form}
								setForm={setForm}
								selectedShipmentService={selectedShipmentService}
								paymentsService={paymentsService}
								computedShopImage={computedShopImage}
								notAvailableProducts={notAvailableProducts}
								setNotAvailableProducts={setNotAvailableProducts}
								setNeedLoginForEmail={setNeedLoginForEmail}
								needLoginForEmail={needLoginForEmail}
								billingForm={billingForm}
								setBillingForm={setBillingForm}
								errorText={errorText}
								setErrorText={setErrorText}
								is_friend_payment={brandInfo?.is_friend_payment || null}
								updateService={updateService}
								createdOrder={createdOrder}
								setCreatedOrder={setCreatedOrder}
							/>

							{(customerData?.token ||
								brandInfo?.incust_data?.loyalty_applicable_type === "for_all") &&
								!cartService.incustService.processedCheck && (
									<Loyalty
										hidden={true}
										incustService={cartService.incustService}
									/>
								)}
						</>
					)}
				</div>
			</Container>
		</>
	);
}
