import { ReactNode, useMemo } from "react";

import MUIPopConfirm from "../../../features/MUIPopConfirm";
import useAppContext from "../../../useAppContext";
import { useSelectedStoreContext } from "../../SelectedStore/context";
import { Box, Typography } from "@mui/material";
import Interweave from "../../../features/Interweave";
import useSelectProduct from "../../services/useNewProductsService/useSelectProduct";

interface IProductPopconfirmBase {
	children: ReactNode;
}

interface IDeleteProductsPopConfirmProps extends IProductPopconfirmBase {
	deleteSingle?: boolean;
	cartProductId?: number;
	productId?: number;
}

export default function DeleteProductsPopConfirmWrapper(props: IDeleteProductsPopConfirmProps) {
	const { cartService } = useSelectedStoreContext();

	const getCartProductByCartProductId = cartService.getCartProductByCartProductId;
	const computedProductsNode = useMemo(() => {
		if (props.deleteSingle && props.cartProductId) {
			const cartProduct = getCartProductByCartProductId(props.cartProductId);
			if (cartProduct) {
				const attrsString = cartProduct.cart_attributes
					?.map(cpa => cpa.attribute.name + ` (${cpa.quantity})`)
					.join(", ");
				return (
					<PopConfirmProductsComponent
						name={cartProduct.product.name}
						attrs={attrsString || ""}
					/>
				);
			}
		}

		if (props.productId) {
			const filtered = cartService.cart?.cart_products?.filter(
				x => x.product.id === props.productId
			);
			if (filtered) {
				return filtered.map((cp, index) => (
					<Box key={index} className={index > 0 ? "border-top mt-2" : ""}>
						<PopConfirmProductsComponent
							key={cp.id}
							name={cp.product.name}
							attrs={
								cp.cart_attributes
									?.map(cpa => cpa.attribute.name + ` (${cpa.quantity})`)
									.join(", ") || ""
							}
						/>
					</Box>
				));
			}
		}

		return <></>;
	}, [
		cartService.cart?.cart_products,
		getCartProductByCartProductId,
		props.cartProductId,
		props.deleteSingle,
		props.productId,
	]);

	if (cartService.addingProductWithNewAttributesId && props.deleteSingle) {
		return <CancelAddingProductOption children={props.children} />;
	}

	if (!props.deleteSingle) {
		return (
			<DeleteAllProductOptions
				children={props.children}
				productIdState={props.productId}
				computedProductsNode={computedProductsNode}
			/>
		);
	}

	return (
		<DeleteSingleProductOption
			children={props.children}
			cartProductId={props.cartProductId}
			computedProductsNode={computedProductsNode}
		/>
	);
}

interface IPopConfirmProductsComponentProps {
	name: string;
	attrs: string;
}

function PopConfirmProductsComponent(props: IPopConfirmProductsComponentProps) {
	return (
		<>
			<Typography fontWeight={"bold"}>{props.name}</Typography>
			<Typography variant={"body2"} color={"text.secondary"}>
				{props.attrs}
			</Typography>
		</>
	);
}

function CancelAddingProductOption(props: IProductPopconfirmBase) {
	const {
		localisation: { global, menu },
	} = useAppContext();
	const { cartService } = useSelectedStoreContext();
	const confirm = () => {
		cartService.setAddingProductWithNewAttributesId(null);
		cartService.updateProductVariationsState(true);
	};

	return (
		<MUIPopConfirm
			primaryText={true}
			title={menu.cancelAddingNewOptionHeader}
			onConfirm={confirm}
			cancelText={global.cancelButton}
			confirmText={global.confirmButton}
			message={menu.cancelAddingNewOptionMsg}
			node={props.children}
		/>
	);
}

interface IDeleteAllProductOptionsProps extends IProductPopconfirmBase {
	productIdState: number | null | undefined;
	computedProductsNode: ReactNode;
}

function DeleteAllProductOptions(props: IDeleteAllProductOptionsProps) {
	const {
		localisation: { global, menu },
	} = useAppContext();
	const { cartService } = useSelectedStoreContext();
	const selectProduct = useSelectProduct();

	const confirm = async () => {
		if (props.productIdState) {
			await cartService.deleteProductFromCart(props.productIdState, false);
			selectProduct(null);
			cartService.clearCartProductLocalData();
		}
	};

	return (
		<MUIPopConfirm
			primaryText={true}
			title={menu.deleteAllOptionsHeader}
			reverseButtonColors={true}
			onConfirm={confirm}
			cancelText={global.cancelButton}
			confirmText={global.deleteText}
			message={
				<>
					<Interweave content={menu.deleteFromCartAllOptionsMsg} />
					<Box mt={2}>{props.computedProductsNode}</Box>
				</>
			}
			node={props.children}
		/>
	);
}

interface IDeleteSingleProductOptionProps extends IProductPopconfirmBase {
	cartProductId: number | null | undefined;
	computedProductsNode: ReactNode;
}

function DeleteSingleProductOption(props: IDeleteSingleProductOptionProps) {
	const {
		localisation: { global, menu },
	} = useAppContext();
	const { cartService } = useSelectedStoreContext();
	const selectProduct = useSelectProduct();

	const getCartProductByCartProductId = cartService.getCartProductByCartProductId;
	const computedProductId = useMemo(() => {
		if (props.cartProductId) {
			const cartProduct = getCartProductByCartProductId(props.cartProductId);
			if (cartProduct) return cartProduct.product.id;
		}
		return null;
	}, [getCartProductByCartProductId, props.cartProductId]);

	const computedHasVariations = useMemo(() => {
		if (props.cartProductId) {
			if (computedProductId) {
				const filtered = cartService.cart?.cart_products?.filter(
					x => x.product.id === computedProductId
				);
				if (filtered && filtered.length > 1) {
					return true;
				}
			}
		}
		return false;
	}, [cartService.cart?.cart_products, computedProductId, props.cartProductId]);

	const confirm = async () => {
		if (props.cartProductId) {
			cartService.updateProductVariationsState(false);
			await cartService.deleteProductFromCart(props.cartProductId);
			if (!computedHasVariations) {
				selectProduct(null);
				cartService.clearCartProductLocalData();
			}
		}
	};

	return (
		<MUIPopConfirm
			primaryText={true}
			title={menu.deleteProductHeader}
			reverseButtonColors={true}
			onConfirm={confirm}
			cancelText={global.cancelButton}
			confirmText={global.deleteText}
			additionalNode={
				computedHasVariations ? (
					<DeleteAllOptionsAdditionalNode
						computedProductsNode={props.computedProductsNode}
						productIdState={computedProductId}
					/>
				) : null
			}
			message={
				<>
					<Interweave content={menu.deleteFromCartSingleMsg} />
					<Box mt={2}>{props.computedProductsNode}</Box>
				</>
			}
			node={props.children}
		/>
	);
}

interface IDeleteAllOptionsAdditionalNodeProps {
	productIdState: number | null | undefined;
	computedProductsNode: ReactNode;
}

function DeleteAllOptionsAdditionalNode(props: IDeleteAllOptionsAdditionalNodeProps) {
	const {
		localisation: { menu },
	} = useAppContext();

	return (
		<DeleteProductsPopConfirmWrapper
			deleteSingle={false}
			productId={props.productIdState || undefined}
			children={
				<Typography
					variant={"body2"}
					sx={{
						cursor: "pointer",
						color: "error.main",
						textDecorationColor: "error.main",
						textDecoration: "underline",
						mb: 1,
						pl: 1,
					}}
				>
					{menu.deleteAllProdOptionsBtn}
				</Typography>
			}
		/>
	);
}
