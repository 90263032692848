export const OrderLocale = {
	open_unconfirmed: "store brand order status open unconfirmed text",
	open_confirmed: "store brand order status open confirmed text",
	closed: "store brand order status closed text",
	canceled: "store brand order status canceled text",
	must_pay: "store brand order status pay must pay text",
	processing: "store brand order status pay processing text",
	payed: "store brand order status pay payed text",
	orderNumber: "web store orders order number text",
	cancelOrderMsg: "web store orders cancel order message text",
	addressHeader: "web store orders address header text",
	addressDetailsHeader: "web store orders address details header text",
	addressDetailed: "web store orders address detailed text",
	phoneHeader: "web store orders phone header text",
	emailHeader: "web store orders email header text",
	deliveryHeader: "web store orders delivery header text",
	sumDetailed: "web store orders sum detailed text",
	allFilter: "web store orders all filter text",
	confirmedFilter: "web store orders confirmed filter text",
	unconfirmedFilter: "web store orders unconfirmed filter text",
	closedFilter: "web store orders closed filter text",
	canceledFilter: "web store orders canceled filter text",
	notFound: "web store orders not found text",
	price: "web store orders table price text",
	quantity: "web store orders table quantity text",
	sum: "web store orders table sum text",
	myOrders: "web store orders my orders header text",
	payButton: "web store orders pay button text",
	payHeader: "web store orders pay header text",
	payChose: "web store orders pay chose text",
	payDisabled: "web store orders pay disabled text",
	paySuccess: "web store orders pay success text",
	payedInvoice: "payed invoice",
	payWaitToPayFriend: "web store order wait to pay friend",
	payFriendHeader: "web order header friend pay text",
	payInvoiceFriendHeader: "web invoice header friend pay text",
	deliveryTimeHeader: "web store make order delivery time header text",
	deliveryTimeDateLabelToday: "web store make order delivery time date label text",
	deliveryTimeTimeLabel: "web store make order delivery time time label text",
	makeOrderHeader: "web store make order header text",
	regHeaderText: "web store make order registration header text",
	regSubHeaderText: "web store make order registration sub header text",
	makeOrderConfirm: "web store make order confirm button text",
	separateDeliveryMsg: "web store make order separate delivery msg text",
	productsHeader: "web store make order products header text",
	makeOrderDeliveryHeader: "web store make order delivery header text",
	paymentHeader: "web store make order payment header text",
	overallHeader: "web store make order overall header text",
	successHeader: "web store make order success header text",
	successNameHeader: "web store make order success name header text",
	successPhoneHeader: "web store make order success phone header text",
	successDeliveryTimeHeader: "web store make order success delivery time header text",
	successAddressDetailsHeader: "web store make order success address details header text",
	successAddressCommentHeader: "web store make order success address comment header text",
	successSelectedShopHeader: "web store make order success selected shop header text",
	successAddressDetailed: "web store make order success address detailed text",
	streetFeedback: "web store make order street feedback text",
	streetLabel: "web store make order street label text",
	houseFeedback: "web store make order house feedback text",
	houseLabel: "web store make order house label text",
	flatFeedback: "web store make order flat feedback text",
	flatLabel: "web store make order flat label text",
	floorFeedback: "web store make order floor feedback text",
	floorLabel: "web store make order floor label text",
	entranceFeedback: "web store make order entrance feedback text",
	entranceLabel: "web store make order entrance label text",
	addressFeedback: "web store make order address feedback text",
	addressLabel: "web store make order address label text",
	addressDetailsFeedback: "web store make order address details feedback text",
	addressDetailsLabel: "web store make order address details label text",
	addressCommentLabel: "web store make order address comment label text",
	availablePays: "web store make order available payments text",
	cashMsg: "web store make order cash msg text",
	inStoreMsg: "web store make order in store msg text",
	laterMsg: "web store make order later msg text",
	onlinePayMsg: "web store make order online msg text",
	friendPayMsg: "web store make order friend msg text",
	selectFriendConfirm: "web store order select friend confirm text",
	friendSelect: "store payment select friend text",
	friendSelectNoFriends: "store payment select friend no friends text",
	friendDeleteMsg: "store delete friend text",
	friendDeleteHeaderMsg: "store delete friend header text",
	stepPersonalHeader: "web store make order stepper personal header text",
	stepDeliveryHeader: "web store make order stepper delivery header text",
	stepOnlyDeliveryHeader: "web store make order stepper only delivery header text",
	stepPaymentHeader: "web store make order stepper payment header text",
	stepDeliveryTimeHeader: "web store make order stepper delivery time header text",
	step: "web store make order stepper step text",
	stepOutOf: "web store make order stepper step out of text",
	stepPrev: "web store make order stepper step prev text",
	stepNext: "web store make order stepper step next text",
	firstNameFeedback: "web store make order first name feedback text",
	firstNameLabel: "web store make order first name label text",
	lastNameFeedback: "web store make order last name feedback text",
	lastNameLabel: "web store make order last name label text",
	phoneFeedback: "web store make order phone feedback text",
	phoneLabel: "web store make order phone label text",
	emailFeedback: "web store make order email feedback text",
	emailLabel: "web store make order email label text",
	notAvailableMsg: "web store orders not available products msg text",
	cantPayCauseAvailability: "web store orders cant pay cause availability text",
	commentLabel: "web store orders comment label",
	commentFriendLabel: "web store orders comment friend label",
	authStepGuest: "web store make order auth step as guest header",
	authStepGuestSub: "web store make order auth step as guest sub header",
	authStepLoginSub: "web store make order auth step login sub header",
	authStepRegSub: "web store make order auth step registration sub header",
	loggedInAs: "web store make order auth step logged as text",
	authorisationStepHeader: "web store make order authorisation step header",
	stepConfirmationHeader: "web store make order stepper confirm header",
	toPayHeader: "web store make order to pay header",
	loyaltyRedeemedOrderHeader: "loyalty bonuses redeemed order header",
	orderConfirmStepHeader: "web store make order stepper confirm step header",
	willRedeemedCoupons: "loyalty will redeemed coupons header",
	willRedeemedBonuses: "loyalty will redeemed bonuses header",
	editItems: "web store make order edit items text",
	willRedeemedCouponsHeader: "loyalty will redeemed coupons popover header",
	loyaltyInfo: "loyalty information",
	invoiceHeader: "invoice number header",
	invoicePayed: "invoice is already payed",
	customFeedback: "web store make order custom field feedback text",
	qr_code_show_text: "unipos qr code text for buyer",
	unipos_button_logo: "unipos button logo",
	paymentCost: "web store make order payment method cost header",
	deliveryCost: "web store make order delivery method cost header",
	free: "web store make order delivery method free text",
	billingAddress: "store brand billing settings button",
	billingPerson: "store brand billing person button",
	billingCompany: "store brand billing organisation button",
	deliveryTimeWarning: "web store delivery time store closed warning",
	waitForShip: "store order wait for ship status text",
	shipped: "store order shipped status text",
	in_transit: "store order in transit status text",
	delivered: "store order delivered status text",
	orderNotFound: "order not found error",
	statusHeader: "web store order status header text",
	statusPayHeader: "web store order status pay header text",
	statusDeliveryHeader: "web store order status shipment header text",
	notSelectedDelivery: "store order select shipment method error",
	notSelectedPayment: "store order select payment method error",
	notAvailablePayments: "store order no payments available text",
	notAvailableDeliveries: "store order no shipments available text",
	needSelectDelivery: "store order need select shipment text",
	personalDataError: "store brand create order personal data error",
	emailError: "store brand create order no email error",
	addressError: "store brand create order incorrect address error",
	deliveryTimeDateLabel: "web store make order delivery date and time label",
	redirectPay: "web app autoredirect payment message",
	overallHeaderTotal: "web store sum label text",
	chooseDeliveryText: "web choose delivery text",
	cancelOrder: "web app cancel order button",
	payedLabel: "web store sum paid label text",
	orderCreatedText: "web store order created text",
	viewOrderButton: "web store view order button",
	hideOrderButton: "web store hide order button",
	viewInvoiceButton: "web store view invoice button",
	firstNameNotFilledError: "store create order first name not filled error",
	lastNameNotFilledError: "store create order last name not filled error",
	enterTipLabel: "web app enter tips sum label",
	tipsSubHeader: "web app tips sub header",
	orderSum: "order sum header",
	offerAgreementText: "web order offer agreement text",
	deliveryDateLabel: "web store make order delivery time date label",
	unknownShipmentPricesError: "shipment prices unknown error",
	nextPriceCost: "shipment prices next cheap delivery message",
	nextPriceFree: "shipment prices next free delivery message",
	achivedPriceCost: "shipment prices achived cheap delivery message",
	achivedPriceFree: "shipment prices achived free delivery message",
	courierButton: "web app orders instruction for courier button",
	courierLabel: "web app orders instruction for courier label",
	courierTooltip: "web app orders instructions for courier tooltip",
	paidSeparately: "shipment prices paid separately text",
	cartHashError: "web app cart hash error",
	selectedShipmentMethodHeader: "web store make order delivery method header",
	thanksForOrderGift: "web app orders thanks for order gift text",
	payerFeeText: "payer fee receipt text",
	payerFeeOrderListText: "payer fee receipt list order text",
	updateOrderPersonalData: "web app store order update personal data error",
	updateOrderShipmentData: "web app store order update shipment data error",
	updateOrderTipsData: "web app store order update tips data error",
	commentIsRequired: "web app store order comment is required error",
	payOtherMethodButton: "web store orders pay other method button text",
};
