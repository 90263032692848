import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import api from "../../../api";
import useAppContext from "../../../useAppContext";
import { ExtraFeeSchema } from "../../../api/shop/basic/types";

export default function useExtraFee(totalSum: number, currency?: string | null): IUseExtraFee {
	const { brandInfo } = useAppContext();

	const { data: extraFees } = useQuery({
		queryKey: ["extra-fees", brandInfo?.group_id],
		enabled: !!brandInfo?.id && !!currency,
		initialData: null,
		queryFn: async () => {
			if (brandInfo?.id && !!currency) {
				try {
					const response = await api.shop.basic.getCalculatedExtraFee({
						total_sum: totalSum,
						currency: currency,
					});
					if (response.data && response.data.length > 0) {
						return response.data;
					}
				} catch (ex) {} //TODO: handle error
				return null;
			}
		},
	});

	const calcExtraFeeTotalSum = useMemo(() => {
		let extraFee = 0;
		if (extraFees && extraFees.length > 0) {
			extraFee = extraFees.reduce((sum, fee) => sum + fee.applied_amount_float, 0);
		}

		return extraFee;
	}, [extraFees]);

	const calcTotalSumWithExtraFee = useMemo(() => {
		return totalSum + calcExtraFeeTotalSum;
	}, [totalSum, calcExtraFeeTotalSum]);

	return {
		calcTotalSumWithExtraFee,
		extraFees: extraFees || null,
	};
}

export interface IUseExtraFee {
	calcTotalSumWithExtraFee: number;
	extraFees: ExtraFeeSchema[] | null;
}
