import { AxiosInstance } from "axios";
import { Bot } from "./types";
import { OkResponse } from "../types";

export default function BotsAPIModule(instance: AxiosInstance) {
	const getPath = (path: any) => `bots/${path}`;
	return {
		getBot(id: number) {
			return instance.get<Bot>(getPath(id));
		},
		sendShareAndEarn() {
			return instance.post<OkResponse>(getPath("send_share_and_earn"));
		},
	};
}
