export const ApiUrl = "https://api.payforsay.com";
export const WsUrl = "wss://api.payforsay.com";
export const ClientBotApiUrl = "https://bot.payforsay.com";
export const ServiceBotApiUrl = "https://servicebot.payforsay.com";
export const OpenStreetMapApiUrl = "https://nominatim.openstreetmap.org/";
export const PhotonApiUrl = "https://photon.komoot.io";
export const GoogleOAuthClientId =
	"717480723250-hd3eq55mbp8iq6ijfcqr5difeek5i7j3.apps.googleusercontent.com";
export const InternalWidgetUrl = "https://webapp.7loc.com";
// export const AppleAuthClientId = "com.7loc.authid";
export const AppleAuthClientId = "com.7loc.auth.client";
export const AppleAuthRedirectUri = "https://webapp.7loc.com";
export const AppleAuthApiRedirectUri = "https://api.payforsay.com/auth/apple_oauth";
export const SseUrl = "https://sse.7loc.com";

export const AdminUrl = "https://admin.7loc.com";
