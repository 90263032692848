import { Link } from "@mui/material";
import useAppContext from "../../../useAppContext";
import DeleteAccountModal from "./DeleteAccountModal";
import { useState } from "react";

export default function DeleteAccount() {
	const { localisation } = useAppContext();

	const [modalOpen, setModalOpen] = useState(false);

	return (
		<>
			<Link component={"button"} color={"error"} onClick={() => setModalOpen(true)}>
				{localisation.auth.deleteAccountButton}
			</Link>
			<DeleteAccountModal open={modalOpen} setOpen={setModalOpen} />
		</>
	);
}
