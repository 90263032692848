import { useMemo } from "react";
import { Link, Typography } from "@mui/material";

import f from "../../helpers/formatText";
import useAppContext from "../../useAppContext";
import CheckInfoLine from "./CheckInfoLine";
import { CheckProps } from "./types";
import { getLinkOnMaps } from "../../helpers";

export default function CheckHeader({ data }: CheckProps) {
	const {
		localisation: { check: locale, payment: paymentLocale },
	} = useAppContext();

	return (
		<>
			{data.header && (
				<Typography variant={"h6"} fontWeight={"bold"}>
					{f(locale.storeNameText, { name: data.header })}
				</Typography>
			)}

			{/* Header */}
			<Typography>
				<CheckInfoLine enabled={!!data.date}>
					{f(locale.dateText, { datetime: data.date })}
				</CheckInfoLine>
				<CheckInfoLine enabled={!!data.toName}>
					{data.onlyName ? data.toName : f(locale.toText, { name: data.toName })}
				</CheckInfoLine>
				<CheckInfoLine enabled={!!data.status}>
					{f(locale.statusText, {
						status: data.status ? locale[`status_${data.status}_text`] : "",
					})}
				</CheckInfoLine>
				<CheckInfoLine enabled={!!data.status_pay && (data.showStatusPay ?? false)}>
					{f(locale.statusPayText, {
						status:
							data.status_pay === "payed"
								? locale.statusPayPayedText
								: locale.statusPayNotPayedText,
					})}
				</CheckInfoLine>
				{data.viewOrder && (
					<>
						<CheckInfoLine enabled={!!data.email}>
							<Link href={`mailto:${data.email}`}>{data.email}</Link>
						</CheckInfoLine>
						<CheckInfoLine enabled={!!data.phone}>
							<Link href={`tel:${data.phone?.replace(" ", "")}`}>{data.phone}</Link>
						</CheckInfoLine>
						<CheckInfoLine enabled={!!data.paymentMethodName}>
							{f(locale.paymentMethodText, {
								payment_method: data.paymentMethodName,
							})}
						</CheckInfoLine>
						<CheckInfoLine enabled={!!data.paymentMethodComment}>
							{f(locale.paymentMethodCommentText, {
								payment_comment_label: data.paymentMethodCommentLabel
									? data.paymentMethodCommentLabel
									: paymentLocale.commentLabel,
								payment_comment: data.paymentMethodComment,
							})}
						</CheckInfoLine>
						{data.deliveryMethodBaseType &&
							data.deliveryMethodBaseType !== "in_store" &&
							data.deliveryMethodBaseType !== "no_delivery" && (
								<>
									<CheckInfoLine enabled={!!data.deliveryMethodName}>
										{f(locale.deliveryMethodText, {
											delivery_method: data.deliveryMethodName,
										})}
									</CheckInfoLine>
									<CheckInfoLine
										enabled={
											data.deliveryMethodBaseType === "pickup" &&
											!!data.deliveryPickupPointName
										}
									>
										{f(locale.deliveryMethodPickupPointText, {
											name: data.deliveryPickupPointName,
										})}
									</CheckInfoLine>
									<CheckInfoAddressLine
										deliveryAddress={data.deliveryAddress}
										lat={data.addressLat}
										lng={data.addressLng}
										placeId={data.placeId}
									/>
									<CheckInfoLine
										enabled={
											!!(
												data.deliveryMethodCommentLabel &&
												data.deliveryMethodComment
											)
										}
									>
										{f(locale.deliveryMethodCommentText, {
											delivery_comment_label: data.deliveryMethodCommentLabel,
											delivery_comment: data.deliveryMethodComment,
										})}
									</CheckInfoLine>
									<CheckInfoLine enabled={!!data.desiredDeliveryDate}>
										{f(
											data.deliveryMethodBaseType === "pickup"
												? locale.desiredPickupDateText
												: locale.desiredDeliveryDateText,
											{ datetime: data.desiredDeliveryDate }
										)}
									</CheckInfoLine>
									<CheckInfoLine enabled={!!data.comment}>
										{f(locale.commentText, { comment: data.comment })}
									</CheckInfoLine>
								</>
							)}
					</>
				)}
			</Typography>
		</>
	);
}

interface ICheckInfoAddressLineProps {
	lat?: string | null;
	lng?: string | null;
	deliveryAddress?: string | null;
	placeId?: string | null;
}

function CheckInfoAddressLine(props: ICheckInfoAddressLineProps) {
	const {
		localisation: { check: locale },
	} = useAppContext();

	const computedMapLink = useMemo(() => {
		if (props.lat && props.lng) {
			return getLinkOnMaps(props.lat, props.lng, props.placeId || "");
		}
		return null;
	}, [props.lat, props.lng, props.placeId]);

	return (
		<CheckInfoLine enabled={!!props.deliveryAddress}>
			{!!computedMapLink ? (
				<Link href={computedMapLink} target={"_blank"}>
					{f(locale.deliveryMethodAddressText, { address: props.deliveryAddress })}
				</Link>
			) : (
				f(locale.deliveryMethodAddressText, { address: props.deliveryAddress })
			)}
		</CheckInfoLine>
	);
}
