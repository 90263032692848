import { Box, Grid, Typography } from "@mui/material";
import { SxProps } from "@mui/system";

import BasePayButton from "../BasePayButton";
import { IncustPayConfiguration } from "../../../../api/shop/payments/types";
import useAppContext from "../../../../useAppContext";
import f from "../../../../helpers/formatText";
import { PaymentMethod } from "../../../../api/shop/basic/types";
import { PayButtonIcon } from "../PayButton";
import { PaymentFee } from "../../Payment/PaymentItem";
import { useEffect, useRef } from "react";

interface IncustPayCardButtonProps {
	data: IncustPayConfiguration;
	setData: (data: IncustPayConfiguration) => void;
	sx?: SxProps;
	logo: string | null;
	method?: PaymentMethod | null;
	totalAmount: number;
	currency: string;
	payButtonMaxHeight: number | null;
	setPayButtonMaxHeight: (value: number) => void;
	anyFee?: boolean;
}

export default function IncustPayCardButton(props: IncustPayCardButtonProps) {
	const btnRef = useRef<HTMLButtonElement | null>(null);
	const { localisation } = useAppContext();

	const setPayButtonMaxHeight = props.setPayButtonMaxHeight;
	useEffect(() => {
		if (btnRef.current && setPayButtonMaxHeight) {
			let height = btnRef.current.clientHeight;
			if (!height || height < 24) {
				height = 24;
			}
			setPayButtonMaxHeight(height);
		}
	}, [setPayButtonMaxHeight]);

	return (
		<BasePayButton onClick={() => props.setData(props.data)} sx={props.sx}>
			<Box
				textAlign={"start"}
				width={"100%"}
				ref={btnRef}
				sx={{
					minHeight: props.payButtonMaxHeight ? `${props.payButtonMaxHeight}px` : "auto",
					alignContent: "center",
					alignSelf: "center",
					overflow: "hidden",
					overflowWrap: "break-word",
					width: "100%",
				}}
			>
				<Box display={"flex"} width={"100%"}>
					{props.logo && <PayButtonIcon path={props.logo} alt={props.data.name} />}
					<Box
						pl={2}
						width={"100%"}
						display={"flex"}
						alignSelf={"center"}
						overflow={"hidden"}
					>
						<Box alignSelf={"center"} overflow={"hidden"} width={"100%"}>
							<Grid container gap={1} flexWrap={"nowrap"} alignItems={"center"}>
								<Grid
									item
									xs={true}
									display={"flex"}
									overflow={"hidden"}
									whiteSpace={"nowrap"}
								>
									<Typography
										sx={{ lineHeight: 1.2, alignSelf: "center" }}
										overflow={"hidden"}
										textOverflow={"ellipsis"}
										variant={"subtitle1"}
									>
										{f(localisation.payment.incustPayCardText, {
											name: props.data.name,
										})}
									</Typography>
								</Grid>
							</Grid>
							{props.method && (
								<PaymentFee
									feeValue={props.method.fee_value || 0}
									feePercent={props.method.fee_percent || 0}
									totalAmount={props.totalAmount}
									currency={props.currency}
									anyFee={props.anyFee}
								/>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		</BasePayButton>
	);
}
