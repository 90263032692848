import useAppContext from "../../../../../../useAppContext";
import { useEffect, useMemo } from "react";
import { CheckData, CheckItemType } from "../../../../../../features/Check/types";
import { Check } from "../../../../../../features/Check";
import { Box, Divider } from "@mui/material";
import CountInput from "./CountInput";
import AmountInput from "./AmountInput";
import IncustLoyalty from "../../../../../loyalty/components/IncustLoyalty/IncustLoyalty";
import { PaymentDataComponentProps } from "../../types";
import useExtraFee from "../../../../hooks/useExtraFee";
import { useSelectedStoreOptional } from "../../../../../SelectedStore/context";

export default function PaymentDataComponent(props: PaymentDataComponentProps) {
	const { lang, brandInfo } = useAppContext();
	const selectedStore = useSelectedStoreOptional();

	const checkData = useMemo((): CheckData | null => {
		if (!props.invoiceTemplate || props.invoiceTemplate.payment_mode === "entered_amount")
			return null;

		const items: CheckItemType[] = props.invoiceTemplate?.items.map(item => ({
			name: item.name,
			quantity: item.quantity * (props.form.count || 1),
			price: item.price,
			sum: item.price * item.quantity * (props.form.count || 1),
			hideImage: true,
		}));

		const subtotal = items.reduce((total, item) => total + item.sum, 0);

		const isLoyalty = !!(
			brandInfo?.loyalty_info?.loyalty_enabled &&
			brandInfo.loyalty_info?.loyalty_type === "incust" &&
			(props.incustService.processedCheck || props.incustService.processCheckLoading)
		);

		const currency = props.invoiceTemplate.currency ? props.invoiceTemplate.currency : props.currency

		if (isLoyalty) {
			return {
				type: "regular",
				viewOrder: true,
				items: items,
				subtotal: subtotal,
				totalAmountWithTips: subtotal,
				extraFee: [],
				total_sum_with_extra_fee: 0,
				currency: currency,
			};
		} else {
			return {
				type: "regular",
				viewOrder: true,
				items: items,
				totalAmountWithTips: subtotal,
				extraFee: [],
				total_sum_with_extra_fee: 0,
				subtotal: subtotal,
				currency: currency,
			};
		}
	}, [
		brandInfo?.loyalty_info?.loyalty_enabled,
		brandInfo?.loyalty_info?.loyalty_type,
		props.form.count,
		props.incustService.processCheckLoading,
		props.incustService.processedCheck,
		props.invoiceTemplate,
		props.currency,
	]);

	const subtotal = checkData?.subtotal ?? props.form.entered_amount ?? 0;

	const { processedCheck } = props.incustService;
	const totalDiscount = processedCheck
		? (processedCheck.discount_amount || 0) + (processedCheck.bonuses_redeemed_amount || 0)
		: 0;
	const totalAmount = subtotal - totalDiscount;
	const extraFeeService = useExtraFee(totalAmount, selectedStore?.currency || props.currency);

	useEffect(() => {
		if (totalAmount === 0) return;
		props.paymentService.setSums({
			totalSum: extraFeeService.calcTotalSumWithExtraFee || totalAmount,
			sumToPay: extraFeeService.calcTotalSumWithExtraFee || totalAmount,
		});
	}, [
		totalAmount,
		props.paymentService.calcSumToPay,
		props.paymentService,
		extraFeeService.calcTotalSumWithExtraFee,
	]);

	if (!lang) return null;

	return (
		<Box sx={{ px: 3 }}>
			{!!checkData && (
				<Check
					containerProps={{
						sx: { mb: 2 },
					}}
					data={checkData}
				/>
			)}
			<Box>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={"center"}
					flexWrap={"wrap"}
					gap={2}
					mt={2}
				>
					{props.invoiceTemplate?.items?.length ? (
						!props.invoiceTemplate.disabled_qty && (
							<CountInput form={props.form} setForm={props.setForm} />
						)
					) : (
						<AmountInput
							currency={props.currency}
							form={props.form}
							setForm={props.setForm}
							enteredAmount={props.enteredAmount}
						/>
					)}

					{!!(
						brandInfo?.loyalty_info?.loyalty_enabled &&
						brandInfo.loyalty_info?.loyalty_type === "incust" &&
						(props.incustService.processedCheck ||
							props.incustService.processCheckLoading)
					) && (
						<Box width={"100%"}>
							<IncustLoyalty
								incustService={props.incustService}
								withoutPreSum={true}
								// hidden={!user}
							/>
						</Box>
					)}
				</Box>
			</Box>

			{!!(
				brandInfo?.loyalty_info?.loyalty_enabled &&
				brandInfo.loyalty_info?.loyalty_type === "incust" &&
				props.incustService.processedCheck?.amount_to_pay &&
				(props.incustService.processedCheck || props.incustService.processCheckLoading)
			) && (
				<>
					<Divider sx={{ mt: 2 }} />
					<Check
						data={{
							type: "regular",
							viewOrder: true,
							totalDiscount: totalDiscount || null,
							totalAmount: totalAmount,
							totalAmountWithTips: totalAmount,
							payer_fee: props.paymentService.calculateFeeRange(),
							payer_fee_row: props.paymentService.showPayerFeeRow,
							sum_to_pay: props.paymentService.calculateFeeRange(true),
							extraFee: extraFeeService.extraFees,
							total_sum_with_extra_fee: extraFeeService.calcTotalSumWithExtraFee,
							isLoyaltyAwards: !!(
								processedCheck?.bonuses_added_amount ||
								processedCheck?.emitted_coupons?.length ||
								processedCheck?.special_accounts_charges?.length
							),
							bonuses: processedCheck?.bonuses_added_amount,
							couponsCount: processedCheck?.emitted_coupons?.length,
							customerAccounts: processedCheck?.special_accounts_charges,
							currency: props.invoiceTemplate?.currency ? props.invoiceTemplate.currency : props.currency,
						}}
					/>
				</>
			)}
		</Box>
	);
}
