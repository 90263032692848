import { useEffect } from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import BottomButtons from "../BottomButtons";
import ShopBreadcrumb from "./ShopBreadcrumb";
import DetailedProductModal from "./product/DetailedProductModal";
import { useShopContext } from "../context";
import Products from "./product/Products";
import ShopMenuCategories from "./ShopMenuCategories";
import ShopAutoTranslateWarning from "./ShopAutoTranslateWarning";
import ShopBannerAndInfo from "./ShopBannerAndInfo";
import ShopFilters from "./ShopFilters";
import AIChatWindow from "../../components/AIChat/AIChatWindow";
import useScreenService from "../../services/useScreenService";
import useViewService from "../../services/useViewService";
import ShopInfo from "./ShopInfo";
import ShopMenuStickyWrapper from "./ShopMenuStickyWrapper";
import { useSelectedStore, useSelectedStoreContextStoreOptional } from "../SelectedStore/context";
import useAppContext from "../../useAppContext";
import useSearchParamsFixed from "../../features/hooks/useSearchParamsFixed";
import ShopSubCategories, { ShopSubCategoriesAndFiltersWrapper } from "./ShopSubCategories";
import { Box } from "@mui/material";
import useProductViewSize from "./product/hooks/useProductsViewSize";
import useFiltersBarSettings from "./hooks/useFiltersBarSettings";
import useNewPaymentsService from "../payments/hooks/useNewPaymentsService";

export default function ShopMenu() {
	const [searchParams] = useSearchParamsFixed();
	const {
		setPageTitle,
		groupService: { group },
	} = useAppContext();

	const { brandInfo, setIsSingleShop, setNeedTryLastStoreOnSelect, menuInStoreService } =
		useShopContext();
	const selectedStore = useSelectedStore();

	const { setIsEMenu } = useShopContext();
	const { shipmentsService } = useSelectedStoreContextStoreOptional();
	const paymentService = useNewPaymentsService(selectedStore.id);

	const { isMobile } = useScreenService();

	const viewService = useViewService();

	const { computedProductsContainerSize, isShopInfo } = useProductViewSize();

	const filtersBarSettings = useFiltersBarSettings(viewService);

	useEffect(() => {
		if (brandInfo.stores_count === 1) setIsSingleShop(true);
	}, [brandInfo.stores_count, setIsSingleShop]);

	useEffect(() => {
		setNeedTryLastStoreOnSelect(false);
	}, [setNeedTryLastStoreOnSelect]);

	useEffect(() => {
		if (group?.texts.web.pages_headers.menu) {
			setPageTitle(group.texts.web.pages_headers.menu.replace("{name}", selectedStore.name));
		}
	}, [setPageTitle, selectedStore.name, group?.texts.web.pages_headers.menu]);

	useEffect(() => {
		if (!brandInfo.transactions_activated) {
			setIsEMenu(true);
		} else if (selectedStore.is_enabled_emenu) {
			setIsEMenu(true);
		} else if (
			shipmentsService.computedStoreEmptyPaymentsMethods.notAvailableCash &&
			shipmentsService.computedStoreEmptyPaymentsMethods.notAvailableCustomPayments &&
			shipmentsService.computedStoreEmptyPaymentsMethods.notAvailableOnline
		) {
			console.log("e menu: no payment methods");
			setIsEMenu(true);
		} else if (
			shipmentsService.computedStoreEmptyPaymentsMethods.notAvailableCash &&
			shipmentsService.computedStoreEmptyPaymentsMethods.notAvailableCustomPayments &&
			paymentService.methods.length < 1
		) {
			console.log(
				"e menu: only online payment method available and no payment providers connected"
			);
			setIsEMenu(true);
		} else if (menuInStoreService.menuInStore?.is_e_menu) {
			setIsEMenu(true);
		} else {
			setIsEMenu(false);
		}
	}, [
		brandInfo.transactions_activated,
		menuInStoreService.menuInStore?.is_e_menu,
		paymentService.methods.length,
		searchParams,
		selectedStore.is_enabled_emenu,
		setIsEMenu,
		shipmentsService.computedStoreEmptyPaymentsMethods,
		shipmentsService.computedStoreEmptyShipments,
	]);

	return (
		<div>
			<AIChatWindow />

			<ShopBannerAndInfo />

			<MDBRow
				id={"products-container"}
				className={`w-100 m-0 ${!filtersBarSettings.computedIsFiltersBarVisible && viewService.view === "list" ? "" : "mt-2 mt-md-4"}`}
			>
				{!isMobile && selectedStore.has_categories && (
					<MDBCol size={12} lg={3} className={"px-0 pt-2"}>
						<ShopMenuCategories />
					</MDBCol>
				)}

				<MDBCol
					size={12}
					md={12}
					lg={computedProductsContainerSize}
					className={"mb-2 px-0 px-md-1"}
				>
					<ShopAutoTranslateWarning />

					{selectedStore.has_categories && <ShopBreadcrumb />}

					{selectedStore.has_categories && (
						<>
							<Box id={"shop-sub-categories-container"}></Box>
							<ShopSubCategoriesAndFiltersWrapper>
								<ShopSubCategories />
							</ShopSubCategoriesAndFiltersWrapper>
						</>
					)}

					{filtersBarSettings.computedIsFiltersBarVisible && (
						<ShopFilters filtersSettings={filtersBarSettings} />
					)}

					<Products view={viewService.view} />
				</MDBCol>

				{isShopInfo && (
					<MDBCol md={4} lg={3} className={"pt-2"}>
						<div className={"d-flex flex-column h-100 border-start ps-3 ms-1"}>
							<ShopMenuStickyWrapper id={"shop-info-container"}>
								<ShopInfo />
							</ShopMenuStickyWrapper>
						</div>
					</MDBCol>
				)}
			</MDBRow>

			<DetailedProductModal />

			<BottomButtons />
		</div>
	);
}
