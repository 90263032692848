import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import OrderContent from "../order/MakeOrder/OrderSuccess/OrderContent";
import { Order } from "../../api/shop/order/types";
import api from "../../api";
import useLocalisation from "../../hooks/localisation/useLocalisation";
import useSearchParamsFixed from "../../features/hooks/useSearchParamsFixed";
import RouteRedirect from "../../helpers/routeRedirect";
import { useSelectedStoreContext } from "../SelectedStore/context";
import { Box, Paper, Typography } from "@mui/material";
import { TgLink } from "../../helpers/ThemeComponents";
import changePage from "../../helpers/changePage";
import useAppContext from "../../useAppContext";
import { isAvailableOsAndBrowserForTgRedirect } from "../../helpers";
import useDeviceInfo from "../../features/hooks/useDeviceInfo";
import { useShopContext } from "../context";
import { NonModalDialogPage } from "../../features/NonModalDialog";
import useNewPaymentsService from "../payments/hooks/useNewPaymentsService";
import MessangerButton from "../../features/MessangerButton";

export default function CustomerOrderPage() {
	const {
		authService: { user },
		loadingManager: { getIsLoading, setIsLoading },
		bot,
		brandInfo,
	} = useAppContext();
	const { selectedStore } = useSelectedStoreContext();
	const { webAppData } = useShopContext();

	const { orderId } = useParams();
	const [searchParams, setSearchParams] = useSearchParamsFixed();
	const localisation = useLocalisation();

	const [order, setOrder] = useState<Order | null>(null);

	const isFromWebView = searchParams.get("is_webview");
	const botName = bot?.username;
	const tgLink = `https://t.me/${botName}`;

	const deviceInfo = useDeviceInfo();
	const [triedRedirect, setTriedRedirect] = useState<boolean>(false);

	const needBotButton =
		bot &&
		brandInfo?.bot_id &&
		user?.messangers?.includes(bot.bot_type || "") &&
		(bot.bot_type !== "telegram" || (bot.bot_type === "telegram" && !webAppData));

	const handleBackToShop = () => {
		if (selectedStore) {
			changePage(`/shop/${selectedStore.id}/menu`);
		} else {
			changePage(`/shop/select`);
		}
	};

	const orderToken = searchParams.get("order_token");
	const onLoad = useCallback(async () => {
		setIsLoading("order-page-order", true);

		if (isFromWebView && !webAppData) {
			if (!triedRedirect && botName) {
				console.log("try redirect tg", {
					botName,
					isAvailableOsAndBrowserForTgRedirect:
						isAvailableOsAndBrowserForTgRedirect(deviceInfo),
					tgLink,
				});

				try {
					window.location.href = tgLink;
				} finally {
					setTriedRedirect(true);
				}
			}
			return;
		}

		if (user?.id && orderToken) {
			return setSearchParams(prevState => {
				prevState.delete("order_token");
				return prevState;
			});
		}

		try {
			if (orderId) {
				const id = parseInt(orderId);
				const response = await api.shop.order.getOrder(id, orderToken);
				setOrder(response.data);
			}
		} catch (err: any) {
			console.log(err);
			setOrder(null);
		} finally {
			setIsLoading("order-page-order", false);
		}
	}, [
		setIsLoading,
		isFromWebView,
		webAppData,
		user?.id,
		orderToken,
		triedRedirect,
		botName,
		deviceInfo,
		tgLink,
		setSearchParams,
		orderId,
	]);

	const paymentService = useNewPaymentsService(
		selectedStore.id,
		order?.shipment || null,
		selectedStore.currency,
		null,
		() => onLoad()
	);

	useEffect(() => {
		onLoad().then();
	}, [onLoad]);

	const setOrderId = paymentService.setOrderId;
	useEffect(() => {
		if (order) {
			setOrderId(order.id);
		}
	}, [order, setOrderId]);

	const searchOrderId = searchParams.get("order_id");

	const redirectParams = useMemo(() => {
		const params: {
			store_id: number;
			order_id?: string | null | number;
			order_token?: string | null;
		} = {
			store_id: selectedStore.id,
		};
		if ((searchOrderId || orderId || order?.id) && orderToken) {
			params.order_id = searchOrderId || orderId || order?.id;
			params.order_token = orderToken;
		}

		return params;
	}, [order?.id, orderId, searchOrderId, orderToken, selectedStore.id]);

	if (getIsLoading()) return null;

	if (order && (order.status === "new" || order.status === "terminated_by_user")) {
		return (
			<RouteRedirect to={`shop/${order.store_id}/orders/checkout`} params={redirectParams} />
		);
	}

	if (!order) {
		return (
			<Typography color={"error"} textAlign={"center"}>
				{localisation.orders.orderNotFound}
			</Typography>
		);
	}

	return (
		<NonModalDialogPage
			containerProps={{
				maxWidth: "sm",
			}}
			header={
				<Box textAlign={"start"} px={3}>
					<Box>
						{localisation.orders.orderNumber.replace("{order_id}", order.id.toString())}
					</Box>
					<Typography variant={"body2"}>
						<TgLink className={"cursor-pointer"} onClick={() => handleBackToShop()}>
							{localisation.global.backHome}
						</TgLink>
					</Typography>
				</Box>
			}
		>
			<Paper elevation={4}>
				<OrderContent order={order} paymentService={paymentService} />

				{needBotButton && (
					<Box mt={3} px={3} pb={3} textAlign={"end"}>
						<MessangerButton bot={bot} />
					</Box>
				)}
			</Paper>
		</NonModalDialogPage>
	);
}

export function RedirectCustomerOrderPage() {
	const { orderId } = useParams();
	const [searchParams] = useSearchParamsFixed();

	const [redirectUrl, setRedirectUrl] = useState<string | null>(null);

	const orderToken = searchParams.get("order_token");
	useEffect(() => {
		const orderIdNum = orderId ? parseInt(orderId) : null;

		if (!orderIdNum) {
			setRedirectUrl("shop");
		} else {
			api.shop.order
				.getOrder(orderIdNum, orderToken)
				.then(response => {
					const order = response.data;
					setRedirectUrl(`shop/${order.store_id}/orders/${order.id}`);
				})
				.catch(err => {
					console.log(err);
					setRedirectUrl("shop");
				});
		}
	}, [orderId, orderToken]);

	if (!redirectUrl) return null;

	return (
		<RouteRedirect
			to={redirectUrl}
			params={{
				store_id: null,
			}}
		/>
	);
}
