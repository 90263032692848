import { API, photonMapApi } from "./base";
import shop from "./shop";
import { MapModuleGoogle, MapModulePhoton } from "./map";
import AuthApiModule from "./auth";
import MenuInStoreApiModule from "./menuInStore";
import InvoiceModule from "./invoice";
import LocalisationModule from "./localisation";
import GroupsApiModule from "./groups";
import BotsAPIModule from "./bots";
import PagesAPIModule from "./pages";
import ReviewsAPIModule from "./reviews";
import AdminApiModule from "./admin";

const api = {
	instance: API,
	auth: AuthApiModule(API),
	groups: GroupsApiModule(API),
	localisation: LocalisationModule(API),
	mapPhoton: MapModulePhoton(photonMapApi),
	mapGoogle: MapModuleGoogle(API),
	shop: shop,
	menuInStore: MenuInStoreApiModule(API),
	invoice: InvoiceModule(API),
	bot: BotsAPIModule(API),
	pages: PagesAPIModule(API),
	reviews: ReviewsAPIModule(API),
	admin: AdminApiModule(API),
};

export default api;
