import { AxiosInstance } from "axios";
import {
	ChangeEmailData,
	ChangePasswordData,
	ChatIDExists,
	ConfirmEmailRequestSent,
	CreateExternalLoginData,
	DeleteUserAccountData,
	EmailConfirmed,
	EmailExists,
	ExternalLogin,
	IsEmailConfirmed,
	IsManagerOrAdmin,
	LangData,
	LoginData,
	MarketingConsentPayload,
	MarketingInfo,
	OAuthAppleLoginToken,
	OAuthLoginToken,
	OAuthRegisterData,
	OAuthRegisterToken,
	OAuthToken,
	RegisterData,
	ResetPasswordData,
	SendConfirmEmailData,
	ShortTokenDataType,
	Token,
	UpdateUserData,
	User,
	UserAccountDeleted,
	UserIncustCustomerData,
} from "./types";
import { BotType } from "../bots/types";
import { OkResponse } from "../types";

export default function AuthApiModule(instance: AxiosInstance) {
	const tokenKey = "shop_token";
	const userKey = "auth-user";
	const userObjVersion = "1"; // if user schema wll be changed old data will be removed

	const getPath = (path: string) => "auth/" + path;

	return {
		getCurrentToken: () => localStorage.getItem(tokenKey),
		setCurrentToken: (token: string) => localStorage.setItem(tokenKey, token),
		removeCurrentToken: () => localStorage.removeItem(tokenKey),

		getCurrentUser: (): User | null => {
			const userData = localStorage.getItem(userKey);
			if (!userData) return null;

			try {
				const data = JSON.parse(userData);
				if (data.version !== userObjVersion || !data.user) {
					localStorage.removeItem(userKey);
					return null;
				}
				return data.user as User;
			} catch (err) {
				console.log("restoring user json error");
				console.log(err);
				localStorage.removeItem(userKey);
				return null;
			}
		},
		setCurrentUser: (user: User) => {
			localStorage.setItem(
				userKey,
				JSON.stringify({
					version: userObjVersion,
					user: user,
				})
			);
		},
		removeCurrentUser: () => localStorage.removeItem(userKey),

		login(data: LoginData) {
			return instance.post<Token>(getPath("login"), data);
		},
		loginByTgWebview(botId: number | string) {
			return instance.post<Token>(getPath("login/tg_webview"), null, {
				headers: {
					TGAuthorization: `${window.Telegram.WebApp.initData},${botId}`,
				},
			});
		},
		loginByShortToken(short_token: string) {
			return instance.post<ShortTokenDataType>(getPath("login/by_short_token"), {
				short_token: short_token,
			});
		},

		register(data: RegisterData) {
			return instance.post<Token>(getPath("register"), data);
		},

		sendConfirmEmail(data: SendConfirmEmailData) {
			return instance.post<ConfirmEmailRequestSent>(getPath("sendConfirmEmail"), data);
		},
		confirmEmail(confirmToken: string) {
			return instance.post<EmailConfirmed>(
				getPath("confirmEmail"),
				{},
				{
					headers: {
						Authorization: "Bearer " + confirmToken,
					},
				}
			);
		},
		checkIsEmailConfirmed(token: string) {
			return instance.get<IsEmailConfirmed>("checkIsEmailConfirmed", {
				headers: {
					Authorization: `bearer ${token}`,
				},
			});
		},

		checkIsEmailExists(email: string) {
			return instance.post<EmailExists>(getPath(`checkIsEmailExists/${email}`));
		},
		checkIsChatIdExists(chat_id: number) {
			return instance.get<ChatIDExists>(getPath("checkIsChatIdExists"), {
				params: { chat_id },
			});
		},

		getUser() {
			return instance.get<User>(getPath("user/"));
		},
		getOrSetUserLang(defaultLangs?: string[] | null) {
			return instance.post<LangData>(getPath("user/getOrSetUserLang"), {
				default_langs: defaultLangs,
			});
		},
		getUserIncustCustomerData(storeId?: number | null) {
			let params = {};
			if (storeId) {
				params = { store_id: storeId };
			}
			return instance.get<UserIncustCustomerData>(getPath("user/incustCustomerData"), {
				params: params,
			});
		},
		updateUser(data: UpdateUserData) {
			return instance.patch<User>(getPath("user/"), data);
		},
		updateUserLang(newLang: string) {
			return instance.patch<LangData>(getPath("user/lang"), { lang: newLang });
		},

		changeEmail(data: ChangeEmailData, token: string) {
			return instance.post<User>(getPath("changeEmail"), data, {
				headers: {
					Authorization: `bearer ${token}`,
				},
			});
		},
		changePassword(data: ChangePasswordData) {
			return instance.post<User>(getPath("user/changePassword"), data);
		},

		resetPassword(data: ResetPasswordData, token: string) {
			return instance.post<null>(getPath("resetPassword"), data, {
				headers: {
					Authorization: `bearer ${token}`,
				},
			});
		},

		createExternalLogin(data: CreateExternalLoginData) {
			return instance.post<ExternalLogin>(getPath("externalLogin/create"), data);
		},
		getExternalLogin(uuid: string) {
			return instance.get<ExternalLogin>(getPath(`externalLogin/${uuid}`));
		},
		cancelExternalLogin(uuid: string) {
			return instance.post<ExternalLogin>(getPath(`externalLogin/${uuid}/cancel`));
		},
		unlinkMessanger(messangerType: BotType, password: string) {
			return instance.post<User>(getPath("user/unlinkMessanger"), {
				messanger_type: messangerType,
				password: password,
			});
		},
		checkIsManagerOrAdmin() {
			return instance.get<IsManagerOrAdmin>(getPath(`user/checkIsManagerOrAdmin`));
		},
		loginByGoogle(payload: OAuthLoginToken) {
			return instance.post<OAuthToken>(getPath(`login/by_google`), payload);
		},
		loginByApple(payload: OAuthAppleLoginToken) {
			return instance.post<OAuthToken>(getPath(`login/by_apple`), payload);
		},
		registerByOAuth(payload: OAuthRegisterToken) {
			return instance.post<Token>(getPath(`register/oauth`), payload);
		},
		consentOAuth(payload: OAuthRegisterToken) {
			return instance.post<Token>(getPath(`customer_consent/oauth`), payload);
		},
		userInfoByOauthToken(payload: OAuthRegisterToken) {
			return instance.post<OAuthRegisterData>(getPath(`oauth/user_info`), payload);
		},
		getMarketingInfo() {
			return instance.get<MarketingInfo>(getPath(`marketing_info`));
		},
		updateMarketingConsent(payload: MarketingConsentPayload) {
			return instance.post<OkResponse>(getPath(`update_marketing_consent`), payload);
		},

		deleteAccount(payload: DeleteUserAccountData) {
			return instance.post<UserAccountDeleted>(getPath("user/delete-account"), payload);
		},
	};
}
