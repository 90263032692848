import { AxiosInstance } from "axios";

import { CreateInvoiceWebPayload, IInvoiceCreatedResult, Invoice, InvoiceTemplate } from "./types";
import { PaymentCheckoutData } from "../shop/payments/types";
import { MakePaymentPayload, PrePaymentPayload } from "../shop/basic/types";
import { OkResponse } from "../types";

export default function InvoiceModule(instance: AxiosInstance) {
	const getPath = (path: string) => `/payments/${path}`;

	const getHeaders = (token: string | null | undefined) =>
		token ? { Authorization: `Bearer ${token}` } : undefined;

	return {
		getInvoiceByToken(token: string) {
			return instance.get<Invoice>(getPath(`invoice/byToken`), {
				headers: getHeaders(token),
			});
		},

		getInvoice(invoiceId: number) {
			return instance.get<Invoice>(getPath(`invoice/${invoiceId}`));
		},

		savePaymentMethod(
			objectType: "order" | "invoice",
			objectId: number,
			payload: PrePaymentPayload,
			botId?: number | null | undefined,
			token?: string | null
		) {
			return instance.post<OkResponse>(
				getPath(`save_payment_method/${objectType}/${objectId}`),
				payload,
				{
					params: { bot_id: botId ?? undefined },
					headers: getHeaders(token),
				}
			);
		},

		makePayment(
			objectType: "order" | "invoice",
			objectId: number,
			payload: MakePaymentPayload,
			botId?: number | null | undefined,
			token?: string | null
		) {
			return instance.post<PaymentCheckoutData>(
				getPath(`make_payment/${objectType}/${objectId}`),
				payload,
				{
					params: { bot_id: botId ?? undefined },
					headers: getHeaders(token),
				}
			);
		},

		createInvoice(payload: CreateInvoiceWebPayload) {
			return instance.post<IInvoiceCreatedResult>(getPath("invoice/create"), payload);
		},

		getInvoiceTemplate(invoiceTemplateId: number) {
			return instance.get<InvoiceTemplate>(getPath(`invoiceTemplate/${invoiceTemplateId}`));
		},

		cancelEWalletPayment(paymentId: number) {
			return instance.post<PaymentCheckoutData>(getPath(`${paymentId}/cancel`));
		},
	};
}
