import { useCallback, useMemo, useState } from "react";
import api from "../../../../api";
import useTopupOptions, {
	IUseTopupOptions,
} from "../../../../features/TopupOptions/useTopupOptions";
import { SetState } from "../../../../types";
import {
	CreateOrderPayload,
	CreateOrderProduct,
	Order,
	OrderPersonalData,
} from "../../../../api/shop/order/types";
import { Product } from "../../../../api/shop/basic/types";
import { IUseFloatingSumType } from "./useFloatingSum";
import { useSelectedStoreContext } from "../../../SelectedStore/context";
import useCurrency from "../../../../services/useCurrencyService";
import useAppContext from "../../../../useAppContext";
import useIncustPayDataTopup from "../../../payments/hooks/useIncustPayDataTopup";
import useIncustPayData from "../../../payments/hooks/useIncustPayData";
import { calcConsentValues } from "../../../../auth/functions";

export default function useProductTopup(
	product: Product | null | undefined,
	count: number,
	floatingSum: IUseFloatingSumType
): IUseProductTopup {
	const {
		brandInfo,
		authService: { user },
	} = useAppContext();
	const { selectedStore } = useSelectedStoreContext();
	const incustPayDataTopup = useIncustPayDataTopup(selectedStore, product?.id);
	const incustPayData = useIncustPayData(selectedStore);

	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

	const computedProductAmount = useMemo(() => {
		if (product?.floating_sum_settings?.is_enabled) {
			return floatingSum.floatingSumAmount;
		}
		return product?.price ? product.price * count : 0;
	}, [
		count,
		floatingSum.floatingSumAmount,
		product?.floating_sum_settings?.is_enabled,
		product?.price,
	]);

	const currency = useCurrency();
	const optionsService = useTopupOptions(
		incustPayDataTopup,
		incustPayData,
		currency || "",
		false,
		computedProductAmount,
		product?.id
	);

	const savePersonalData = (form: CreateOrderPayload) => {
		const data: OrderPersonalData = {
			first_name: form.first_name,
			last_name: form.last_name,
			phone: form.phone,
			email: form.email,
		};
		api.shop.order.saveLastPersonalData(JSON.stringify(data));
	};

	const computedOrderProduct = useMemo(() => {
		const create_product: CreateOrderProduct = {
			quantity: count,
			product_id: product?.id || 0,
			attributes: [],
			floating_sum: product?.floating_sum_settings?.is_enabled
				? floatingSum.floatingSumAmount * 100
				: null,
			incust_pay_id: optionsService.selectedOption?.configId,
			incust_account: optionsService.selectedOption?.specialData,
			incust_card: optionsService.selectedOption?.cardNumber,
		};

		return create_product;
	}, [
		count,
		floatingSum.floatingSumAmount,
		optionsService.selectedOption?.cardNumber,
		optionsService.selectedOption?.configId,
		optionsService.selectedOption?.specialData,
		product?.floating_sum_settings?.is_enabled,
		product?.id,
	]);

	const handleConfirm = useCallback(
		async (form: CreateOrderPayload): Promise<ITopupMakeOrderResult> => {
			const result: ITopupMakeOrderResult = {
				error: null,
				order: null,
				canGoNext: false,
			};
			try {
				setIsLoading(true);
				const formCopy = JSON.parse(JSON.stringify(form)) as CreateOrderPayload;
				const consentValues = calcConsentValues(
					form.is_accepted_agreement,
					form.marketing_consent,
					brandInfo?.consent_mode,
					user
				);
				formCopy.is_accepted_agreement = consentValues.consent;
				formCopy.marketing_consent = consentValues.market_consent;
				const response = await api.shop.order.createOrder(formCopy);
				if (response.data && brandInfo) {
					savePersonalData(form);
					result.canGoNext = true;
					setError(null);
					result.order = response.data;
				}
			} catch (ex: any) {
				result.error = ex?.response?.data?.detail || ex.message;
			} finally {
				setIsLoading(false);
			}
			return result;
		},
		[brandInfo, user]
	);

	return {
		isLoading,
		error,
		setError,
		showConfirmModal,
		setShowConfirmModal,
		handleConfirm,
		optionsService,
		computedOrderProduct,
		product,
	};
}

export interface IUseProductTopup {
	isLoading: boolean;
	error: string | null;
	setError: SetState<string | null>;
	showConfirmModal: boolean;
	setShowConfirmModal: SetState<boolean>;
	handleConfirm: (form: CreateOrderPayload) => Promise<ITopupMakeOrderResult>;
	optionsService: IUseTopupOptions;
	computedOrderProduct: CreateOrderProduct;
	product: Product | null | undefined;
}

export interface ITopupMakeOrderResult {
	error: string | null;
	order: Order | null;
	canGoNext: boolean;
}
