import { useCallback } from "react";
import { useSelectedStoreOptional } from "../../SelectedStore/context";

export default function useGetProductLink() {
	const selectedStore = useSelectedStoreOptional();
	return useCallback(
		(productId: number) => {
			return `${window.location.origin}/shop/${selectedStore?.id}/menu/?product_id=${productId}`;
		},
		[selectedStore?.id]
	);
}
