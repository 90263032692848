import { AxiosInstance } from "axios";

export default function AdminApiModule(instance: AxiosInstance) {
	const getPath = (path: string) => "admin/" + path;

	return {
		getProfilesCount() {
			return instance.get<number>(getPath("profiles/count"));
		},
	};
}
