import { Alert, Box, Link } from "@mui/material";
import { CartProduct } from "../../api/shop/cart/types";
import useAppContext from "../../useAppContext";
import Interweave from "../../features/Interweave";
import f from "../../helpers/formatText";

interface INeedAuthProductsInCartAlertProps {
	products: CartProduct[];
}

export default function NeedAuthCartAlert(props: INeedAuthProductsInCartAlertProps) {
	const {
		authService: { setShowAuthorisationModal },
		localisation: { global, cart },
	} = useAppContext();

	const viewProducts = props.products.map((product, index) => {
		return `<strong>${product.product.name}</strong><br />`;
	});

	return (
		<Alert severity="error">
			{!!props.products.length ? (
				<Interweave
					content={f(cart.needAuthProductsInCartAlert, {
						products: viewProducts.join(),
					})}
				/>
			) : (
				<Interweave content={cart.needAuthCartAlert} />
			)}
		</Alert>
	);
}
