import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import api from "../../../api";
import {
	AddProductToCartData,
	Cart,
	CartProduct,
	CreateCartAttributeData,
	CreateCartProductData,
	SaveCartAttribute,
	UpdateCartProductRequest,
} from "../../../api/shop/cart/types";
import { Product, Store } from "../../../api/shop/basic/types";
import { CartServiceType, LastSelectedProductVariation, ProductVariationsState } from "./types";
import useSearchParamsFixed from "../../../features/hooks/useSearchParamsFixed";
import { useShopContext } from "../../context";
import useAppContext from "../../../useAppContext";
import useIncustService, {
	useIncustCheckItemsFromCart,
} from "../../../features/services/useIncustService";
import { CategoriesServiceType } from "../useCategoriesService/types";
import { removeOrderState } from "../../order/MakeOrder/OrderStepper/orderSessionState";

export default function useCartService(
	store: Store | null,
	categoriesService: CategoriesServiceType
): CartServiceType {
	const { lang, authService, showError } = useAppContext();

	const { brandInfo } = useShopContext();
	const cartRef = useRef<HTMLDivElement | null>(null);

	const [cart, setCart] = useState<Cart | null>(null);
	const [cartToken, setCartToken] = useState<string | null>(null);
	const [searchParams, setSearchParams] = useSearchParamsFixed();
	const [addingProductWithNewAttributesId, setAddingProductWithNewAttributesId] = useState<
		number | null
	>(null);
	const [selectedVariationCartProductId, setSelectedVariationCartProductId] = useState<
		number | null
	>(null);
	const [productToUpdate, setProductToUpdate] = useState<UpdateCartProductRequest | null>(null);
	const [productVariationsState, setProductVariationsState] =
		useState<ProductVariationsState | null>(null);

	const [isLoading, setIsLoading] = useState(false);

	useQuery<any>({
		queryKey: ["cart", authService.token, cartToken, store?.id, authService.user],
		queryFn: async () => await loadCart(null),
		initialData: null,
		enabled: !!store?.id,
		refetchInterval: 30 * 1000,
	});

	const loadCart = useCallback(
		async (token?: string | null | undefined) => {
			if (!cart) setIsLoading(true);

			try {
				if (
					(!authService.isAuthorised && !cartToken) ||
					!(token || authService.token || cartToken)
				) {
					setCart(null);
					return null;
				}
				const response = await api.shop.cart.getCart(
					store?.id,
					token || authService.token || cartToken,
					authService.isAuthorised ? cartToken : null
				);
				if (authService.isAuthorised && store?.id) {
					setCartToken(null);
					api.shop.cart.removeLocalCartToken(brandInfo.id, store?.id);
				} else if (response.data.access_token && store?.id) {
					api.shop.cart.setLocalCartToken(
						response.data.access_token,
						brandInfo.id,
						store?.id
					);
				}

				setCart(prevState => {
					if (!prevState) return response.data;
					if (prevState.hash === response.data.hash) return prevState;
					return response.data;
				});
				return response.data;
			} catch (err: any) {
				console.log(err);
				return null;
			} finally {
				setIsLoading(false);
			}
		},
		[cart, cartToken, store?.id, authService.token, authService.isAuthorised, brandInfo.id]
	);

	const getCartProduct = (productId: number) => {
		if (!cart?.cart_products) return null;
		return cart.cart_products.find(x => x.product.id === productId) ?? null;
	};

	const getCartProductByCartProductId = useCallback(
		(productId: number) => {
			if (!cart?.cart_products) return null;
			return cart.cart_products.find(x => x.id === productId) ?? null;
		},
		[cart?.cart_products]
	);

	const getCartAttribute = (productId: number, attrId: number) => {
		const product = getCartProductByCartProductId(productId);
		if (!product?.cart_attributes) return null;

		const attribute = product.cart_attributes.find(x => x.attribute.id === attrId);
		return attribute ?? null;
	};

	const getCartAttributeByCartProductId = (productId: number, attrId: number) => {
		const product = getCartProductByCartProductId(productId);
		if (!product?.cart_attributes) return null;

		const attribute = product.cart_attributes.find(x => x.attribute.id === attrId);
		return attribute ?? null;
	};

	async function setProductAttributeQuantity(
		productId: number,
		attrId: number,
		newQuantity: number | "+1" | "-1"
	) {
		if (!cart?.cart_products) return false;
		const cartProduct = getCartProductByCartProductId(productId);
		if (!cartProduct) {
			throw new Error(`Product ${productId} not found in cart`);
		}

		const cartAttr = getCartAttributeByCartProductId(productId, attrId);
		if (!cartAttr) return false;

		let quantity: number;

		switch (newQuantity) {
			case "+1":
				quantity = cartAttr.quantity + 1;
				break;
			case "-1":
				quantity = cartAttr.quantity - 1;
				break;
			default:
				quantity = newQuantity;
		}

		let prodQty;
		if (
			productToUpdate &&
			productToUpdate.quantity &&
			productId === productToUpdate.cartProductId
		) {
			prodQty = productToUpdate.quantity;
		} else {
			prodQty = cartProduct.quantity;
		}

		updateProductForUpdate(cartProduct.id, prodQty, {
			quantity: quantity,
			attribute_id: attrId,
		});

		return true;
	}

	const calculateProductTotalPrice = useCallback((cartProduct: CartProduct): number => {
		let totalPrice = cartProduct.floating_sum || cartProduct.product.price;

		if (cartProduct.cart_attributes) {
			totalPrice += cartProduct.cart_attributes.reduce((accumulator, cAttr) => {
				return accumulator + cAttr.attribute.price_impact * cAttr.quantity;
			}, 0);
		}

		totalPrice *= cartProduct.quantity;

		return totalPrice;
	}, []);

	const checkItems = useIncustCheckItemsFromCart(
		cart,
		calculateProductTotalPrice,
		categoriesService.getCategoryById
	);
	const incustService = useIncustService(
		brandInfo.id,
		store?.id,
		brandInfo.incust_data,
		lang,
		store?.currency || "",
		checkItems
	);

	const getDefaultSelectedCartProductVariation = useCallback(
		(productId: string) => {
			const filtered = cart?.cart_products?.filter(cp => cp.product.product_id === productId);
			if (filtered && filtered.length === 1) {
				return filtered[0].id;
			} else if (filtered && filtered.length > 1) {
				return filtered[filtered.length - 1].id;
			} else {
				return null;
			}
		},
		[cart?.cart_products]
	);

	useEffect(() => {
		if (store?.id) {
			setCartToken(api.shop.cart.getLocalCartToken(brandInfo.id, store.id));
		}
	}, [store?.id, brandInfo.id]);

	const cartTokenParam = searchParams.get("cart_token");
	useEffect(() => {
		if (cartTokenParam) {
			setCartToken(cartTokenParam);
			setSearchParams(prev => {
				const newData = new URLSearchParams(Object.fromEntries(prev));
				newData.delete("cart_token");
				return newData;
			});
		}
	}, [cartTokenParam, setSearchParams]);

	const productsCount =
		cart?.cart_products?.reduce((accumulator, object) => {
			return accumulator + object.quantity;
		}, 0) || 0;

	const totalSum =
		cart?.cart_products?.reduce((accumulator, cartProduct) => {
			return accumulator + calculateProductTotalPrice(cartProduct);
		}, 0) || 0;

	const addProductToCart = useCallback(
		async (
			product: Product,
			quantity: number = 1,
			cart_attributes?: CreateCartAttributeData[],
			productEl: HTMLDivElement | null = null,
			noAnimate: boolean = false,
			floatingSumAmount?: number
		) => {
			if (!store) return false;

			const animateProductToCart = (): Promise<boolean> => {
				return new Promise(resolve => {
					const cartEl = cartRef.current;
					if (!cartEl || !productEl || !product.image_url) {
						return resolve(false);
					}

					if (cartEl.classList.contains("d-none")) {
						cartEl.classList.remove("d-none");
						cartEl.classList.add("show-cart-animation");

						setTimeout(() => {
							cartEl.classList.add("animate");
						}, 50);
						setTimeout(() => {
							cartEl.classList.remove("show-cart-animation");
						}, 350);
					}

					const cartPos = cartEl.getBoundingClientRect();
					const productPos = productEl.getBoundingClientRect();
					const flyingImg = document.createElement("img");

					flyingImg.src = product.image_url;
					flyingImg.className = "flying-img";
					flyingImg.style.left = `${productPos.left}px`;
					flyingImg.style.top = `${productPos.top}px`;
					flyingImg.style.width = `${productPos.width}px`;
					flyingImg.style.height = `${productPos.height}px`;
					flyingImg.style.borderRadius = getComputedStyle(productEl).borderRadius;

					document.body.appendChild(flyingImg);

					setTimeout(() => {
						const cartXCenter = cartPos.left + (cartPos.right - cartPos.left) / 2;
						const productXCenter =
							productPos.left + (productPos.right - productPos.left) / 2;

						const cartYCenter = cartPos.top + (cartPos.bottom - cartPos.top) / 2;
						const productYCenter =
							productPos.top + (productPos.bottom - productPos.top) / 2;

						const x = cartXCenter - productXCenter;
						const y = cartYCenter - productYCenter;

						flyingImg.style.transform = `translate(${x}px, ${y}px) scale(0)`;
						flyingImg.style.opacity = ".3";
					}, 100);

					setTimeout(() => {
						resolve(true);
					}, 300);

					setTimeout(() => {
						flyingImg.remove();
					}, 600);
				});
			};

			const animateCartScale = (): Promise<boolean> => {
				return new Promise(resolve => {
					const cartEl = cartRef.current;
					if (!cartEl) {
						return resolve(false);
					}

					cartEl.classList.add("cart-scale-animation");

					setTimeout(() => {
						cartEl.classList.remove("cart-scale-animation");
						resolve(true);
					}, 350);
				});
			};

			const animate = async () => {
				if (noAnimate) return false;

				const resProduct = await animateProductToCart();
				const resJump = await animateCartScale();
				return resProduct || resJump;
			};

			const cartProduct: CreateCartProductData = {
				quantity: quantity,
				product_id: product.id,
				cart_attributes: cart_attributes,
			};
			if (product.floating_sum_settings?.is_enabled && floatingSumAmount) {
				cartProduct.floating_sum = floatingSumAmount;
			}

			try {
				const data: AddProductToCartData = {
					store_id: store.id,
					product: cartProduct,
				};
				setIsLoading(true);
				const response = await api.shop.cart.addProductToCart(data, cartToken);
				await animate();
				if (!cart && !authService.isAuthorised) {
					const token = response.data.access_token;
					if (token) {
						api.shop.cart.setLocalCartToken(token, brandInfo.id, store.id);
						setCartToken(token);
					}
				}
				if (response.data.cart) setCart(response.data.cart);
				removeOrderState();

				return true;
			} catch (err: any) {
				showError(err);
				try {
					await loadCart();
					return false;
				} catch (e) {
					console.log(e);
					return false;
				}
			} finally {
				setIsLoading(false);
			}
		},
		[authService.isAuthorised, brandInfo.id, cart, cartToken, loadCart, showError, store]
	);

	const updateProductForUpdate = useCallback(
		(
			cartProductId: number,
			qty?: number | null,
			attribute?: SaveCartAttribute | null,
			clear?: boolean
		) => {
			if (clear) return setProductToUpdate(null);

			setProductToUpdate(prevState => {
				if (!prevState) {
					return {
						cartProductId: cartProductId,
						quantity: qty || 1,
						attributes: attribute ? [attribute] : [],
					};
				}
				if (!attribute) {
					return {
						...prevState,
						quantity: qty || 1,
					};
				}
				if (prevState.attributes?.some(x => x.attribute_id === attribute.attribute_id)) {
					return {
						...prevState,
						attributes: prevState.attributes?.map(x => {
							if (x.attribute_id === attribute.attribute_id) {
								const item = {
									...x,
									quantity: attribute.quantity,
								};
								if (attribute.delete) item.delete = true;
								return item;
							}
							return x;
						}),
					};
				}
				return {
					...prevState,
					attributes: prevState.attributes
						? [...prevState.attributes, attribute]
						: [attribute],
				};
			});
		},
		[]
	);

	const updateProductVariationsState = useCallback(
		(
			needToChooseMode: boolean = false,
			fromCard: boolean = false,
			isAddToCart: boolean = false,
			lastState: LastSelectedProductVariation | null = null
		) => {
			setProductVariationsState(prevState => {
				if (!prevState) {
					return {
						needToChooseMode: needToChooseMode,
						fromCard: fromCard,
						isAddToCart: isAddToCart,
						lastSelectedProductVariations: lastState ? [lastState] : [],
					};
				}
				if (lastState) {
					return {
						...prevState,
						lastSelectedProductVariations: [
							...prevState.lastSelectedProductVariations,
							lastState,
						],
					};
				}
				return {
					...prevState,
					needToChooseMode: needToChooseMode,
					fromCard: fromCard,
					isAddToCart: isAddToCart,
				};
			});
		},
		[]
	);

	const clearCartProductLocalData = useCallback(() => {
		setAddingProductWithNewAttributesId(null);
		setSelectedVariationCartProductId(null);
		updateProductForUpdate(0, null, null, true);
		updateProductVariationsState(false, false, false);
	}, [updateProductForUpdate, updateProductVariationsState]);

	const computedNeedAuthProductsInCart = useMemo(() => {
		const products = cart?.cart_products?.filter(
			cart_product => cart_product.product.need_auth
		);
		return products || [];
	}, [cart?.cart_products]);

	const computedIsOnlyServiceProductsInCart = useMemo(() => {
		if (!cart?.cart_products?.length) return false;
		return !!cart?.cart_products?.every(
			cart_product => cart_product.product.type === "service"
		);
	}, [cart?.cart_products]);

	return {
		cartRef,
		cart,
		token: cartToken,
		loadCart,
		isLoading,
		addProductToCart,
		async updateProductInCart(
			cartProductId: number,
			qty?: number | null,
			attributes?: SaveCartAttribute[] | null,
			floatingSum?: number | null
		) {
			if (!store) return false;

			const cartProduct = getCartProductByCartProductId(cartProductId);
			if (!cartProduct) {
				throw new Error(`Product ${cartProductId} not found in cart`);
			}

			try {
				let payload: UpdateCartProductRequest | null = productToUpdate;
				if (!payload && qty) {
					payload = {
						quantity: qty,
						attributes: attributes,
					};
				} else if (!payload && floatingSum) {
					payload = { floating_sum: floatingSum, quantity: 1 };
				}
				if (payload) {
					delete payload.cartProductId;
					const response = await api.shop.cart.updateProductInCart(
						cartProductId,
						payload,
						store.id,
						cartToken
					);

					if (response.data) {
						setCart(response.data);
						removeOrderState();

						return true;
					}
				}

				return false;
			} catch (ex: any) {
				showError(ex);
				return false;
			} finally {
				setProductToUpdate(null);
			}
		},
		async deleteProductFromCart(productId: number, isCartProductId: boolean = true) {
			if (!store) return false;

			const cartProduct = getCartProductByCartProductId(productId);
			if (!cartProduct && isCartProductId) {
				throw new Error("CartProduct not found in cart");
			}
			// Если у нас есть информация о корзине и продукт корзины содержит id
			try {
				setIsLoading(true);
				const response = await api.shop.cart.deleteProductFromCart(
					productId,
					store.id,
					isCartProductId,
					cartToken
				);
				if (response.status === 200 && response.data) {
					setCart(response.data);
				} else {
					return false;
				}
				setAddingProductWithNewAttributesId(null);
				setSelectedVariationCartProductId(null);
				removeOrderState();

				return true;
			} catch (err: any) {
				showError(err);
				return false;
			} finally {
				setIsLoading(false);
			}
		},
		async addAttributeToProduct(productId: number, attrId: number, quantity: number = 1) {
			if (!store || !cart) return false;

			const cartProduct = getCartProductByCartProductId(productId);
			if (!cartProduct) {
				throw new Error("CartProduct not found in cart");
			}

			let prodQty = 1;
			if (
				productToUpdate &&
				productToUpdate.quantity &&
				productId === productToUpdate.cartProductId
			) {
				prodQty = productToUpdate.quantity;
			} else {
				prodQty = cartProduct.quantity;
			}

			updateProductForUpdate(cartProduct.id, prodQty, {
				quantity: quantity,
				attribute_id: attrId,
			});
			removeOrderState();

			return true;
		},
		async deleteAttributeFromProduct(productId: number, attrId: number) {
			const cartProduct = getCartProductByCartProductId(productId);
			if (!cartProduct) {
				throw new Error("CartProduct not found in cart");
			}
			const cartAttr = getCartAttribute(productId, attrId);
			if (!cartAttr) return false;

			let prodQty = 1;
			if (
				productToUpdate &&
				productToUpdate.quantity &&
				productId === productToUpdate.cartProductId
			) {
				prodQty = productToUpdate.quantity;
			} else {
				prodQty = cartProduct.quantity;
			}
			updateProductForUpdate(cartProduct.id, prodQty, {
				attribute_id: attrId,
				quantity: 1,
				delete: true,
			});
			removeOrderState();

			return true;
		},
		setProductAttrQty: setProductAttributeQuantity,
		increaseProductAttrQty(productId: number, attrId: number) {
			return setProductAttributeQuantity(productId, attrId, "+1");
		},
		decreaseProductAttrQty(productId: number, attrId: number) {
			return setProductAttributeQuantity(productId, attrId, "-1");
		},
		async clearCart() {
			if (!store) return false;

			try {
				setIsLoading(true);
				const response = await api.shop.cart.clearCart(store.id, cartToken);
				setCart(response.data);
				removeOrderState();

				return true;
			} catch (err) {
				showError(err);
				return false;
			} finally {
				incustService.clearLoyaltyData();
				setIsLoading(false);
			}
		},
		getCartProduct,
		calculateProductTotalPrice,
		calculateCartTotalSum(): number {
			return (
				cart?.cart_products?.reduce(
					(acc, cartProduct) => acc + calculateProductTotalPrice(cartProduct),
					0
				) ?? 0
			);
		},
		calculateCartProductsCount(): number {
			return (
				cart?.cart_products?.reduce((acc, cartProduct) => acc + cartProduct.quantity, 0) ??
				0
			);
		},
		isEmpty: !cart?.cart_products?.length,
		productsCount,
		totalSum,
		incustService: incustService,
		addingProductWithNewAttributesId,
		setAddingProductWithNewAttributesId,
		selectedVariationCartProductId,
		setSelectedVariationCartProductId,
		getDefaultSelectedCartProductVariation,
		getCartProductByCartProductId,
		productToUpdate,
		updateProductForUpdate,
		clearCartProductLocalData,
		updateProductVariationsState,
		productVariationsState,
		computedNeedAuthProductsInCart,
		computedIsOnlyServiceProductsInCart,
	};
}
