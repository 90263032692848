import {
	AvailablePayments,
	CustomShipment,
	PaymentMethod,
	Shipment,
} from "../../../api/shop/basic/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import useAppContext from "../../../useAppContext";
import api from "../../../api";
import {
	PaymentButtonBase,
	PaymentCheckoutData,
	PaymentPayload,
	PaymentProvidersData,
} from "../../../api/shop/payments/types";
import { InterfaceSums, PayProviders } from "../types";
import formatCurrency from "../../../helpers/formatCurrency";
import useGoPay, { IUseGoPay } from "./useGoPay";
import { CartServiceType } from "../../services/useCartService/types";
import changePage from "../../../helpers/changePage";
import { removeOrderState } from "../../order/MakeOrder/OrderStepper/orderSessionState";
import { useShopContext } from "../../context";
import useSearchParamsFixed from "../../../features/hooks/useSearchParamsFixed";
import { OrderShipment } from "../../../api/shop/order/types";
import { AxiosResponse } from "axios";
import { SetState } from "../../../types";
import { NO_CENT_CURRENCIES } from "../../../helpers/noCentCurrencies";

export default function useNewPaymentsService(
	storeId: number | null = null,
	selectedShipment: Shipment | CustomShipment | OrderShipment | null = null,
	currency?: string | null,
	cartService: CartServiceType | null = null,
	notOnlinePaymentCallback?: () => void,
	selectedIncustPayAccountId?: string | null,
	invoiceTemplateId: number | null = null
): IUsePaymentsService {
	const [orderId, setOrderId] = useState<number | null>(null);
	const [invoiceId, setInvoiceId] = useState<number | null>(null);
	const [invoiceToken, setInvoiceToken] = useState<string | null>(null);
	const [orderToken, setOrderToken] = useState<string | null>(null);
	const [sums, setSums] = useState<InterfaceSums>({
		totalSum: 0,
		sumToPay: 0,
	});
	const [isPrePaymentLoading, setIsPrePaymentLoading] = useState<boolean>(false);
	const [comment, setComment] = useState<string>("");
	const [error, setError] = useState<string>("");
	const [paymentsCollapseOpen, setPaymentsCollapseOpen] = useState<boolean>(true);
	const [interval, setIntervalState] = useState<any>(null);
	const [isExternalSuccessPayment, setIsExternalSuccessPayment] = useState<any>(null);
	const [uniposDesc, setUniposDesc] = useState<string | null>(null);

	const goPayService = useGoPay(storeId, setError);

	const [selectedProvider, setSelectedProvider] = useState<PaymentMethod | null>(null);

	const { brandInfo, lang, botId, showError } = useAppContext();
	const { webAppData, setBotWaitInfo } = useShopContext();

	const [showPayerFeeRow, setShowPayerFeeRow] = useState<string | null>(null);

	const [forceSingleMethod, setForceSingleMethod] = useState<PaymentMethod | null>(null);
	const [ignoreIncustFee, setIgnoreIncustFee] = useState<boolean>(false);
	const [paymentNoLongerAvailable, setPaymentNoLongerAvailable] = useState<boolean>(false);

	const [searchParams] = useSearchParamsFixed();

	const menuInStoreIdParam = searchParams.get("in_store_id");

	const {
		data: paymentsInfo,
		isPending,
		isFetching,
		refetch,
	} = useQuery({
		queryKey: [
			"payments-info",
			brandInfo?.group_id,
			menuInStoreIdParam,
			selectedShipment,
			selectedIncustPayAccountId,
		],
		enabled: !!brandInfo?.group_id,
		initialData: null,
		queryFn: async () => {
			if (!brandInfo?.group_id) return;
			try {
				let shipmentId: undefined | null | number = undefined;
				if (selectedShipment) {
					if ("settings_id" in selectedShipment) {
						if (selectedShipment.settings_id) {
							shipmentId = selectedShipment.settings_id as number;
						}
					} else {
						shipmentId = selectedShipment.id;
					}
				}
				const response = await api.shop.basic.getPaymentsInfo({
					brand_id: brandInfo?.id,
					store_id: storeId,
					is_qr_menu: !!menuInStoreIdParam,
					shipment_id: shipmentId || null,
					incust_account_id: selectedIncustPayAccountId,
					invoice_template_id: invoiceTemplateId,
				});
				if (response.data) {
					return response.data;
				}
			} catch (ex) {} //TODO: handle error
			return null;
		},
	});

	const goPayFunc = goPayService.goPay;
	const goPay = useCallback(
		async (loadedCheckout: Object) => {
			if (selectedProvider) {
				const paymentData: PaymentProvidersData = {
					amount_to_pay: sums.sumToPay,
					payment_providers_count: 1,
				};
				//@ts-ignore
				paymentData[selectedProvider.provider] = loadedCheckout;

				await goPayFunc(selectedProvider.provider as PayProviders, paymentData);
			}
		},
		[goPayFunc, selectedProvider, sums.sumToPay]
	);

	const paymentData = useMemo((): PaymentPayload | null => {
		return {
			order_id: orderId,
			store_id: storeId,
			order_token: orderToken,
			is_webview: !!window.Telegram?.WebApp.initData,
			invoice_id: invoiceId,
			invoice_token: invoiceToken,
			bot_id: botId,
			lang: lang,
			provider: selectedProvider?.provider || "unknown",
			comment: comment,
		} as PaymentPayload;
	}, [
		botId,
		comment,
		invoiceId,
		invoiceToken,
		lang,
		orderId,
		orderToken,
		selectedProvider?.provider,
		storeId,
	]);

	const startPaymentStatusPolling = useCallback(() => {
		if (interval) {
			clearInterval(interval);
		}

		const newInterval = setInterval(async () => {
			try {
				if (orderId || invoiceId) {
					const response = await api.shop.basic.checkPaymentStatus(
						orderToken ? orderToken : invoiceToken,
						orderId,
						invoiceId
					);
					if (response.data.payment_status === "payed") {
						clearInterval(newInterval); // Очищаємо поточний інтервал
						setIntervalState(null); // Скидаємо стан інтервалу
						setIsExternalSuccessPayment(true);
					}
				}
			} catch (err) {
				console.log(err);
				showError(err);
				clearInterval(newInterval); // Очищаємо інтервал при помилці
				setIntervalState(null); // Скидаємо стан інтервалу
			}
		}, 3000);

		// Зберігаємо новий інтервал в стані
		setIntervalState(newInterval);
	}, [interval, invoiceId, invoiceToken, orderId, orderToken, showError]);

	const handlePayTelegram = useCallback(
		async (url: string) => {
			if (webAppData && !orderId) return window.Telegram?.WebApp.close();

			if (!webAppData) {
				window.open(url, "_blank");

				startPaymentStatusPolling();
				return;
			}

			try {
				setBotWaitInfo({ active: true, isError: false });

				try {
					if (orderId && brandInfo?.id) {
						await api.shop.order.sendInvoiceToBot(brandInfo.id, orderId);
						window.Telegram?.WebApp.close();
					}
				} catch (err) {
					showError(err);
				}
			} finally {
				setBotWaitInfo({ active: false, isError: false });
			}
		},
		[brandInfo?.id, orderId, setBotWaitInfo, showError, webAppData, startPaymentStatusPolling]
	);

	const clearCart = useCallback(async () => {
		if (cartService) await cartService.clearCart();
	}, [cartService]);

	const setSuccessExternalOnlinePayment = useCallback(async () => {
		setIsExternalSuccessPayment(true);
	}, []);

	const makePrePayment = useCallback(
		async (
			onlyData: boolean = false,
			externalInvoiceId: number | null = null,
			externalInvoiceToken: string | null = null,
			externalOrderId: number | null = null,
			externalOrderToken: string | null = null
		): Promise<null | PaymentCheckoutData> => {
			console.log("*** SELECTED PROVIDER", selectedProvider);
			if (
				(!orderId && !invoiceId && externalInvoiceId && externalOrderId) ||
				!brandInfo?.id ||
				!selectedProvider
			)
				return null;

			setIsPrePaymentLoading(true);

			const payload = {
				payment_settings_id: selectedProvider.settings_id!,
				object_payment_settings_id: selectedProvider.object_settings_id,
				comment: comment,
				is_webview: !!window.Telegram?.WebApp.initData,
			};

			let objectType: "order" | "invoice";
			let objectId: number;
			let token: string | null | undefined;

			console.log(externalOrderId, orderId, externalOrderToken, orderToken);

			if (externalOrderId || orderId) {
				objectType = "order";
				objectId = externalOrderId || orderId!;
				token = externalOrderToken || orderToken;
			} else {
				objectType = "invoice";
				objectId = externalInvoiceId || invoiceId!;
				token = externalInvoiceToken || invoiceToken;
			}

			try {
				let response: AxiosResponse;
				if (selectedProvider.is_online && selectedProvider.provider !== "friend") {
					response = await api.invoice.makePayment(
						objectType,
						objectId,
						payload,
						botId,
						token
					);
				} else {
					response = await api.invoice.savePaymentMethod(
						objectType,
						objectId,
						payload,
						botId,
						token
					);
				}
				if (selectedProvider.is_online) {
					if (response.data) {
						console.log("*** PRE PAYMENT RESPONSE DATA", response.data);
						if (response.data.type === "tg_pay" && response.data.data.data.url) {
							return await handlePayTelegram(response.data.data.data.url);
						}
						if (response?.data?.data?.error) {
							setError(response.data.data.error);
							return null;
						}
						if (onlyData) return response.data.data as Object as PaymentCheckoutData;
						removeOrderState();
						goPay(response.data.data).then();
						return response.data.data as Object as PaymentCheckoutData;
					}
					return null;
				}

				if (notOnlinePaymentCallback) {
					notOnlinePaymentCallback();
					return null;
				}
				let url;
				let params;
				if (storeId && (orderId || externalOrderId)) {
					url = `/shop/${storeId}/orders/${orderId || externalOrderId}`;
					const currentOrderToken = orderToken || externalOrderToken || null;
					params = {
						is_order_created: "true",
						...(currentOrderToken && {
							order_token: currentOrderToken,
						}),
					};
				}
				removeOrderState();
				await clearCart();
				changePage(url || "", params || {});
			} catch (ex: any) {
				setError(ex?.response?.data?.detail || ex?.message || "Unknown pre payment error");
			} finally {
				setIsPrePaymentLoading(false);
			}

			return null;
		},
		[
			botId,
			brandInfo?.id,
			clearCart,
			comment,
			goPay,
			handlePayTelegram,
			invoiceId,
			invoiceToken,
			notOnlinePaymentCallback,
			orderId,
			orderToken,
			selectedProvider,
			storeId,
		]
	);

	const filteredPaymentMethods = useMemo(() => {
		const filteredMethods: PaymentMethod[] = [];
		if (paymentsInfo && paymentsInfo.methods) {
			for (const method of paymentsInfo.methods) {
				if (method.provider === "incust_pay" && ignoreIncustFee) {
					console.log("*** IGNORED INCUST PAY FEE");
					continue;
				}
				// if (selectedShipment) {
				// 	if (method.provider === "friend" && selectedShipment.allow_friend_payment) {
				// 		filteredMethods.push(method);
				// 		continue;
				// 	}
				// 	if (method.provider === "cash" && selectedShipment.allow_cash_payment) {
				// 		filteredMethods.push(method);
				// 		continue;
				// 	}
				// 	if (method.is_online && selectedShipment.allow_online_payment) {
				// 		filteredMethods.push(method);
				// 		continue;
				// 	}
				// 	filteredMethods.push(method);
				// 	continue;
				// }
				filteredMethods.push(method);
			}
		}

		return filteredMethods;
	}, [ignoreIncustFee, paymentsInfo]);

	const { isFetching: uniposIsLoading } = useQuery({
		queryKey: ["unipos-data", filteredPaymentMethods],
		enabled: !!(
			filteredPaymentMethods.some(x => x.provider === "unipos") &&
			(orderId || invoiceId || orderToken || invoiceToken)
		),
		initialData: null,
		queryFn: async () => {
			const provider = filteredPaymentMethods.find(x => x.provider === "unipos");
			if (!provider) return null;

			let objectType: "order" | "invoice";
			let objectId: number;
			let token: string | null | undefined;

			if (orderId) {
				objectType = "order";
				objectId = orderId!;
				token = orderToken;
			} else {
				objectType = "invoice";
				objectId = invoiceId!;
				token = invoiceToken;
			}

			const data = await api.invoice.makePayment(
				objectType,
				objectId,
				{
					payment_settings_id: provider.settings_id,
					object_payment_settings_id: provider.object_settings_id,
					comment: comment,
					is_webview: !!window.Telegram?.WebApp.initData,
					skip_process_order_payment: true,
				},
				botId,
				token
			);
			if (data && data.data && data.data.data && data.data.data.description) {
				const obj = data.data.data as Object as PaymentButtonBase;
				setUniposDesc(obj?.description || null);
			}
		},
	});

	const feeValue = useMemo(() => {
		let feeValue = 0;

		if (selectedProvider?.fee_value) {
			feeValue += selectedProvider.fee_value;
		}

		if (selectedProvider?.fee_percent) {
			feeValue += (sums.sumToPay * selectedProvider?.fee_percent) / 100;
		}

		if (currency && NO_CENT_CURRENCIES.has(currency)) {
			return Math.ceil(feeValue);
		}

		return feeValue;
	}, [currency, selectedProvider?.fee_percent, selectedProvider?.fee_value, sums.sumToPay]);

	const calcSumToPay = useMemo(() => {
		const currencyLang = brandInfo?.default_lang || lang;
		return formatCurrency((sums.sumToPay + feeValue).toString(), currencyLang, currency);
	}, [brandInfo?.default_lang, currency, feeValue, lang, sums.sumToPay]);

	const calculateFeeRange = useCallback(
		(withSum: boolean = false): string | null => {
			try {
				setShowPayerFeeRow(null);
				if (filteredPaymentMethods.length === 0) return null;

				const fees: number[] = filteredPaymentMethods.map(obj => {
					return (sums.sumToPay * (obj.fee_percent || 0)) / 100 + (obj.fee_value || 0);
				});

				let maxFee = Math.max(0, ...fees);
				const allFeesEqual = fees.every(fee => fee === maxFee);

				if (currency && NO_CENT_CURRENCIES.has(currency)) {
					maxFee = Math.ceil(maxFee);
				}

				if (allFeesEqual && maxFee) {
					if (!withSum) {
						return null;
					} else {
						setShowPayerFeeRow(
							formatCurrency(fees[0], brandInfo?.default_lang || lang, currency)
						);
						return formatCurrency(
							sums.sumToPay + fees[0],
							brandInfo?.default_lang || lang,
							currency
						);
					}
				}

				if (withSum) {
					return formatCurrency(sums.sumToPay, brandInfo?.default_lang || lang, currency);
				}

				if (maxFee === 0) return null;

				return formatCurrency(maxFee, brandInfo?.default_lang || lang, currency);
			} catch (ex) {
				return null;
			}
		},
		[filteredPaymentMethods, brandInfo?.default_lang, lang, currency, sums.sumToPay]
	);

	const setSelectedPaymentFunc = useCallback((value: PaymentMethod | null) => {
		setSelectedProvider(value);
		setError("");
		setComment("");
	}, []);

	const checkIsOrderPaymentIsOnlyOne = useCallback(
		(paymentId: number | null | undefined) => {
			if (paymentId && (paymentsInfo?.single_payment_method || forceSingleMethod)) {
				console.log("*** PAYMENT ID", paymentId);
				console.log("*** SINGLE", paymentsInfo?.single_payment_method);
				return (
					paymentsInfo?.single_payment_method?.settings_id === paymentId ||
					forceSingleMethod?.settings_id === paymentId
				);
			}
			return false;
		},
		[forceSingleMethod, paymentsInfo?.single_payment_method]
	);

	useEffect(() => {
		if (isExternalSuccessPayment) {
			if (orderId) {
				clearCart().then(() => {
					let url;
					let params;
					if (storeId && orderId) {
						url = `/shop/${storeId}/orders/${orderId}`;
						params = {
							is_order_created: "true",
							...(orderToken && {
								order_token: orderToken,
							}),
						};
					}
					removeOrderState();
					changePage(url || "", params || {});
				});
			} else {
				let url = `/success_payment`;
				let params = {
					is_order_created: "true",
					...(invoiceToken && {
						invoice_token: invoiceToken,
					}),
				};
				removeOrderState();
				changePage(url || "", params || {});
			}
		}
	}, [clearCart, isExternalSuccessPayment, orderId, orderToken, storeId, invoiceToken]);

	useEffect(() => {
		if (paymentsInfo?.single_payment_method || forceSingleMethod) {
			const provider = filteredPaymentMethods.find(
				x =>
					x.provider === paymentsInfo?.single_payment_method?.provider ||
					x.provider === forceSingleMethod?.provider
			);
			if (provider && provider.provider !== "incust_pay") {
				setSelectedProvider(provider);
				// setPaymentsCollapseOpen(false);
			}
		}
	}, [
		cartService,
		filteredPaymentMethods,
		forceSingleMethod,
		invoiceId,
		invoiceToken,
		orderId,
		orderToken,
		paymentsInfo?.single_payment_method,
	]);

	return {
		methods: filteredPaymentMethods,
		paymentsInfo,
		isPending,
		makePrePayment,
		setOrderId,
		setInvoiceId,
		setInvoiceToken,
		setOrderToken,
		setSelectedProvider: setSelectedPaymentFunc,
		setSums,
		goPayService,
		refetch,
		isLoading: isFetching || isPending,
		selectedProvider,
		isPrePaymentLoading,
		comment,
		setComment,
		error,
		setError,
		calcSumToPay,
		sumsToPay: sums,
		paymentData,
		clearCart,
		paymentsCollapseOpen,
		setPaymentsCollapseOpen,
		calculateFeeRange,
		uniposDesc,
		uniposIsLoading,
		showPayerFeeRow: showPayerFeeRow,
		forceSingleMethod,
		setForceSingleMethod,
		feeValue,
		setIgnoreIncustFee,
		setPaymentNoLongerAvailable,
		paymentNoLongerAvailable,
		checkIsOrderPaymentIsOnlyOne,
		setSuccessExternalOnlinePayment,
		storeId,
		orderId,
		invoiceId,
	};
}

export interface IUsePaymentsService {
	methods: PaymentMethod[];
	paymentsInfo: AvailablePayments | null | undefined;
	isPending: boolean;
	makePrePayment: (
		onlyData?: boolean,
		externalInvoiceId?: number | null,
		externalInvoiceToken?: string | null,
		externalOrderId?: number | null,
		externalOrderToken?: string | null
	) => Promise<null | PaymentCheckoutData>;
	setOrderId: (orderId: number) => void;
	setInvoiceId: (invoiceId: number) => void;
	setInvoiceToken: (invoiceToken: string) => void;
	setOrderToken: (orderToken: string) => void;
	setSelectedProvider: (provider: PaymentMethod | null) => void;
	setSums: (sums: InterfaceSums) => void;
	goPayService: IUseGoPay;
	refetch: () => void;
	isLoading: boolean;
	selectedProvider: PaymentMethod | null;
	isPrePaymentLoading: boolean;
	comment: string;
	setComment: (comment: string) => void;
	error: string;
	setError: (error: string) => void;
	calcSumToPay: string;
	sumsToPay: InterfaceSums;
	paymentData: PaymentPayload | null;
	clearCart: () => void;
	paymentsCollapseOpen: boolean;
	setPaymentsCollapseOpen: SetState<boolean>;
	calculateFeeRange: (withSum?: boolean) => string | null;
	uniposDesc: string | null;
	uniposIsLoading: boolean;
	showPayerFeeRow: string | null;
	forceSingleMethod: PaymentMethod | null;
	setForceSingleMethod: (value: PaymentMethod | null) => void;
	feeValue: number;
	setIgnoreIncustFee: (value: boolean) => void;
	setPaymentNoLongerAvailable: (value: boolean) => void;
	paymentNoLongerAvailable: boolean;
	checkIsOrderPaymentIsOnlyOne: (paymentId: number | null | undefined) => boolean;
	setSuccessExternalOnlinePayment: () => void;
	storeId: number | null;
	orderId: number | null;
	invoiceId: number | null;
}
