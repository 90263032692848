import { useInfiniteQuery } from "@tanstack/react-query";
import { useSelectedStoreContext } from "../../SelectedStore/context";
import api from "../../../api";
import { useRecoilValue } from "recoil";
import ProductsParamsState from "./ProductsParamsState";
import useAppContext from "../../../useAppContext";
import { useCallback, useEffect, useRef } from "react";

export default function useGetProducts(enabled = true) {
	const {
		appearance,
		loadingManager: { setIsLoading: lmSetIsLoading },
		lang,
	} = useAppContext();
	const { selectedStore, filtersService, categoriesService } = useSelectedStoreContext();

	const state = useRecoilValue(ProductsParamsState);

	const lastRequestLang = useRef<string | null>(null);
	const setIsLangLoading = useCallback(
		(isLoading: boolean) => {
			lmSetIsLoading("products-lang", isLoading);
		},
		[lmSetIsLoading]
	);

	useEffect(() => {
		return () => {
			setIsLangLoading(false);
		};
	}, [setIsLangLoading]);

	const limit = appearance?.appearanceQuery?.products_limit || 20;
	return useInfiniteQuery({
		enabled,
		queryKey: [
			"products",
			lang,
			selectedStore.id,
			state,
			filtersService.filtersSetId,
			categoriesService.selectedCategoryId,
			limit,
		],
		queryFn: async ({ pageParam, signal }) => {
			if (lastRequestLang.current && lastRequestLang.current !== lang) {
				setIsLangLoading(true);
			}

			lastRequestLang.current = lang;

			return api.shop.basic
				.getProducts(
					selectedStore.id,
					{
						...state,
						filters_set_id: filtersService.filtersSetId,
						category_id: categoriesService.selectedCategoryId,
						limit,
						cursor: pageParam,
					},
					signal
				)
				.then(({ data }) => data)
				.finally(() => setIsLangLoading(false));
		},
		getNextPageParam: lastPage => {
			return lastPage.cursor;
		},
		initialPageParam: null as string | null | undefined,
	});
}
