import { useMemo, useRef, useState } from "react";
import { Box } from "@mui/material";

import ProductQty from "./ProductQty/ProductQty";
import { useSelectedStoreContext } from "../../SelectedStore/context";
import { ProductCardToCartButton, ProductInfoButton } from "./ProductCardButtons";
import { CreateCartAttributeData } from "../../../api/shop/cart/types";
import useCartProduct from "../../services/useCartService/hooks/useCartProduct";
import useProductPrice from "../../services/useCartService/hooks/useProductPrice";
import { IUseFloatingSumType } from "./hooks/useFloatingSum";
import { IUseProductGiftType } from "./hooks/useProductGift";
import { IUseProductTopup } from "./hooks/useProductTopup";
import useSelectProduct from "../../services/useNewProductsService/useSelectProduct";
import { Product } from "../../../api/shop/basic/types";

interface IAttributeDrawerCartMenuProps {
	product: Product;
	selectedAttributes: CreateCartAttributeData[];
	productQty: number;
	setProductQty: (qty: number) => any;
	backgroundColor: string;
	computedRequiredAttributesWarning: () => boolean;
	errorText: string | null;
	floatingSum: IUseFloatingSumType;
	gift?: IUseProductGiftType | null;
	topupService?: IUseProductTopup | null;
}

export default function ProductCartMenu(props: IAttributeDrawerCartMenuProps) {
	const { product } = props;

	const { cartService } = useSelectedStoreContext();
	const selectProduct = useSelectProduct();
	const [addToCartLoading, setAddToCartLoading] = useState(false);
	const elRef = useRef<HTMLDivElement>(null);

	const { cartProduct, isProductInCart } = useCartProduct(product.id);
	const { computedProductPriceWithQty } = useProductPrice(
		product,
		props.selectedAttributes,
		props.productQty,
		cartProduct?.quantity || 0,
		false,
		props.floatingSum
	);

	const addProductToCart = async () => {
		const isRequiredFilled = props.computedRequiredAttributesWarning();
		if (isRequiredFilled) {
			try {
				setAddToCartLoading(true);

				if (isProductInCart && cartProduct) {
					let floatingSumVal = 0;
					if (cartProduct.floating_sum)
						floatingSumVal = props.floatingSum.floatingSumAmount;

					await cartService.updateProductInCart(
						cartProduct.id,
						null,
						null,
						floatingSumVal
					);
					cartService.clearCartProductLocalData();
					selectProduct(null);
					return;
				}
				if (product) {
					await cartService.addProductToCart(
						product,
						props.productQty,
						props.selectedAttributes,
						null,
						false,
						props.floatingSum.floatingSumAmount
					);
					cartService.setSelectedVariationCartProductId(null);
					cartService.setAddingProductWithNewAttributesId(null);
					selectProduct(null);
				}
			} finally {
				setAddToCartLoading(false);
			}
		}
	};

	const computedPrice = useMemo(() => {
		//TODO: temporary disabled
		// if (!!props.topupService?.optionsService.computedAmountWithCharge) {
		// 	return formatCurrency(
		// 		props.topupService?.optionsService.computedAmountWithCharge,
		// 		brandInfo?.default_lang || lang,
		// 		selectedStore.currency
		// 	);
		// }
		return computedProductPriceWithQty;
	}, [
		computedProductPriceWithQty,
		// lang,
		// props.topupService?.optionsService.computedAmountWithCharge,
		// selectedStore.currency,
	]);

	return (
		<>
			<Box
				id={"cart-controls"}
				sx={{
					position: "sticky",
					bottom: 0,
					left: 0,
					right: 0,
					zIndex: 2,
					backgroundColor: "var(--mui-palette-background-paper)",
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					display={"flex"}
					alignItems={"center"}
					sx={{
						width: "100%",
						paddingX: "20px",
						backgroundColor: props.backgroundColor,
						backgroundImage: "var(--mui-overlays-16)",
						transition: "background-color 0.3s",
					}}
					justifyContent={"space-between"}
					ref={elRef}
					flexWrap={"wrap"}
				>
					{product?.type === "info" ? (
						<ProductInfoButton product={product} fromDetailed={true} />
					) : (
						<>
							{!product?.floating_sum_settings?.is_enabled &&
								(product?.type !== "gift" || !props.gift?.computedNeedAuth) &&
								!product?.floating_qty_enabled && (
									<Box paddingY={"8px"}>
										<ProductQty
											cartProduct={isProductInCart ? cartProduct : null}
											selectedProduct={product}
											textSize={"fs-6"}
											iconSize={"medium"}
											buttonSize={"medium"}
											customClass={"justify-content-center me-2"}
											productQty={props.productQty}
											setProductQty={props.setProductQty}
										/>
									</Box>
								)}
							{product && (
								<Box flex={"1"} paddingY={"8px"}>
									<ProductCardToCartButton
										product={product}
										isInCart={isProductInCart}
										textSize={"fs-6"}
										iconSize={"medium"}
										buttonSize={"large"}
										addProductToCart={addProductToCart}
										addToCartLoading={addToCartLoading}
										fullWidth={true}
										variant={"contained"}
										cartProduct={isProductInCart ? cartProduct : null}
										price={computedPrice}
										errorText={props.errorText}
										floatingSum={props.floatingSum}
										gift={props.gift}
										topupService={props.topupService}
									/>
								</Box>
							)}
						</>
					)}
				</Box>
			</Box>
		</>
	);
}
