import { Box, Grid, Typography } from "@mui/material";
import BasePayButton from "../PaymentButtons/BasePayButton";
import { PayButtonIcon } from "../PaymentButtons/PayButton";
import { PaymentMethod } from "../../../api/shop/basic/types";
import { useEffect, useMemo, useRef } from "react";
import formatCurrency from "../../../helpers/formatCurrency";
import useAppContext from "../../../useAppContext";
import { IUsePaymentsService } from "../hooks/useNewPaymentsService";
import { PayProviders } from "../types";
import { NO_CENT_CURRENCIES } from "../../../helpers/noCentCurrencies";

interface PaymentItemProps {
	isLast: boolean;
	setSelectedProvider: (method: PaymentMethod) => void;
	setCollapse: () => void;
	method: PaymentMethod;
	totalAmount: number;
	currency: string | undefined;
	paymentsService: IUsePaymentsService;
	isSelectItem: boolean;
	isCollapsed: boolean;
	payButtonMaxHeight: number | null;
	setPayButtonMaxHeight: (value: number) => void;
	uniposDesc?: string | null;
	uniposLoading?: boolean | null;
	selectedIncustAccount?: string | null;
}

export default function PaymentItem(props: PaymentItemProps) {
	const btnRef = useRef<HTMLButtonElement | null>(null);

	const computedName = useMemo(() => {
		if (props.method.provider === "incust_pay") {
			if (props.isSelectItem && props.selectedIncustAccount) {
				return props.selectedIncustAccount;
			}
		}

		return props.method.name;
	}, [props.isSelectItem, props.method.name, props.method.provider, props.selectedIncustAccount]);

	const computedDesc = useMemo(() => {
		if (props.method.provider === "unipos" && props.uniposDesc) {
			return props.uniposDesc || props.method.desc || "";
		}
		return props.method.desc || "";
	}, [props.method.desc, props.method.provider, props.uniposDesc]);

	const setPayButtonMaxHeight = props.setPayButtonMaxHeight;
	useEffect(() => {
		if (btnRef.current && setPayButtonMaxHeight) {
			let height = btnRef.current.clientHeight;
			if (!height || height < 24) {
				height = 24;
			}
			setPayButtonMaxHeight(height);
		}
	}, [setPayButtonMaxHeight]);

	return (
		<BasePayButton
			onClick={() => {
				props.setSelectedProvider(props.method);
				props.setCollapse();
			}}
			sx={props.isLast ? { borderBottom: 0 } : undefined}
			loading={!!(props.method.provider === PayProviders.Unipos && props.uniposLoading)}
			isCollapsed={props.isCollapsed}
			isSelectedItem={props.isSelectItem}
		>
			<Box
				ref={btnRef}
				sx={{
					minHeight:
						!props.isSelectItem && props.payButtonMaxHeight
							? `${props.payButtonMaxHeight}px`
							: "auto",
					alignContent: "center",
					alignSelf: "center",
					overflow: "hidden",
					overflowWrap: "break-word",
					width: "100%",
				}}
			>
				<Box display={"flex"} alignSelf={"center"} flex={1} width={"100%"}>
					<PayButtonIcon
						path={props.method.logo}
						alt={props.method.name || props.method.provider}
					/>
					<Box sx={{ pl: 2 }} alignSelf={"center"} overflow={"hidden"} width={"100%"}>
						<Grid container gap={1} flexWrap={"nowrap"} alignItems={"center"}>
							<Grid
								item
								xs={true}
								display={"flex"}
								overflow={"hidden"}
								whiteSpace={"nowrap"}
							>
								<Typography
									sx={{ lineHeight: 1.2, alignSelf: "center" }}
									overflow={"hidden"}
									textOverflow={"ellipsis"}
									variant={"subtitle1"}
								>
									{computedName}
								</Typography>
							</Grid>
						</Grid>
						{!props.isSelectItem && computedDesc && (
							<Grid container gap={1} flexWrap={"nowrap"}>
								<Grid
									item
									xs={true}
									display={"flex"}
									overflow={"hidden"}
									whiteSpace={"nowrap"}
								>
									{computedDesc && (
										<Typography
											textOverflow={"ellipsis"}
											variant={"body2"}
											color={"text.secondary"}
											textTransform={"none"}
											overflow={"hidden"}
										>
											{computedDesc}
										</Typography>
									)}
								</Grid>
							</Grid>
						)}
						{!props.isSelectItem && (
							<PaymentFee
								feeValue={props.method.fee_value || 0}
								feePercent={props.method.fee_percent || 0}
								totalAmount={props.totalAmount}
								currency={props.currency}
								anyFee={!!props.paymentsService.calculateFeeRange()}
							/>
						)}
					</Box>
				</Box>
			</Box>
		</BasePayButton>
	);
}

interface IPaymentFeeProps {
	feeValue: number;
	feePercent: number;
	totalAmount: number;
	currency: string | undefined;
	anyFee?: boolean;
}

export function PaymentFee(props: IPaymentFeeProps) {
	const { brandInfo, lang, localisation } = useAppContext();

	const computedFeeAmount = useMemo(() => {
		const percentFee = props.feePercent ? (props.totalAmount * props.feePercent) / 100 : 0;
		const valueFee = props.feeValue || 0;

		let value: number;
		if (valueFee && percentFee) {
			value = valueFee + percentFee;
		} else {
			value = valueFee || percentFee;
		}

		if (!value) return "";

		if (props.currency && NO_CENT_CURRENCIES.has(props.currency)) {
			value = Math.ceil(value);
		}

		return `${formatCurrency(value, brandInfo?.default_lang || lang, props.currency)}`;
	}, [
		brandInfo?.default_lang,
		lang,
		props.currency,
		props.feePercent,
		props.feeValue,
		props.totalAmount,
	]);

	if (!props.totalAmount || (!computedFeeAmount && !props.anyFee)) {
		return <></>;
	}

	return (
		<Typography
			textAlign={"start"}
			variant={"body2"}
			color={"text.secondary"}
			textTransform={"none"}
		>
			{computedFeeAmount ? (
				<>
					{localisation.orders.payerFeeOrderListText}
					{": "}
					{computedFeeAmount}
				</>
			) : (
				localisation.payment.noFee
			)}
		</Typography>
	);
}
