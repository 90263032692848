import { useCallback, useEffect, useMemo } from "react";
import { SelectedProductTabType } from "../DetailedProduct";
import useSearchParamsFixed from "../../../../features/hooks/useSearchParamsFixed";
import { Product } from "../../../../api/shop/basic/types";

export default function useProductTabs(product: Product | null): IUseProductTabs {
	const [searchParams, setSearchParams] = useSearchParamsFixed();

	const setActiveTab = useCallback(
		(tab: SelectedProductTabType) => {
			setSearchParams(prev => {
				const newParams = new URLSearchParams(prev.toString());
				console.log("setting tab", tab);
				newParams.set("tab", tab);
				return newParams;
			});
		},
		[setSearchParams]
	);

	const availableTabs = useMemo(() => {
		const res: SelectedProductTabType[] = [];
		if ((!product || product.description) && product?.type !== "info") {
			res.push("description");
		}
		if (
			(!product || Object.keys(product.characteristics).length > 0) &&
			product?.type !== "info"
		) {
			res.push("characteristics");
		}
		return res;
	}, [product]);

	const defaultTab: SelectedProductTabType = availableTabs.length
		? availableTabs[0]
		: "description";
	const activeTabParam = searchParams.get("tab");
	const activeTab: SelectedProductTabType = useMemo(() => {
		if (!activeTabParam) {
			return defaultTab;
		}
		// @ts-ignore
		if (availableTabs.includes(activeTabParam)) {
			return activeTabParam as SelectedProductTabType;
		}
		return defaultTab;
	}, [activeTabParam, defaultTab, availableTabs]);

	useEffect(() => {
		if (activeTabParam && activeTabParam !== activeTab) {
			setActiveTab(defaultTab);
		}
	}, [activeTabParam, activeTab, defaultTab, setActiveTab]);

	return {
		activeTab,
		availableTabs,
		setActiveTab,
	};
}

export interface IUseProductTabs {
	activeTab: SelectedProductTabType;
	availableTabs: SelectedProductTabType[];
	setActiveTab: (tab: SelectedProductTabType) => void;
}
