import { ThemeModalProps } from "../../../helpers/ThemeComponents/types";
import { ThemeModal } from "../../../helpers/ThemeComponents";
import useAppContext from "../../../useAppContext";
import {
	Alert,
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	IconButton,
	InputAdornment,
	Link,
	Typography,
} from "@mui/material";
import Interweave from "../../../features/Interweave";
import useProfilesCount from "./useProfilesCount";
import { getErrorText } from "../../../helpers/errors";
import { AdminUrl } from "../../../config";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import useDeleteAccount from "./useDeleteAccount";
import TextField from "@mui/material/TextField";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import f from "../../../helpers/formatText";

export default function DeleteAccountModal(props: ThemeModalProps) {
	const {
		localisation,
		authService: { user },
	} = useAppContext();

	const profilesCount = useProfilesCount();

	const [deleteProfilesCheckbox, setDeleteProfilesCheckbox] = useState(false);
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	const deleteAccount = useDeleteAccount();

	const { setOpen } = props;
	useEffect(() => {
		if (!user && setOpen) {
			setOpen(false);
		}
	}, [setOpen, user]);

	if (!user) return null;

	return (
		<ThemeModal title={localisation.auth.deleteAccountModalTitle} maxWidth={"sm"} {...props}>
			<Typography sx={{ whiteSpace: "pre-wrap" }}>
				<Interweave noWrap content={localisation.auth.deleteAccountModalMessage} />
			</Typography>
			{profilesCount.isError ? (
				<Alert severity={"error"} variant={"filled"} sx={{ mt: 2 }}>
					<Interweave content={getErrorText(profilesCount.error)} />
				</Alert>
			) : (
				profilesCount.isSuccess &&
				profilesCount.data > 0 && (
					<>
						<Alert sx={{ mt: 2 }} severity={"warning"} variant={"filled"}>
							<Interweave
								content={f(localisation.auth.deleteAccountModalProfilesWarning, {
									profiles_count: profilesCount.data,
								})}
							/>
							<Link
								component={"button"}
								color={"inherit"}
								onClick={() => {
									window.open(AdminUrl, "_blank");
								}}
							>
								{localisation.auth.deleteAccountModalReviewProfilesButton}
							</Link>
						</Alert>
						<FormControlLabel
							control={
								<Checkbox
									checked={deleteProfilesCheckbox}
									onChange={e => setDeleteProfilesCheckbox(e.target.checked)}
								/>
							}
							label={localisation.auth.deleteAccountModalDeleteProfilesCheckbox}
						/>
					</>
				)
			)}

			{user.is_password && (
				<TextField
					sx={{ mt: 2 }}
					fullWidth
					value={password}
					onChange={e => setPassword(e.target.value)}
					type={showPassword ? "text" : "password"}
					label={localisation.auth.loginPasswordLabel}
					InputProps={{
						endAdornment: (
							<InputAdornment position={"end"}>
								<IconButton
									onClick={() => setShowPassword(prev => !prev)}
									onMouseDown={event => event.preventDefault()}
									edge={"end"}
								>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			)}

			{deleteAccount.isError && (
				<Alert severity={"error"} variant={"filled"}>
					<Interweave content={getErrorText(deleteAccount.error)} />
				</Alert>
			)}

			<Box sx={{ mt: 3 }}>
				<LoadingButton
					disabled={
						!profilesCount.isSuccess ||
						(profilesCount.data > 0 && !deleteProfilesCheckbox) ||
						(user.is_password && !password)
					}
					loadingPosition={"start"}
					loading={profilesCount.isPending || deleteAccount.isPending}
					fullWidth
					onClick={() => deleteAccount.mutate({ current_password: password || null })}
				>
					{localisation.auth.deleteAccountModalConfirmButton}
				</LoadingButton>
				<Button
					sx={{ mt: 1 }}
					fullWidth
					variant={"contained"}
					onClick={() => setOpen?.(false)}
				>
					{localisation.auth.deleteAccountModalCancelButton}
				</Button>
			</Box>
		</ThemeModal>
	);
}
