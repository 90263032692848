import { useEffect, useMemo, useState } from "react";
import { MDBIcon, MDBBtn } from "mdb-react-ui-kit";

import CartItem from "./CartItem";
import Loader from "../../loader/loader";
import { TgLink } from "../../helpers/ThemeComponents";
import useLocalisation from "../../hooks/localisation/useLocalisation";
import CartNote from "../CartNote";
import { useShopContext } from "../context";
import DetailedProductModal from "../menu/product/DetailedProductModal";
import Loyalty from "../loyalty";
import { useSelectedStoreContext } from "../SelectedStore/context";
import MUIPopConfirm from "../../features/MUIPopConfirm";
import { Alert, Box, BoxProps, Button, Container, Typography } from "@mui/material";
import changePage from "../../helpers/changePage";
import useAppContext from "../../useAppContext";
import Total, { ITotalItem } from "../../features/Total";
import CollapsableComponent from "../../features/CollapsableComponent";
import Divider from "@mui/material/Divider";
import Interweave from "../../features/Interweave";
import NeedAuthCartAlert from "./NeedAuthCartAlert";
import useShipmentPrices from "../services/useShipmentPrices";
import useSelectedShipmentService from "../order/MakeOrder/useSelectedShipmentService";
import useAuthSettings from "../../services/useAuthSettings";
import useUserIncustCustomerData from "../../hooks/incust/useUserIncustCustmerData";

export default function ShopCart() {
	const {
		setPageTitle,
		localisation,
		lang,
		loadingManager: { setIsLoading },
	} = useAppContext();

	const { menuInStoreService, brandInfo } = useShopContext();
	const { selectedStore, cartService } = useSelectedStoreContext();

	const menuInStore = menuInStoreService.menuInStore;

	const isLoading = cartService.isLoading;

	const [disabledByServiceProducts, setDisabledByServiceProducts] = useState(false);

	const getTotalItems = useMemo(() => {
		const totalItems: ITotalItem[] = [];

		totalItems.push({
			name: localisation.orders.toPayHeader.replace(
				"{count}",
				cartService.calculateCartProductsCount().toString()
			),
			value: cartService.calculateCartTotalSum(),
			isPrice: true,
		});

		const totalName = localisation.orders.overallHeader + ":";

		let totalAmount;
		if (
			cartService.incustService.processedCheck &&
			(cartService.incustService.processedCheck.amount_to_pay ||
				cartService.incustService.processedCheck.amount_to_pay === 0)
		)
			totalAmount = cartService.incustService.processedCheck.amount_to_pay;
		else totalAmount = cartService.calculateCartTotalSum();

		totalItems.push({
			name: totalName,
			value: totalAmount,
			isFinal: true,
			isPrice: true,
		});

		return totalItems;
	}, [cartService, localisation.orders.overallHeader, localisation.orders.toPayHeader]);

	useEffect(() => {
		setPageTitle(selectedStore.name + " - " + localisation.cart.cart);
	}, [localisation.cart.cart, selectedStore.name, setPageTitle]);

	const isMenuInStore = !!menuInStore;
	useEffect(() => {
		if (isMenuInStore || cartService.isEmpty) {
			setIsLoading("cart-loyalty", false);
		} else if (brandInfo.loyalty_info?.loyalty_enabled) {
			if (!cartService.incustService.internalLoading) {
				setIsLoading("cart-loyalty", false);
			} else {
				setIsLoading("cart-loyalty", true);
			}
		}

		return () => {
			setIsLoading("cart-loyalty", false);
		};
	}, [
		brandInfo.loyalty_info?.loyalty_enabled,
		isMenuInStore,
		setIsLoading,
		cartService.isEmpty,
		cartService.incustService.internalLoading,
	]);

	const {
		query: { refetch: refetchIncustCustomer },
	} = useUserIncustCustomerData();

	useEffect(() => {
		refetchIncustCustomer();
	}, [refetchIncustCustomer]);

	return (
		<Container maxWidth={"sm"} sx={{ mb: 5 }}>
			{isLoading && <Loader key={"loader"} show={isLoading} />}

			<CartHeader isLoading={isLoading} />

			<CartEmpty isLoading={isLoading} />

			<CartBody isLoading={isLoading} />

			<DetailedProductModal />

			{!cartService.isEmpty && (
				<>
					<Total
						items={getTotalItems}
						currency={selectedStore.currency}
						lang={lang}
						incustCheck={cartService.incustService.processedCheck}
					>
						<Loyalty
							disableLogin
							needFullPageLoading={true}
							incustService={cartService.incustService}
						/>
					</Total>

					{cartService.computedIsOnlyServiceProductsInCart && (
						<CartServiceProductsComponent
							setDisabledByServiceProducts={setDisabledByServiceProducts}
						/>
					)}

					{cartService.incustService.computedCurrencyError && (
						<Alert severity={"warning"} sx={{ my: 1 }}>
							<Box>{cartService.incustService.computedCurrencyError}</Box>
						</Alert>
					)}

					<div className={"mt-2"}>
						<MakeOrderButton
							disabled={
								cartService.incustService.processCheckLoading ||
								cartService.incustService.internalLoading ||
								cartService.incustService.isAddBonusesOnlyError ||
								disabledByServiceProducts
							}
						/>
					</div>
				</>
			)}
		</Container>
	);
}

export function ClearCartButton({
	children,
	nodeWrapperProps,
}: {
	children?: any;
	nodeWrapperProps?: BoxProps;
}) {
	const { cartService } = useSelectedStoreContext();

	const localisation = useLocalisation();

	return (
		<MUIPopConfirm
			cancelText={localisation.global.cancelButton}
			confirmText={localisation.global.clearButton}
			onConfirm={() => cartService.clearCart()}
			childrenHeightAuto={true}
			message={localisation.global.clearMessage.replace(
				"{object_name}",
				localisation.cart.cart
			)}
			node={
				children || (
					<Button disableRipple>
						<span className={"text-danger"} style={{ textDecoration: "underline" }}>
							{localisation.global.clearButton}
						</span>
					</Button>
				)
			}
			nodeWrapperProps={nodeWrapperProps}
		/>
	);
}

interface ILoadingCartComponentProps {
	isLoading: boolean;
}

function CartBody(props: ILoadingCartComponentProps) {
	const { localisation } = useAppContext();
	const { cartService } = useSelectedStoreContext();

	if (!cartService.cart?.cart_products || cartService.cart?.cart_products.length === 0)
		return <div className="text-center small theme-text">{localisation.cart.cartEmpty}</div>;

	if (props.isLoading) return null;

	return (
		<div className={"mb-4"}>
			<div className={"w-100 mb-3"}>
				<div className={"w-100"}>
					<CartNote isInCart={true} />
				</div>
				<CartItems />
			</div>

			<CartAddMore />
		</div>
	);
}

interface IMakeOrderButtonProps {
	disabled: boolean;
}

function MakeOrderButton(props: IMakeOrderButtonProps) {
	const {
		localisation,
		authService: { user, setShowAuthorisationModal },
		groupService: { group },
		brandInfo,
	} = useAppContext();
	const { selectedStore, cartService } = useSelectedStoreContext();

	const authSettings = useAuthSettings(brandInfo?.group_id);

	if (!user && group) {
		return (
			<Box mt={3}>
				{!!brandInfo?.incust_data?.loyalty_id && (
					<Box
						mb={
							!user &&
							(!!cartService.computedNeedAuthProductsInCart.length ||
								authSettings.settings?.is_auth_for_orders_enabled)
								? 2
								: 0
						}
					>
						{!!group.texts.web.loyalty.register.title && (
							<Typography variant={"subtitle1"} fontWeight={"bold"}>
								{group.texts.web.loyalty.register.title}
							</Typography>
						)}
						{!!group.texts.web.loyalty.register.description && (
							<CollapsableComponent collapsedSize={240}>
								<Interweave
									content={group.texts.web.loyalty.register.description}
								/>
							</CollapsableComponent>
						)}
					</Box>
				)}

				{!user &&
					(!!cartService.computedNeedAuthProductsInCart.length ||
						authSettings.settings?.is_auth_for_orders_enabled) &&
					(authSettings.settings?.is_auth_for_orders_enabled ? (
						<NeedAuthCartAlert products={[]} />
					) : (
						<NeedAuthCartAlert products={cartService.computedNeedAuthProductsInCart} />
					))}

				{(!user ||
					cartService.computedNeedAuthProductsInCart.length ||
					authSettings.settings?.is_auth_for_orders_enabled) && (
					<Box mt={3}>
						<Button
							fullWidth
							size={"large"}
							variant={"contained"}
							disabled={props.disabled}
							onClick={() => setShowAuthorisationModal(true)}
						>
							{group.texts.web.order.sign_in_button}
						</Button>

						{!!group.texts.web.order.guest_button &&
							!user &&
							!authSettings.settings?.is_auth_for_orders_enabled &&
							!cartService.computedNeedAuthProductsInCart.length && (
								<>
									{!!group.texts.web.order.or_text && (
										<Divider sx={{ mt: 3, mb: 1, color: "text.secondary" }}>
											{group.texts.web.order.or_text}
										</Divider>
									)}
									<Button
										fullWidth
										size={"large"}
										variant={"text"}
										disabled={
											props.disabled ||
											(!user &&
												(!!cartService.computedNeedAuthProductsInCart
													.length ||
													authSettings.settings
														?.is_auth_for_orders_enabled))
										}
										onClick={() =>
											changePage(`/shop/${selectedStore.id}/orders/checkout`)
										}
									>
										{group.texts.web.order.guest_button}
									</Button>
								</>
							)}
					</Box>
				)}
			</Box>
		);
	}

	return (
		<Button
			fullWidth
			size={"large"}
			variant={"contained"}
			onClick={() => changePage(`/shop/${selectedStore.id}/orders/checkout`)}
			disabled={props.disabled}
		>
			{localisation.cart.cartMakeOrder}
		</Button>
	);
}

function CartAddMore() {
	const { localisation } = useAppContext();
	const { selectedStore } = useSelectedStoreContext();

	return (
		<MDBBtn
			outline
			className={"w-100 border theme-button outline"}
			size="lg"
			onClick={() => changePage(`/shop/${selectedStore.id}/menu`)}
		>
			<MDBIcon fas icon="plus" className={"me-2"} />
			{localisation.cart.cartAddMore}
		</MDBBtn>
	);
}

function CartItems() {
	const { cartService } = useSelectedStoreContext();

	if (!cartService.cart?.cart_products) return null;

	return (
		<>
			{cartService.cart.cart_products.map(cartProduct => (
				<div className={"w-100"} key={cartProduct.id}>
					<CartItem cartProduct={cartProduct} />
				</div>
			))}
		</>
	);
}

function CartEmpty(props: ILoadingCartComponentProps) {
	const { localisation } = useAppContext();
	const { cartService } = useSelectedStoreContext();

	if (props.isLoading || cartService.cart?.cart_products) return null;

	return <div className="text-center small">{localisation.cart.cartEmpty}</div>;
}

function CartHeader(props: ILoadingCartComponentProps) {
	const { localisation } = useAppContext();
	const { cartService } = useSelectedStoreContext();
	const { selectedStore } = useSelectedStoreContext();

	if (props.isLoading) return null;

	return (
		<>
			<div className={"fs-3 fw-bold theme-text"}>
				<span>{localisation.cart.cart}</span>
			</div>
			<div className={"d-flex mb-2"}>
				<div className={"me-2"}>
					<TgLink
						className={"cursor-pointer"}
						onClick={() => changePage(`/shop/${selectedStore.id}/menu`)}
					>
						{localisation.global.backHome}
					</TgLink>
				</div>
				{!!cartService.cart?.cart_products?.length && (
					<div className="ms-auto drop-mdb-btn-style">
						<ClearCartButton />
					</div>
				)}
			</div>
		</>
	);
}

interface ICartServiceProductsComponentProps {
	setDisabledByServiceProducts: (value: boolean) => void;
}

function CartServiceProductsComponent(props: ICartServiceProductsComponentProps) {
	const { cartService, selectedStore, shipmentsService } = useSelectedStoreContext();
	const totalSum = cartService.calculateCartTotalSum();
	const selectedShipmentService = useSelectedShipmentService(totalSum);
	const shipmentPrices = useShipmentPrices(
		shipmentsService,
		selectedShipmentService,
		selectedStore.id,
		cartService.calculateCartTotalSum,
		null,
		null,
		cartService.productsCount
	);

	const setSelectedShipment = selectedShipmentService.setSelectedShipment;
	useEffect(() => {
		setSelectedShipment(shipmentsService.noDeliveryTypeShipment);
	}, [setSelectedShipment, shipmentsService.noDeliveryTypeShipment]);

	const setDisabledByServiceProducts = props.setDisabledByServiceProducts;
	useEffect(() => {
		if (
			shipmentPrices.selectedPriceLoading ||
			shipmentPrices.allPricesLoading ||
			!!shipmentPrices.error
		) {
			setDisabledByServiceProducts(true);
		} else {
			setDisabledByServiceProducts(false);
		}
	}, [
		setDisabledByServiceProducts,
		shipmentPrices.allPricesLoading,
		shipmentPrices.error,
		shipmentPrices.selectedPriceLoading,
	]);

	if (shipmentPrices.error) {
		return (
			<Alert severity={"error"} sx={{ mt: 1 }}>
				<Interweave content={shipmentPrices.error.text} />
			</Alert>
		);
	}

	return <></>;
}
