import { Box, Button } from "@mui/material";
import useLocalisation from "../../../../hooks/localisation/useLocalisation";
import { useEffect, useMemo, useState } from "react";
import {
	BillingAddress,
	CreateOrderPayload,
	NotAvailableProduct,
	Order,
	OrderPersonalData,
} from "../../../../api/shop/order/types";
import { SetMergeState } from "../../../../helpers/useMergedState";
import { SetState } from "../../../../types";
import { useShopContext } from "../../../context";
import { useSelectedStoreContext } from "../../../SelectedStore/context";
import api from "../../../../api";
import MUIPopConfirm from "../../../../features/MUIPopConfirm";
import { LoadingButton } from "@mui/lab";
import CheckIcon from "@mui/icons-material/Check";
import { getErrorText } from "../../../../helpers/errors";
import changePage from "../../../../helpers/changePage";
import useAppContext from "../../../../useAppContext";
import { SelectedShipmentServiceType } from "../useSelectedShipmentService";
import { AxiosResponse } from "axios";
import Interweave from "../../../../features/Interweave";
import { IShipmentPricesService } from "../../../services/useShipmentPrices";
import useAuthSettings from "../../../../services/useAuthSettings";
import { IUsePaymentsService } from "../../../payments/hooks/useNewPaymentsService";
import { removeOrderState, saveOrderState } from "./orderSessionState";
import { IUseUpdateOrder } from "../useUpdateOrder";
import f from "../../../../helpers/formatText";
import { calcConsentValues } from "../../../../auth/functions";
import { ALWAYS_OPEN_MODAL_PROVIDERS } from "../../../payments/constants";

interface OrderStepperButtonsProps {
	setNextStep: () => void;
	setPrevStep: () => void;

	prevDisabled?: boolean;
	nextDisabled?: boolean;

	isNextSubmit?: boolean;

	isPrevLoading?: boolean;
	isNextLoading?: boolean;
}

export function OrderStepperButtons(props: OrderStepperButtonsProps) {
	const localisation = useLocalisation();

	return (
		<Box display={"flex"} gap={1} mt={2}>
			<LoadingButton
				loading={props.isPrevLoading}
				disabled={props.prevDisabled}
				onClick={props.setPrevStep}
			>
				{localisation.orders.stepPrev}
			</LoadingButton>
			<LoadingButton
				loading={props.isNextLoading}
				variant={"contained"}
				disabled={props.nextDisabled}
				onClick={props.setNextStep}
			>
				{localisation.orders.stepNext}
			</LoadingButton>
		</Box>
	);
}

interface OrderStepperLastStepButtonsProps {
	setPrevStep: () => void;
	setNextStep: () => void;

	form: CreateOrderPayload;
	setForm: SetMergeState<CreateOrderPayload>;

	selectedShipmentService: SelectedShipmentServiceType;
	paymentsService: IUsePaymentsService;

	notAvailableProducts: NotAvailableProduct[];
	setNotAvailableProducts: (products: NotAvailableProduct[]) => void;

	setIsLoading?: (isLoading: boolean) => void;

	billingForm: BillingAddress;

	setNeedLoginForEmail: (needLogin: boolean) => void;

	setErrorText: SetState<string | null>;

	setCreatedOrder: (order: Order | null) => void;
	createdOrder: Order | null;

	shipmentPricesService: IShipmentPricesService;
	setShowPayments: (val: boolean) => void;
	updateOrderService: IUseUpdateOrder;
}

export function OrderStepperLastStepButtons(props: OrderStepperLastStepButtonsProps) {
	const localisation = useLocalisation();

	return (
		<Box display={"flex"} gap={1} mt={2}>
			<Button onClick={props.setPrevStep} sx={{ mt: 1, mr: 1 }}>
				{localisation.orders.stepPrev}
			</Button>
			<SubmitOrderButton
				form={props.form}
				setForm={props.setForm}
				selectedShipmentService={props.selectedShipmentService}
				paymentsService={props.paymentsService}
				notAvailableProducts={props.notAvailableProducts}
				setNotAvailableProducts={props.setNotAvailableProducts}
				setNeedLoginForEmail={props.setNeedLoginForEmail}
				billingForm={props.billingForm}
				setNextStep={props.setNextStep}
				setErrorText={props.setErrorText}
				setCreatedOrder={props.setCreatedOrder}
				createdOrder={props.createdOrder}
				shipmentPricesService={props.shipmentPricesService}
				setShowPayments={props.setShowPayments}
				updateOrderService={props.updateOrderService}
			/>
		</Box>
	);
}

interface SubmitOrderButtonProps {
	form: CreateOrderPayload;
	setForm: SetMergeState<CreateOrderPayload>;

	selectedShipmentService: SelectedShipmentServiceType;
	paymentsService: IUsePaymentsService;

	notAvailableProducts: NotAvailableProduct[];
	setNotAvailableProducts: (products: NotAvailableProduct[]) => void;
	setIsLoading?: (isLoading: boolean) => void;
	setNeedLoginForEmail: (needLogin: boolean) => void;
	billingForm: BillingAddress;

	setNextStep: () => void;

	setErrorText: SetState<string | null>;

	setCreatedOrder: (order: Order | null) => void;
	createdOrder: Order | null;

	shipmentPricesService: IShipmentPricesService;
	setShowPayments: (val: boolean) => void;
	updateOrderService: IUseUpdateOrder;
}

function SubmitOrderButton(props: SubmitOrderButtonProps) {
	const localisation = useLocalisation();
	const [isOrderSending, setIsOrderSending] = useState(false);
	const [isAllProductsAvailable, setIsAllProductsAvailable] = useState(true);

	const {
		authService: { user, loadUser },
		showError,
		groupService,
	} = useAppContext();

	const { addressValue, brandInfo, menuInStoreService, addressCoords } = useShopContext();
	const { ordersCountService, selectedStore, cartService, shipmentsService } =
		useSelectedStoreContext();

	const { selectedShipment, selectedShipmentPrice } = props.selectedShipmentService;
	const { makePrePayment } = props.paymentsService;

	const submit = async () => {
		if (
			!props.form.comment &&
			brandInfo.order_fields_settings.order_comment_mode === "required"
		) {
			return props.setErrorText(
				f(localisation.orders.commentIsRequired, {
					comment_label: groupService.group?.texts.web.order.order_comment_label,
				})
			);
		}

		if (props.createdOrder) {
			props.updateOrderService.updateCommentAndMarketing().then(res => {
				const singlePaymentMethod =
					props.paymentsService.paymentsInfo?.single_payment_method ||
					props.paymentsService.forceSingleMethod;

				if (
					singlePaymentMethod &&
					singlePaymentMethod.is_online &&
					!ALWAYS_OPEN_MODAL_PROVIDERS.includes(singlePaymentMethod.provider) &&
					!["required", "optional"].includes(singlePaymentMethod.need_comment)
				) {
					try {
						makePrePayment(
							false,
							null,
							null,
							props.createdOrder?.id,
							props.createdOrder?.token
						).then();
						return;
					} catch (err) {
						console.log(err);
					}
				}
				if (res && res.ok) {
					props.setShowPayments(true);
				}
			});
			return;
		}
		try {
			props.setErrorText(null);
			setIsOrderSending(true);

			const currentCart = await cartService.loadCart();
			if (currentCart?.hash !== cartService.cart?.hash) {
				return props.setErrorText(localisation.orders.cartHashError);
			}

			const isAllProductsAvailable = await checkProductsAvailability();
			if (!isAllProductsAvailable || !selectedShipment || !selectedStore) return;

			// Заполняем форму данными о заказе
			const formCopy = JSON.parse(JSON.stringify(props.form)) as CreateOrderPayload;
			const consentValues = calcConsentValues(
				props.form.is_accepted_agreement,
				props.form.marketing_consent,
				brandInfo?.consent_mode,
				user
			);
			formCopy.is_accepted_agreement = consentValues.consent;
			formCopy.marketing_consent = consentValues.market_consent;
			props.setForm({ is_accepted_agreement: consentValues.consent });

			const isService = cartService.computedIsOnlyServiceProductsInCart;
			if (isService) {
				const noShipment = shipmentsService.noDeliveryTypeShipment;
				if (noShipment) {
					formCopy.shipment_method_id = noShipment.id;
				}
			} else {
				formCopy.shipment_method_id = selectedShipment.id;
				formCopy.delivery_address = addressValue;
			}
			formCopy.menu_in_store_id = menuInStoreService.menuInStore?.id;

			if (props.selectedShipmentService.base_type !== "delivery" || isService) {
				formCopy.delivery_address = null;
				formCopy.address_comment = null;
			}

			if (
				brandInfo.is_get_order &&
				props.selectedShipmentService.is_base_shipment &&
				props.selectedShipmentService.base_type === "delivery"
			) {
				formCopy.delivery_address = `${formCopy.address_street}, ${formCopy.address_house}`;
			}

			// Если есть лояльность, то добавим к объекту заказа чек и отредактируем цены
			if (
				cartService.incustService.processedCheck &&
				cartService.incustService.settings?.currency === selectedStore.currency
			) {
				formCopy.loyalty_type = "incust";
				formCopy.price_after_loyalty =
					cartService.incustService.processedCheck.amount_to_pay;
				formCopy.bonuses_redeemed =
					cartService.incustService.processedCheck.bonuses_redeemed_amount;
				formCopy.discount = cartService.incustService.processedCheck.discount_amount;
				formCopy.incust_check = cartService.incustService.processedCheck;
				formCopy.incust_terminal_id = cartService.incustService.settings.id;
			}

			if (selectedShipment.delivery_datetime_mode === "disabled" || isService) {
				formCopy.desired_delivery_date = null;
				formCopy.desired_delivery_time = null;
			}

			if (selectedStore.billing_settings && selectedStore.billing_settings.is_require) {
				formCopy.billing_address = props.billingForm;
			}

			formCopy.price = selectedShipmentPrice;
			if (!selectedShipmentPrice) {
				formCopy.free_shipment = true;
			}

			if (formCopy.delivery_address && addressCoords && !isService) {
				formCopy.address_lat = addressCoords[1].toString();
				formCopy.address_lng = addressCoords[0].toString();
				const addressObj = api.shop.basic.getLastAddressObject();
				if (addressObj && addressObj.placeId) {
					formCopy.address_place_id = addressObj.placeId;
				}
			}
			formCopy.type = "regular";

			const criticalError = checkCriticalErrorsByForm(formCopy);
			if (criticalError) {
				return props.setErrorText(criticalError);
			}
			// Пытаемся создать заказ
			try {
				const token = user ? null : cartService.token;
				let response: AxiosResponse<Order>;
				if (menuInStoreService.menuInStore?.id) {
					response = await api.shop.order.createOrderInStore(
						{
							store_id: selectedStore.id,
							menu_in_store_id: menuInStoreService.menuInStore.id,

							is_accepted_agreement: formCopy.is_accepted_agreement,

							products: formCopy.products,
							comment: formCopy.comment,

							loyalty_type: formCopy.loyalty_type,
							price_after_loyalty: formCopy.price_after_loyalty,
							bonuses_redeemed: formCopy.bonuses_redeemed,
							discount: formCopy.discount,

							incust_check: formCopy.incust_check,
							incust_terminal_id: formCopy.incust_terminal_id,
							tips_sum: formCopy.tips_sum || 0,
						},
						token
					);
				} else {
					response = await api.shop.order.createOrder(formCopy, token);
				}

				ordersCountService.setOrdersCount(prevState => prevState + 1);
				savePersonalData();

				if (!!cartService.incustService.processedCheck && user) {
					loadUser().then();
				}

				if (cartService.incustService.processedCheck?.amount_to_pay === 0 && response.data.sum_to_pay === 0) {
					props.paymentsService.clearCart();
					removeOrderState();
					changePage(`/shop/${selectedStore.id}/orders/${response.data.id}`, {
						is_order_created: "true",
						...(response.data.token && {
							order_token: response.data.token,
						}),
					});
					return;
				}

				if (
					props.paymentsService.paymentsInfo?.single_payment_method?.provider ===
						"incust_pay" ||
					props.paymentsService.paymentsInfo?.single_payment_method?.provider ===
						"friend" ||
					props.paymentsService.paymentsInfo?.single_payment_method?.provider ===
						"orange" ||
					props.paymentsService.forceSingleMethod?.provider === "incust_pay" ||
					props.paymentsService.forceSingleMethod?.provider === "friend" ||
					props.paymentsService.forceSingleMethod?.provider === "orange"
				) {
					props.setCreatedOrder(response.data);
					saveOrderState(response.data, "createdOrder");
					props.setShowPayments(true);
					return;
				}

				if (
					(props.paymentsService.paymentsInfo?.single_payment_method ||
						props.paymentsService.forceSingleMethod) &&
					props.paymentsService.paymentsInfo?.single_payment_method?.need_comment !==
						"required" &&
					props.paymentsService.forceSingleMethod?.need_comment !== "required" &&
					props.paymentsService.paymentsInfo?.single_payment_method?.need_comment !==
						"optional" &&
					props.paymentsService.forceSingleMethod?.need_comment !== "optional"
				) {
					try {
						await makePrePayment(
							false,
							null,
							null,
							response.data.id,
							response.data.token
						);
					} catch (err) {
						console.log(err);
					}
				}

				if (
					props.paymentsService.paymentsInfo?.single_payment_method &&
					!props.paymentsService.paymentsInfo?.single_payment_method.is_online &&
					props.paymentsService.paymentsInfo?.single_payment_method?.need_comment !==
						"required"
				) {
					await cartService.clearCart();
				}

				props.setCreatedOrder(response.data);
				saveOrderState(response.data, "createdOrder");
				if (
					!(
						props.paymentsService.paymentsInfo?.single_payment_method &&
						!props.paymentsService.paymentsInfo?.single_payment_method.is_online &&
						props.paymentsService.paymentsInfo?.single_payment_method?.need_comment !==
							"required"
					)
				) {
					props.setShowPayments(true);
				}
			} catch (err: any) {
				if (
					err?.response?.data?.detail_data?.error_code ===
					"store_order_missing_incust_check"
				) {
					await cartService.incustService.reFetchProcessCheck();
					return showError(err, true, () => changePage(`/shop/${selectedStore.id}/cart`));
				}
				if (err?.response?.status === 409) {
					return showError(err, true, () => changePage(`/shop/${selectedStore.id}/cart`));
				}
				if (err?.response?.data?.detail === "user with email exist") {
					return props.setNeedLoginForEmail(true);
				}
				props.setErrorText(getErrorText(err, localisation.global.errUnknown));
			}
		} finally {
			setIsOrderSending(false);
		}
	};

	const checkProductsAvailability = async () => {
		if (!selectedStore) return false;

		const args = {
			store_id: selectedStore.id,
			brand_id: brandInfo.id,
		};
		try {
			let token: string | null = null;
			if (!user) token = cartService.token;
			const response = await api.shop.cart.checkProductsAvailability(args, token);
			props.setNotAvailableProducts(response.data);
			if (response.data.length > 0) props.setErrorText(localisation.orders.notAvailableMsg);
			return !response.data.some(notAvailableProduct =>
				cartService.cart?.cart_products?.some(
					cartProduct => notAvailableProduct.product_id === cartProduct.product.id
				)
			);
		} catch (err: any) {
			console.log(err);
			props.setErrorText(getErrorText(err, localisation.global.errUnknown));
			return false;
		}
	};

	const savePersonalData = () => {
		const data: OrderPersonalData = {
			first_name: props.form.first_name,
			last_name: props.form.last_name,
			phone: props.form.phone,
			delivery_address: addressValue,
			email: props.form.email,
			address_comment: props.form.address_comment || "",
			address_floor: props.form.address_floor || 0,
			address_house: props.form.address_house || null,
			address_flat: props.form.address_flat || null,
			address_entrance: props.form.address_entrance || 0,
			address_street: props.form.address_street || null,
			comment: props.form.comment || "",
			custom_shipment_comment: props.form.custom_shipment_comment || "",
		};
		api.shop.order.saveLastPersonalData(JSON.stringify(data));
	};

	const checkCriticalErrorsByForm = (form: CreateOrderPayload) => {
		console.log("checking form error", { user, form });

		if (user?.email && !user.is_confirmed_email) {
			return localisation.global.notConfirmedEmail.replace("{email}", user.email ?? "");
		}

		if (!form.shipment_method_id) {
			return localisation.orders.notSelectedDelivery;
		}

		return null;
	};

	// checkIsAllProductsAvailable
	useEffect(() => {
		if (
			!props.notAvailableProducts.some(notAvailableProduct =>
				cartService.cart?.cart_products?.some(
					(y: any) => notAvailableProduct.product_id === y.id
				)
			)
		)
			return setIsAllProductsAvailable(true);
		if (props.notAvailableProducts.length === 0) return setIsAllProductsAvailable(true);
		setIsAllProductsAvailable(false);
	}, [props.notAvailableProducts, cartService.cart?.cart_products]);

	return (
		<>
			{!isAllProductsAvailable && (
				<div className="small fw-bold text-danger">
					{localisation.orders.notAvailableMsg}
				</div>
			)}
			{cartService.incustService.processedCheck &&
			!!cartService.incustService.processedCheck.bonuses_redeemed_amount &&
			cartService.incustService.processedCheck.bonuses_redeemed_amount > 0 ? (
				<MUIPopConfirm
					node={
						<ConfirmButton
							submit={submit}
							isOrderSending={isOrderSending}
							isAllProductsAvailable={isAllProductsAvailable}
							form={props.form}
							paymentsService={props.paymentsService}
							shipmentPricesService={props.shipmentPricesService}
							createdOrder={props.createdOrder}
						/>
					}
					message={
						<Interweave
							content={localisation.profile.loyaltyConfirm.replace(
								"{bonuses_amount}",
								cartService.incustService.processedCheck.bonuses_redeemed_amount.toString()
							)}
						/>
					}
					cancelText={localisation.global.cancelButton}
					confirmText={localisation.global.confirmButton}
					onConfirm={submit}
				/>
			) : (
				<ConfirmButton
					submit={submit}
					isOrderSending={isOrderSending}
					isAllProductsAvailable={isAllProductsAvailable}
					form={props.form}
					paymentsService={props.paymentsService}
					shipmentPricesService={props.shipmentPricesService}
					createdOrder={props.createdOrder}
				/>
			)}
		</>
	);
}

interface IConfirmButtonProps {
	submit: () => Promise<void>;
	isOrderSending: boolean;
	isAllProductsAvailable: boolean;
	form: CreateOrderPayload;
	paymentsService: IUsePaymentsService;
	shipmentPricesService: IShipmentPricesService;
	createdOrder: Order | null;
}

function ConfirmButton(props: IConfirmButtonProps) {
	const { cartService } = useSelectedStoreContext();
	const {
		authService: { user },
		localisation,
		brandInfo,
	} = useAppContext();

	const authSettings = useAuthSettings(brandInfo?.group_id);

	const isConfirmDisabled = useMemo(() => {
		if (brandInfo?.consent_mode === "info") {
			return (
				!props.isAllProductsAvailable ||
				props.isOrderSending ||
				(!user &&
					(!!cartService.computedNeedAuthProductsInCart.length ||
						authSettings.settings?.is_auth_for_orders_enabled)) ||
				!!props.shipmentPricesService.error
			);
		} else if (brandInfo?.consent_mode === "one_time" && user?.marketing_consent) {
			if (user && user.is_accepted_agreement) {
				return (
					!props.isAllProductsAvailable ||
					props.isOrderSending ||
					(!user &&
						(!!cartService.computedNeedAuthProductsInCart.length ||
							authSettings.settings?.is_auth_for_orders_enabled)) ||
					!!props.shipmentPricesService.error
				);
			}
		}

		return (
			!props.isAllProductsAvailable ||
			props.isOrderSending ||
			!props.form.is_accepted_agreement ||
			(!user &&
				(!!cartService.computedNeedAuthProductsInCart.length ||
					authSettings.settings?.is_auth_for_orders_enabled)) ||
			!!props.shipmentPricesService.error
		);
	}, [
		authSettings.settings?.is_auth_for_orders_enabled,
		brandInfo?.consent_mode,
		cartService.computedNeedAuthProductsInCart.length,
		props.form.is_accepted_agreement,
		props.isAllProductsAvailable,
		props.isOrderSending,
		props.shipmentPricesService.error,
		user,
	]);

	return (
		<LoadingButton
			onClick={async () => {
				if (
					!cartService.incustService.processedCheck ||
					!cartService.incustService.processedCheck.bonuses_redeemed_amount
				) {
					await props.submit();
				}
			}}
			variant={"contained"}
			loading={props.isOrderSending}
			loadingPosition={"start"}
			startIcon={<CheckIcon />}
			disabled={isConfirmDisabled}
		>
			{props.paymentsService.paymentsInfo?.single_payment_method?.is_online ||
			props.paymentsService.forceSingleMethod?.is_online
				? localisation.orders.payButton
				: localisation.global.continue}
		</LoadingButton>
	);
}
