import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { IncustPayCardInfo } from "../../api/shop/payments/types";
import { CustomerSpecialAccount } from "../../shop/loyalty/types";
import api from "../../api";
import f from "../../helpers/formatText";
import useAppContext from "../../useAppContext";
import useCurrencyInfo from "../../hooks/useCurrencyInfo";
import { buildChargeString } from "./helper";
import formatNumber from "../../helpers/formatNumber";
import { IUseIncustPayData } from "../../shop/payments/types";
import { useSelectedStoreOptional } from "../../shop/SelectedStore/context";

export default function useTopupOptions(
	incustPayDataTopup: IUseIncustPayData,
	incustPayData: IUseIncustPayData,
	currency: string,
	showCorporate: boolean = false,
	amount: number = 0,
	productId?: number
): IUseTopupOptions {
	const {
		localisation: { payment, global, topup },
		lang,
		authService: { user },
		brandInfo,
	} = useAppContext();
	const selectedStore = useSelectedStoreOptional();
	const { symbol, position } = useCurrencyInfo(brandInfo?.default_lang || lang, currency || "");

	const [selectedOption, setSelectedOption] = useState<ITopupOption | null>(null);
	const [showCardInfoModal, setShowCardInfoModal] = useState<boolean>(false);
	const [cardNumber, setCardNumber] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const [cardInfo, setCardInfo] = useState<IncustPayCardInfo | null>(null);
	const [selectedCardOption, setSelectedCardOption] = useState<ITopupOption | null>(null);

	const buildInitialOptions = useCallback(() => {
		const options: ITopupOption[] = [];

		let cnt = 0;
		incustPayDataTopup.incustPayData?.incust_pay_list.forEach(item => {
			cnt += 1;
			if (item.topup_card_enabled) {
				options.push({
					value: `card_${cnt}`, //item.incust_pay_configuration?.payment_settings_id?.toString(),
					label: f(topup.incustPayItem, {
						incust_pay_title: "", //item.incust_pay_configuration.name,
					}),
					configId: cnt,
					chargePercent: 0, //item.incust_pay_configuration.charge_percent,
					chargeFixed: 0, //item.incust_pay_configuration.charge_fixed,
				});
			}
			if (!!item.specials.length && !!user) {
				item.specials.forEach(special => {
					if (
						!special.special_account?.corporate ||
						!special.corporate ||
						showCorporate
					) {
						let accountAmount = 0;
						if (!special.amount) {
							incustPayDataTopup.incustPayData?.incust_pay_list.forEach(iitem => {
								iitem.specials.forEach(ispecial => {
									if (
										ispecial.special_account &&
										ispecial.special_account.id === special.id
									) {
										accountAmount = ispecial.amount || 0;
									}
								});
							});
						}

						const balance = f(topup.balanceText, {
							balance: `${position === "start" ? `${symbol} ${formatNumber(special.amount || accountAmount || 0, brandInfo?.default_lang || lang)}` : `${formatNumber(special.amount || accountAmount || 0, lang)} ${symbol}`}`,
						});

						options.push({
							value: special.id,
							label: special.title || "",
							balance: balance,
							specialData: special,
							configId: item.incust_pay_configuration?.payment_settings_id,
							chargePercent: 0, //item.incust_pay_configuration.charge_percent,
							chargeFixed: 0, //item.incust_pay_configuration.charge_fixed,
						});
					}
				});
			}
		});

		return options;
	}, [
		incustPayDataTopup.incustPayData?.incust_pay_list,
		user,
		topup.incustPayItem,
		topup.balanceText,
		showCorporate,
		position,
		symbol,
		brandInfo?.default_lang,
		lang,
	]);

	const [options, setOptions] = useState<ITopupOption[]>(buildInitialOptions());

	const computedCardOptions = useMemo(() => {
		const options: ITopupOption[] = [];
		if (!!cardInfo) {
			cardInfo.incust_pay_data?.specials.forEach(item => {
				let accountAmount = 0;
				if (!item.amount) {
					incustPayDataTopup.incustPayData?.incust_pay_list.forEach(iitem => {
						iitem.specials.forEach(special => {
							if (special.special_account.id === item.id) {
								accountAmount = special.amount || 0;
							}
						});
					});
				}

				const balance = f(topup.balanceText, {
					balance: `${position === "start" ? `${symbol} ${formatNumber(item.amount || accountAmount || 0, brandInfo?.default_lang || lang)}` : `${formatNumber(item.amount || accountAmount || 0, lang)} ${symbol}`}`,
				});
				options.push({
					value: item.id,
					label: item.title || "",
					balance: balance,
					specialData: item,
					configId: selectedOption?.configId,
					chargePercent: selectedOption?.chargePercent || 0,
					chargeFixed: selectedOption?.chargeFixed || 0,
				});
			});
		}

		return options;
	}, [
		brandInfo?.default_lang,
		cardInfo,
		incustPayDataTopup.incustPayData?.incust_pay_list,
		lang,
		position,
		selectedOption?.chargeFixed,
		selectedOption?.chargePercent,
		selectedOption?.configId,
		symbol,
		topup.balanceText,
	]);

	const handleOptionChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const val = (event.target as HTMLInputElement).value;
			const option = options.find(option => option.value === val);
			setSelectedOption(option || null);
		},
		[options]
	);

	const handleCardOptionChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const val = (event.target as HTMLInputElement).value;
			const option = computedCardOptions.find(option => option.value === val);
			setSelectedCardOption(option || null);
			if (option) {
				setOptions(prevState => {
					const res: ITopupOption[] = prevState.map((o: ITopupOption) => {
						if (o.balance === undefined && o.configId === option.configId) {
							return {
								...o,
								specialData: option.specialData,
								cardNumber: cardNumber || "",
							};
						}
						return o;
					});
					return res;
				});
				setSelectedOption(prevState => {
					if (
						prevState &&
						prevState.balance === undefined &&
						prevState?.configId === option.configId
					) {
						return {
							...prevState,
							specialData: option.specialData,
							cardNumber: cardNumber || "",
						};
					}
					return prevState;
				});
			}
		},
		[cardNumber, computedCardOptions]
	);

	const getCardInfo = useCallback(async () => {
		try {
			if (!selectedOption || !cardNumber) return;

			// setIsLoading(true);
			const cardInfo = await api.shop.payments
				.getIncustPayCardInfo(cardNumber, selectedStore?.id, undefined, productId)
				.then(response => response.data);
			if (cardInfo.is_error) {
				setError(cardInfo.incust_pay_data_error.error_detail);
			} else if (cardInfo.incust_pay_data.total_accounts_count === 0) {
				setError(
					f(payment.incustPayCarEmptyCardText, {
						card_number: cardInfo.card_number,
					})
				);
			} else {
				setCardInfo(cardInfo);
			}
		} catch (err: any) {
			setError(err?.response?.data?.detail || global.errUnknown);
		} finally {
			setIsLoading(false);
		}
	}, [
		selectedOption,
		cardNumber,
		selectedStore?.id,
		productId,
		payment.incustPayCarEmptyCardText,
		global.errUnknown,
	]);

	const getBalanceString = (amount: number, accountId: string) => {
		let accountAmount = 0;
		if (!amount) {
			incustPayDataTopup.incustPayData?.incust_pay_list.forEach(item => {
				item.specials.forEach(special => {
					if (special.special_account.id === accountId) {
						accountAmount = special.amount || 0;
					}
				});
			});
		}
		return f(topup.balanceText, {
			balance: `${position === "start" ? `${symbol} ${formatNumber(amount || accountAmount, brandInfo?.default_lang || lang)}` : `${formatNumber(amount || accountAmount, brandInfo?.default_lang || lang)} ${symbol}`}`,
		});
	};

	const computedChargeInfoString = useMemo(() => {
		let text = "";
		if (!!selectedOption) {
			text = buildChargeString(
				`${topup.charge}:`,
				selectedOption.chargePercent,
				selectedOption.chargeFixed,
				brandInfo?.default_lang || lang || "",
				position,
				symbol
			);
		}

		return text;
	}, [brandInfo?.default_lang, lang, position, selectedOption, symbol, topup.charge]);

	const computedCharge = useMemo(() => {
		if (!!selectedOption) {
			const percent = (amount * selectedOption.chargePercent) / 100;
			return selectedOption.chargeFixed + percent;
		}
		return 0;
	}, [amount, selectedOption]);

	const computedAmountWithCharge = useMemo(() => {
		return amount + computedCharge;
	}, [amount, computedCharge]);

	useEffect(() => {
		if (incustPayDataTopup?.incustPayData) {
			setOptions(buildInitialOptions());
		}
	}, [buildInitialOptions, incustPayDataTopup?.incustPayData]);

	return {
		selectedOption,
		handleOptionChange,
		options: options,
		setSelectedOption,
		computedCardOptions,
		showCardInfoModal,
		setShowCardInfoModal,
		cardNumber,
		setCardNumber,
		cardInfo,
		getCardInfo,
		error,
		isLoading,
		selectedCardOption,
		handleCardOptionChange,
		setError,
		getBalanceString,
		computedChargeInfoString,
		computedAmountWithCharge,
		amount,
		computedCharge,
	};
}

export interface IUseTopupOptions {
	selectedOption: ITopupOption | null;
	handleOptionChange: (event: ChangeEvent<HTMLInputElement>) => void;
	options: ITopupOption[];
	setSelectedOption: (option: ITopupOption | null) => void;
	computedCardOptions: ITopupOption[];
	showCardInfoModal: boolean;
	setShowCardInfoModal: (show: boolean) => void;
	cardNumber: string | null;
	setCardNumber: (cardNumber: string | null) => void;
	cardInfo: IncustPayCardInfo | null;
	getCardInfo: () => void;
	error: string | null;
	isLoading: boolean;
	selectedCardOption: ITopupOption | null;
	handleCardOptionChange: (event: ChangeEvent<HTMLInputElement>) => void;
	setError: (error: string | null) => void;
	getBalanceString: (amount: number, accountId: string) => string;
	computedChargeInfoString: string;
	computedAmountWithCharge: number;
	amount: number;
	computedCharge: number;
}

export interface ITopupOption {
	value: string;
	label: string;
	balance?: string | null;
	specialData?: CustomerSpecialAccount;
	configId?: number;
	cardNumber?: string;
	chargePercent: number;
	chargeFixed: number;
}
