import { storeBasePath } from "../../base";
import { AxiosInstance } from "axios";
import {
	AvailablePayments,
	Category,
	Characteristic,
	CharacteristicValues,
	CitiesResponse,
	CreateFiltersData,
	CustomPayment,
	DetectedBrand,
	ExtraFeeSchema,
	FiltersSetCreated,
	FindProductModificationData,
	GetCalculatedExtraFeeArgs,
	GetCategoriesArgs,
	GetCategoriesTreeArgs,
	GetCharacteristicValuesArgs,
	GetCitiesArgs,
	GetDocumentArgs,
	GetMinMaxProductsPricesArgs,
	GetPaymentsArgs,
	GetProductArgs,
	GetStoreArgs,
	GetStoresArgs,
	IStorageAddressObject,
	MinMaxProductsPrices,
	Product,
	ProductsList,
	ProductsListParams,
	Receipt,
	ScanPayload,
	ScanResponse,
	ScanResponseUnAuth,
	ShipmentPrice,
	ShipmentPriceInputData,
	ShipmentPriceResultData,
	ShipmentsData,
	Store,
	TextResponse,
} from "./types";
import { PaymentPayload } from "../payments/types";
import { PaymentStatusResponse } from "../order/types";

export default function BasicShopModule(instance: AxiosInstance) {
	const getPath = (path: string) => `${storeBasePath}/${path}`;

	const getHeaders = (token: string | null | undefined) =>
		token ? { Authorization: `Bearer ${token}` } : undefined;

	return {
		getCities(args: GetCitiesArgs) {
			return instance.get<CitiesResponse>(getPath("cities/"), { params: args });
		},

		getStores(args: GetStoresArgs) {
			return instance.get<Store[]>(getPath("stores/"), { params: args });
		},
		getStore(id: number, args: GetStoreArgs) {
			return instance.get<Store>(getPath(`stores/${id}`), { params: args });
		},

		getCategories(args: GetCategoriesArgs) {
			return instance.get<Category[]>(getPath("categories/"), { params: args });
		},

		getCategoriesTree(args: GetCategoriesTreeArgs) {
			return instance.get<Category[]>(getPath("categories/tree"), { params: args });
		},

		getProducts(
			storeId: number,
			params?: ProductsListParams,
			signal?: AbortSignal | undefined
		) {
			return instance.get<ProductsList>(getPath(`products/${storeId}/`), {
				params,
				signal,
			});
		},
		getMinMaxProductsPrices(storeId: number, args: GetMinMaxProductsPricesArgs) {
			return instance.get<MinMaxProductsPrices>(getPath(`products/${storeId}/minMaxPrices`), {
				params: args,
			});
		},
		getProduct(
			storeId: number,
			productId: number,
			params: GetProductArgs,
			signal?: AbortSignal
		) {
			return instance.get<Product>(getPath(`products/${storeId}/${productId}`), {
				params,
				signal,
			});
		},
		findProductModification(
			storeId: number,
			productId: number,
			data: FindProductModificationData
		) {
			return instance.post<Product>(
				getPath(`products/${storeId}/${productId}/findModification`),
				data
			);
		},
		detectBrand(brandId?: number | null | undefined) {
			return instance.get<DetectedBrand>(getPath(`brands/detect`), {
				params: { brand_id: brandId },
			});
		},

		getShipments(store_id?: number | null | undefined) {
			return instance.get<ShipmentsData>(getPath("shipments/"), { params: { store_id } });
		},

		getPaymentsInfo(args: GetPaymentsArgs) {
			return instance.get<AvailablePayments>(getPath("payments/"), { params: args });
		},

		getPaymentCheckoutData(payload: PaymentPayload) {
			return instance.post<AvailablePayments>(
				getPath("payments/make_payment_by_method"),
				payload
			);
		},

		getCalculatedExtraFee(args: GetCalculatedExtraFeeArgs) {
			return instance.get<ExtraFeeSchema[]>(getPath("payments/extra_fee"), {
				params: args,
			});
		},

		// getPaymentMethodFee(args: GetPaymentMethodArgs) {
		// 	return instance.get<PaymentFee>(`/payments/${args.payment_method}/fee`, {
		// 		params: args,
		// 	});
		// },

		checkPaymentStatus(
			token?: string | null,
			orderId?: number | null,
			invoiceId?: number | null
		) {
			return instance.get<PaymentStatusResponse>(getPath(`payments/payment_status`), {
				headers: getHeaders(token),
				params: { invoice_id: invoiceId, order_id: orderId },
			});
		},

		getDocument(brandId: number, args: GetDocumentArgs) {
			return instance.get<TextResponse>(getPath(`brands/${brandId}/document`), {
				params: args,
			});
		},

		getCharacteristicValues(
			storeId: number,
			characteristicId: number,
			args?: GetCharacteristicValuesArgs
		) {
			return instance.get<CharacteristicValues>(
				getPath(`filters/${storeId}/${characteristicId}/values`),
				{ params: args }
			);
		},
		createFilters(data: CreateFiltersData) {
			return instance.post<FiltersSetCreated>(getPath(`filters/create`), data);
		},
		getCustomPayments() {
			return instance.get<CustomPayment[]>(getPath("payments/custom_payments"));
		},
		getFilters(storeId: number, brandId: number) {
			const args = { store_id: storeId, brand_id: brandId };
			return instance.get<Characteristic[]>(getPath(`filters/`), { params: args });
		},
		getShipmentPrices(args: ShipmentPriceInputData) {
			if (args.shipment_ids) {
				args.shipment_ids = JSON.stringify(args.shipment_ids);
			}
			return instance.get<ShipmentPriceResultData[]>(getPath(`shipments/prices`), {
				params: args,
			});
		},
		getShipmentPrice(args: ShipmentPriceInputData) {
			if (args.shipment_id) {
				args.shipment_id = JSON.stringify(args.shipment_id);
			}
			return instance.get<ShipmentPrice>(getPath(`shipments/price`), { params: args });
		},
		makeScanAndSave(payload: ScanPayload) {
			return instance.post<ScanResponse>(getPath(`scan/scan_and_save`), payload);
		},
		makeScan(payload: ScanPayload) {
			return instance.post<ScanResponseUnAuth>(getPath(`scan/`), payload);
		},
		getReceipt(receiptId: number, storeId?: number | null) {
			let params = {};
			if (storeId) {
				params = { store_id: storeId };
			}
			return instance.get<Receipt>(getPath(`scan/${receiptId}`), { params: params });
		},

		saveLastStoreId(id: number) {
			localStorage.setItem("lastStoreId", id.toString());
		},
		getLastStoreId(): number | null {
			return parseInt(localStorage.getItem("lastStoreId") as string) ?? null;
		},
		removeLastStoreId() {
			localStorage.removeItem("lastStoreId");
		},

		saveLastAddressObject(value: string) {
			localStorage.setItem("lastAddressObject", value);
		},

		getLastAddressObject(): IStorageAddressObject | null {
			const item = localStorage.getItem("lastAddressObject");
			if (item) {
				const obj: IStorageAddressObject = JSON.parse(item);
				if (obj.type) return obj;
			}
			return null;
		},
	};
}
