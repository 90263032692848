import { useMemo } from "react";
import { useSelectedStore } from "../../../SelectedStore/context";

export default function useProductViewSize() {
	const selectedStore = useSelectedStore();

	const isShopInfo = useMemo(() => {
		return (
			!!selectedStore.custom_fields.length &&
			!selectedStore.custom_fields.every(i => !i.value)
		);
	}, [selectedStore.custom_fields]);

	const computedGridViewSize = useMemo(() => {
		if (isShopInfo && selectedStore.has_categories) return 6;
		else if (!isShopInfo && !selectedStore.has_categories) return 3;
		else if (!isShopInfo || !selectedStore.has_categories) return 4;
		else return 6;
	}, [selectedStore.has_categories, isShopInfo]);

	const computedProductsContainerSize = useMemo(() => {
		if (isShopInfo && selectedStore.has_categories) return 6;
		else if (!isShopInfo && !selectedStore.has_categories) return 12;
		else if (!isShopInfo || !selectedStore.has_categories) return 9;
		else return 6;
	}, [isShopInfo, selectedStore.has_categories]);

	return {
		computedGridViewSize,
		computedProductsContainerSize,
		isShopInfo,
	};
}
