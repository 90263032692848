import { ProductsSort } from "../../../api/shop/basic/types";
import { z } from "zod";

export interface ProductsParamsState {
	sort: ProductsSort;
	search_text?: string | null | undefined;
}

export const SelectedProductParams = z.object({
	product_id: z.coerce.number().min(1).optional().catch(undefined),
	tab: z.enum(["description", "characteristics", "attributes"]).optional().catch(undefined),
});
// z.infer does not work. Probably TypeScript version issue
export interface SelectedProductParamsType {
	product_id?: number | undefined;
	tab?: "description" | "characteristics" | "attributes" | undefined;
}
