import { useCallback, useMemo } from "react";
import formatCurrency from "../../../../helpers/formatCurrency";
import { useSelectedStoreContext } from "../../../SelectedStore/context";
import useAppContext from "../../../../useAppContext";
import { Product } from "../../../../api/shop/basic/types";
import { CreateCartAttributeData } from "../../../../api/shop/cart/types";
import { IUseFloatingSumType } from "../../../menu/product/hooks/useFloatingSum";
import formatNumber from "../../../../helpers/formatNumber";

export default function useProductPrice(
	product: Product | null,
	selectedAttributes?: CreateCartAttributeData[] | null,
	qty: number = 0,
	cartProductQty: number = 0,
	isCard: boolean = false,
	floatingSum?: IUseFloatingSumType
): IUseProductPrice {
	const { selectedStore, cartService } = useSelectedStoreContext();
	const {
		lang,
		localisation: { menu },
		brandInfo,
	} = useAppContext();

	const getProductPriceWithAttributes = useCallback(
		(price: number | null, withQty: boolean = false) => {
			const isGift = product?.type === "gift";
			let resPrice = 0;
			let currentQty =
				cartProductQty && !cartService.addingProductWithNewAttributesId
					? cartProductQty
					: qty;

			if (cartService.productToUpdate && cartService.productToUpdate.quantity) {
				currentQty = cartService.productToUpdate.quantity;
			}

			if (product && (price || price === 0) && selectedAttributes) {
				if (selectedAttributes.length === 0) {
					resPrice = price;
					if (currentQty && withQty) resPrice = price * currentQty;
					return isGift
						? formatNumber(resPrice, brandInfo?.default_lang || lang)
						: formatCurrency(
								resPrice,
								brandInfo?.default_lang || lang,
								selectedStore.currency
							);
				}

				let needAddPrice = 0;
				selectedAttributes.forEach(attr => {
					const selectedAttr =
						product.attribute_groups
							?.find(ag => ag.attributes.some(a => a.id === attr.attribute_id))
							?.attributes?.find(aa => aa.id === attr.attribute_id) ?? null;
					if (selectedAttr) needAddPrice += selectedAttr.price_impact * attr.quantity;
				});

				resPrice = price + needAddPrice;
				if (currentQty && withQty) resPrice = resPrice * currentQty;
				return isGift
					? formatNumber(resPrice, brandInfo?.default_lang || lang)
					: formatCurrency(
							resPrice,
							brandInfo?.default_lang || lang,
							selectedStore.currency
						);
			}

			if (price) {
				resPrice = price;
				if (currentQty && withQty) resPrice = price * currentQty;
				return isGift
					? formatNumber(resPrice, brandInfo?.default_lang || lang)
					: formatCurrency(
							resPrice,
							brandInfo?.default_lang || lang,
							selectedStore.currency
						);
			}

			return isGift
				? formatNumber(0, brandInfo?.default_lang || lang)
				: formatCurrency(0, brandInfo?.default_lang || lang, selectedStore.currency);
		},
		[
			brandInfo?.default_lang,
			cartProductQty,
			cartService.addingProductWithNewAttributesId,
			cartService.productToUpdate,
			lang,
			product,
			qty,
			selectedAttributes,
			selectedStore.currency,
		]
	);

	const computedFloatingSumText = useMemo(() => {
		let text;
		if (product?.floating_sum_settings?.options?.length) {
			text = menu.floatingSumPick;
		} else {
			text = menu.floatingSumEnterSum;
		}
		if (product?.type !== "gift") {
			return `${text} (${selectedStore.currency})`;
		}
		return text;
	}, [
		menu.floatingSumEnterSum,
		menu.floatingSumPick,
		product?.floating_sum_settings?.options?.length,
		product?.type,
		selectedStore.currency,
	]);

	const computedProductPrice = useMemo(() => {
		if (isCard && product?.floating_sum_settings?.is_enabled) {
			return computedFloatingSumText;
		}
		let price = product?.price;
		if (floatingSum?.floatingSumAmount) {
			price = floatingSum.floatingSumAmount;
		}
		return getProductPriceWithAttributes(price || null);
	}, [
		floatingSum?.floatingSumAmount,
		getProductPriceWithAttributes,
		isCard,
		product?.floating_sum_settings?.is_enabled,
		product?.price,
		computedFloatingSumText,
	]);

	const computedProductOldPrice = useMemo(() => {
		return getProductPriceWithAttributes(product?.old_price || null);
	}, [getProductPriceWithAttributes, product?.old_price]);

	const computedProductPriceWithQty = useMemo(() => {
		let price = product?.price;
		if (floatingSum?.floatingSumAmount || product?.floating_sum_settings?.is_enabled) {
			price = floatingSum?.floatingSumAmount || 0;
		}
		return getProductPriceWithAttributes(price || 0, true);
	}, [
		floatingSum?.floatingSumAmount,
		getProductPriceWithAttributes,
		product?.floating_sum_settings?.is_enabled,
		product?.price,
	]);

	const computedProductOldPriceWithQty = useMemo(() => {
		return getProductPriceWithAttributes(product?.old_price || null, true);
	}, [getProductPriceWithAttributes, product?.old_price]);

	return {
		computedProductPrice,
		computedProductOldPrice,
		computedProductPriceWithQty,
		computedProductOldPriceWithQty,
	};
}

interface IUseProductPrice {
	computedProductPrice: string;
	computedProductOldPrice: string;
	computedProductPriceWithQty: string;
	computedProductOldPriceWithQty: string;
}
