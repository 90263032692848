import { useCallback, useMemo } from "react";

import { useSelectedStoreContext } from "../../../SelectedStore/context";
import { CartProduct } from "../../../../api/shop/cart/types";
import { Product } from "../../../../api/shop/basic/types";
import f from "../../../../helpers/formatText";
import useAppContext from "../../../../useAppContext";
import useSelectProduct from "../../useNewProductsService/useSelectProduct";
import { canAddProductWithAnotherAttributes } from "../../useNewProductsService/helpers";
import useSelectedProductParams from "../../useNewProductsService/useSelectedProductParams";

export default function useProductQuantity(
	cartProduct: CartProduct | null = null,
	isSingleCartProduct: boolean = true,
	productQty: number = 0,
	product: Product | null = null,
	setProductQty?: (qty: number) => any,
	isCard: boolean = false,
	selectedProduct?: Product | null
): IUserProductQuantity {
	const { cartService } = useSelectedStoreContext();
	const { product_id: selectedProductId } = useSelectedProductParams();
	const { toastService, localisation } = useAppContext();

	const computedQuantity = useMemo(() => {
		if (cartProduct || product) {
			if (cartService.productToUpdate && cartService.productToUpdate.quantity) {
				if (cartProduct && cartService.productToUpdate.cartProductId === cartProduct.id) {
					return cartService.productToUpdate.quantity;
				}
			}

			if (isSingleCartProduct) return cartProduct?.quantity || product?.buy_min_quantity || 1;

			let filtered: CartProduct[] | undefined = [];
			if (cartProduct) {
				filtered = cartService.cart?.cart_products?.filter(
					cp => cp.product.id === cartProduct.product.id
				);
			} else {
				if (product) {
					filtered = cartService.cart?.cart_products?.filter(
						cp => cp.product.id === product.id
					);
				}
			}

			if (filtered) {
				return filtered.reduce((acc, cp) => acc + cp.quantity, 0);
			}
		}
		return productQty || product?.buy_min_quantity || 1;
	}, [
		cartProduct,
		cartService.cart?.cart_products,
		cartService.productToUpdate,
		isSingleCartProduct,
		product,
		productQty,
	]);

	const selectProduct = useSelectProduct();
	const updateProductInCart = cartService.updateProductInCart;
	const updateProductForUpdate = cartService.updateProductForUpdate;
	const increaseQty = useCallback(() => {
		if (
			product &&
			canAddProductWithAnotherAttributes(product) &&
			cartProduct &&
			product.id !== selectedProductId &&
			!isSingleCartProduct
		) {
			selectProduct(product);
			return;
		}
		if (cartProduct && product) {
			if (isCard) {
				return updateProductInCart(cartProduct.id, cartProduct.quantity + 1);
			}
			const qty = cartService.productToUpdate
				? cartService.productToUpdate.quantity + 1
				: cartProduct.quantity + 1;
			updateProductForUpdate(cartProduct.id, qty);
		}
		productQty && setProductQty && setProductQty(productQty + 1);
	}, [
		cartProduct,
		cartService.productToUpdate,
		isCard,
		isSingleCartProduct,
		product,
		productQty,
		selectProduct,
		selectedProductId,
		setProductQty,
		updateProductForUpdate,
		updateProductInCart,
	]);

	const clearCartProductLocalData = cartService.clearCartProductLocalData;
	const deleteProductFromCart = cartService.deleteProductFromCart;
	const decreaseQty = useCallback(() => {
		if (
			product &&
			canAddProductWithAnotherAttributes(product) &&
			cartProduct &&
			product.id !== selectedProductId &&
			computedQuantity > 1 &&
			!isSingleCartProduct
		) {
			selectProduct(product);
			return;
		}

		if (cartProduct && product) {
			const newQty = cartService.productToUpdate
				? cartService.productToUpdate.quantity - 1
				: cartProduct.quantity - 1;
			if (newQty <= 0) {
				selectProduct(null);
				clearCartProductLocalData();
				return deleteProductFromCart(cartProduct.id);
			}
			setProductQty && setProductQty(newQty);
			if (isCard) return updateProductInCart(cartProduct.id, newQty);

			updateProductForUpdate(cartProduct.id, newQty);
		} else {
			if (productQty && setProductQty) {
				if (productQty - (selectedProduct?.buy_min_quantity || 1) > 0) {
					productQty &&
						setProductQty &&
						setProductQty(productQty - (selectedProduct?.buy_min_quantity || 1));
					return;
				}
				selectProduct(null);
				clearCartProductLocalData();
			}
		}
	}, [
		cartProduct,
		cartService.productToUpdate,
		clearCartProductLocalData,
		computedQuantity,
		deleteProductFromCart,
		isCard,
		isSingleCartProduct,
		product,
		productQty,
		selectProduct,
		selectedProduct?.buy_min_quantity,
		selectedProductId,
		setProductQty,
		updateProductForUpdate,
		updateProductInCart,
	]);

	const showToast = toastService.showToast;
	const updateQty = useCallback(
		async (qty: number) => {
			if (cartProduct) {
				if (cartProduct.quantity === qty) {
					return;
				}
				if (isCard) {
					await updateProductInCart(cartProduct.id, qty);
					return showToast({
						severity: "success",
						message: f(localisation.menu.qtyChangedText, {
							qty: qty,
							product_name: product?.name,
						}),
					});
				}
				updateProductForUpdate(cartProduct.id, qty);
			}
			setProductQty && setProductQty(qty);
		},
		[
			cartProduct,
			isCard,
			localisation.menu.qtyChangedText,
			product?.name,
			setProductQty,
			showToast,
			updateProductForUpdate,
			updateProductInCart,
		]
	);

	return { computedQuantity, increaseQty, decreaseQty, updateQty };
}

interface IUserProductQuantity {
	computedQuantity: number;
	increaseQty: () => void;
	decreaseQty: () => void;
	updateQty: (qty: number) => void;
}
