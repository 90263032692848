export default function getLocale(
	language?: string | null | undefined,
	region?: string | null | undefined
) {
	if (!language) {
		language = "en";
	}
	try {
		// Спочатку пробуємо створити повну локаль (мова + регіон)
		const fullLocale = `${language.toLowerCase()}-${region?.toUpperCase()}`;
		new Intl.Locale(fullLocale); // Якщо локаль існує, повертаємо її
		return fullLocale;
	} catch (error) {
		// Якщо повна локаль недоступна, повертаємо лише мову
		return language;
	}
}
