import { useMemo } from "react";

import { useSelectedStoreContext } from "../../../SelectedStore/context";
import { CartProduct } from "../../../../api/shop/cart/types";

export default function useCartProduct(
	selectedProductId: number | null | undefined
): IUseCartProduct {
	const { cartService } = useSelectedStoreContext();

	const getCartProductByCartProductId = cartService.getCartProductByCartProductId;
	const getCartProduct = cartService.getCartProduct;
	const cartProduct = useMemo(() => {
		if (!selectedProductId) return null;
		if (cartService.selectedVariationCartProductId) {
			return getCartProductByCartProductId(cartService.selectedVariationCartProductId);
		}
		return getCartProduct(selectedProductId);
	}, [
		cartService.selectedVariationCartProductId,
		getCartProduct,
		getCartProductByCartProductId,
		selectedProductId,
	]);

	const isProductInCart = useMemo(() => {
		return !!cartProduct && cartService.addingProductWithNewAttributesId !== selectedProductId;
	}, [cartProduct, cartService.addingProductWithNewAttributesId, selectedProductId]);

	return {
		cartProduct,
		isProductInCart,
	};
}

interface IUseCartProduct {
	cartProduct: CartProduct | null;
	isProductInCart: boolean;
}
