import { useEffect, useMemo, useRef } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";

import { useSelectedStoreContext } from "../../SelectedStore/context";
import { Product } from "../../../api/shop/basic/types";
import { CartProduct } from "../../../api/shop/cart/types";
import ChipButton from "../../../features/ChipButton";
import useAppContext from "../../../useAppContext";
import { TgLink } from "../../../helpers/ThemeComponents";
import DeleteProductsPopConfirmWrapper from "./DeleteProductsPopConfirm";
import { canAddProductWithAnotherAttributes } from "../../services/useNewProductsService/helpers";

interface IProductVariationsProps {
	product: Product;
}

export default function ProductVariations(props: IProductVariationsProps) {
	const {
		localisation: { menu },
	} = useAppContext();
	const { cartService } = useSelectedStoreContext();
	const variationsMainRef = useRef<HTMLDivElement>(null);
	const addNewRef = useRef<HTMLDivElement>(null);

	const computedFilteredProducts = useMemo(() => {
		return cartService.cart?.cart_products?.filter(
			cp => cp.product.product_id === props.product.product_id
		);
	}, [cartService.cart?.cart_products, props.product.product_id]);

	const computedHasMultipleOptions = useMemo(() => {
		return !!(computedFilteredProducts && computedFilteredProducts.length > 1);
	}, [computedFilteredProducts]);

	const getDefaultSelectedCartProductVariation =
		cartService.getDefaultSelectedCartProductVariation;
	const computedDefaultSelected = useMemo(() => {
		if (cartService.productVariationsState?.needToChooseMode) return null;
		if (cartService.addingProductWithNewAttributesId) return null;
		return getDefaultSelectedCartProductVariation(props.product.product_id);
	}, [
		cartService.addingProductWithNewAttributesId,
		cartService.productVariationsState?.needToChooseMode,
		getDefaultSelectedCartProductVariation,
		props.product.product_id,
	]);

	const setSelectedVariationCartProductId = cartService.setSelectedVariationCartProductId;
	useEffect(() => {
		if (
			computedFilteredProducts &&
			computedFilteredProducts.length > 1 &&
			!cartService.selectedVariationCartProductId
		) {
			setSelectedVariationCartProductId(computedDefaultSelected);
		}
	}, [
		cartService.selectedVariationCartProductId,
		computedDefaultSelected,
		computedFilteredProducts,
		setSelectedVariationCartProductId,
	]);

	useEffect(() => {
		if (!cartService.productVariationsState?.fromCard) {
			const el = document.getElementById("modal-or-swipeable-overflow-wrapper");
			const top =
				variationsMainRef && variationsMainRef.current
					? variationsMainRef.current.offsetTop - 74
					: null;
			if (el && top) {
				el?.scrollTo({
					behavior: "smooth",
					top: top,
				});
			}
		}
	}, [
		cartService.productVariationsState?.fromCard,
		cartService.addingProductWithNewAttributesId,
		cartService.selectedVariationCartProductId,
	]);

	if (
		!computedFilteredProducts ||
		!computedFilteredProducts.length ||
		!canAddProductWithAnotherAttributes(props.product)
	)
		return <></>;

	return (
		<Box py={1} mt={2} ref={variationsMainRef}>
			<Typography variant={"h5"} fontWeight={"bold"} sx={{ pl: 1 }}>
				{menu.addedOptionsHeader}
			</Typography>
			<Typography
				variant={"body2"}
				color={"text.secondary"}
				sx={{ pl: 1, mb: computedHasMultipleOptions ? 0 : 1 }}
			>
				{menu.optionsToEditSubHeader}
			</Typography>

			{computedHasMultipleOptions && (
				<Box pl={1} mb={1}>
					<DeleteProductsPopConfirmWrapper
						deleteSingle={false}
						productId={props.product.id}
					>
						<Typography
							variant={"body2"}
							sx={{
								a: {
									color: "error.main",
									textDecorationColor: "var(--mui-palette-error-main)",
								},
							}}
						>
							<TgLink className={"cursor-pointer"}>
								{menu.deleteAllProdOptionsBtn}
							</TgLink>
						</Typography>
					</DeleteProductsPopConfirmWrapper>
				</Box>
			)}

			<Box>
				{computedFilteredProducts.map((cartProduct, index) => (
					<ProductVariationItem
						key={cartProduct.id + index}
						selectedId={
							cartService.selectedVariationCartProductId ||
							computedDefaultSelected ||
							null
						}
						cartProduct={cartProduct}
						index={index}
					/>
				))}
			</Box>
			<Box
				ref={addNewRef}
				sx={{ pb: cartService.productVariationsState?.needToChooseMode ? 3 : 1 }}
			>
				<ProductVariationItem
					selectedId={null}
					product={props.product}
					cartProduct={null}
					addNew={true}
					addNewSelected={!!cartService.addingProductWithNewAttributesId}
					index={0}
				/>
			</Box>
		</Box>
	);
}

interface IProductVariationItemProps {
	selectedId: number | null;
	cartProduct: CartProduct | null;
	product?: Product;
	addNew?: boolean;
	addNewSelected?: boolean;
	index: number;
}

function ProductVariationItem(props: IProductVariationItemProps) {
	const {
		localisation: { menu },
	} = useAppContext();
	const { cartService } = useSelectedStoreContext();
	const isSelected = props.selectedId === props.cartProduct?.id || props.addNewSelected;

	const handleClick = () => {
		if (props.addNew && props.product) {
			if (isSelected) {
				cartService.updateProductVariationsState(true, false, false);
				cartService.setAddingProductWithNewAttributesId(null);
			} else {
				cartService.updateProductVariationsState(false, false, false);
				cartService.setAddingProductWithNewAttributesId(props.product.id);
			}
			cartService.updateProductForUpdate(0, null, null, true);
			cartService.setSelectedVariationCartProductId(null);
		}
		if (props.cartProduct) {
			if (isSelected) {
				cartService.updateProductVariationsState(true, false, false);
				cartService.setSelectedVariationCartProductId(null);
			} else {
				cartService.updateProductVariationsState(false, false, false);
				cartService.setSelectedVariationCartProductId(props.cartProduct.id);
			}
			cartService.updateProductForUpdate(props.cartProduct.id, null, null, true);
			cartService.setAddingProductWithNewAttributesId(null);
		}
	};

	const computedAttributesString = useMemo(() => {
		if (props.cartProduct) {
			return props.cartProduct.cart_attributes
				?.map(cpa => cpa.attribute.name + `(${cpa.quantity})`)
				.join(", ");
		}
		return "";
	}, [props.cartProduct]);

	const computedOptionCount = useMemo(() => {
		if (!props.addNew) {
			if (props.cartProduct) return props.cartProduct.quantity;
		}
		return 0;
	}, [props.addNew, props.cartProduct]);

	return (
		<Box>
			<ChipButton
				sx={props.addNew ? { mb: "4px" } : {}}
				chipParentBackground={true}
				noSelectBorder={true}
				mb={2}
				onClick={handleClick}
				selected={isSelected}
				color={"primary"}
				chipProps={{
					sx: {
						borderRadius: "0.7rem",
						fontSize: "0.875rem",
						display: "block",
						paddingX: 3,
						".MuiChip-label": {
							paddingLeft: 0,
							paddingRight: 0,
							whiteSpace: "wrap",
						},
					},
				}}
			>
				{props.addNew ? (
					<>
						<Typography variant={"h6"} fontWeight={"bold"}>
							<AddIcon sx={{ ml: "-4px", mr: "5px" }} />
							{menu.addWithOtherAttributes}
						</Typography>
						{!computedAttributesString && props.addNew && menu.addNewOptionSubheader}
					</>
				) : (
					<Box display={"flex"}>
						<Box alignSelf={"center"}>
							<Typography variant={"h6"} fontWeight={"bold"}>
								<EditIcon fontSize={"inherit"} sx={{ mr: 2 }} />
								{menu.editOptionHeader} {props.cartProduct?.product.name}
								{computedOptionCount > 1 && ` x${computedOptionCount}`}
							</Typography>
							{!!computedAttributesString && (
								<Typography variant={"body2"} color={"text.secondary"}>
									{computedAttributesString}
								</Typography>
							)}
						</Box>
						<Box
							alignSelf={"center"}
							ml={"auto"}
							onClick={(e: any) => e.stopPropagation()}
						>
							<DeleteProductsPopConfirmWrapper
								deleteSingle={true}
								cartProductId={props.cartProduct?.id}
							>
								<IconButton>
									<DeleteForeverIcon color={"error"} />
								</IconButton>
							</DeleteProductsPopConfirmWrapper>
						</Box>
					</Box>
				)}
			</ChipButton>
		</Box>
	);
}
