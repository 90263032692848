import { useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToHashElement() {
	let location = useLocation();

	let hashElement = useMemo(() => {
		let hash = location.hash;
		const removeHashCharacter = (str: string) => {
			return str.slice(1);
		};

		if (hash) {
			return document.getElementById(removeHashCharacter(hash));
		} else {
			return null;
		}
	}, [location]);

	useEffect(() => {
		console.log("hashElement", hashElement);
		if (!hashElement) return;

		window.scrollTo({
			top: hashElement.offsetTop,
			behavior: "smooth",
		});
	}, [hashElement]);

	return null;
}
