import { InfiniteData, useQuery, useQueryClient } from "@tanstack/react-query";
import useAppContext from "../../../useAppContext";
import api from "../../../api";
import { ProductsList } from "../../../api/shop/basic/types";
import getGetProductQueryKey from "./getGetProductQueryKey";

export default function useGetProduct(storeId: number, productId?: number | null | undefined) {
	const { brandInfo, lang } = useAppContext();
	const queryClient = useQueryClient();
	return useQuery({
		enabled: !!productId,
		queryKey: getGetProductQueryKey(storeId, productId, brandInfo?.id, lang),
		queryFn: ({ signal }) =>
			api.shop.basic
				.getProduct(
					storeId,
					productId!,
					{
						brand_id: brandInfo?.id,
					},
					signal
				)
				.then(({ data }) => data),
		placeholderData: productId
			? previousData => {
					if (previousData?.id === productId) return previousData;
					return (
						queryClient
							.getQueriesData<InfiniteData<ProductsList>>({
								queryKey: ["products"],
								exact: false,
							})
							.flatMap(([, data]) => data?.pages.flatMap(page => page.data) || [])
							.find(product => product.id === productId) ?? previousData
					);
				}
			: undefined,
	});
}
