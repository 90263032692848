import { ChangeEvent, useEffect, useRef } from "react";
import { MDBIcon } from "mdb-react-ui-kit";

import useLocalisation from "../../hooks/localisation/useLocalisation";
import { useSelectedStoreContext } from "../SelectedStore/context";
import useAppContext from "../../useAppContext";
import { useRecoilState } from "recoil";
import ProductsParamsState from "../services/useNewProductsService/ProductsParamsState";

export default function StoreSearchInput({ isFavorites = false }: { isFavorites?: boolean }) {
	const { appearance } = useAppContext();
	const localisation = useLocalisation();

	const { favoritesService } = useSelectedStoreContext();
	const [productsParamsState, setProductsParamsState] = useRecoilState(ProductsParamsState);

	const inputRef = useRef<HTMLInputElement | null>(null);
	const timerRef = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.value = productsParamsState.search_text ?? "";
		}
	}, [productsParamsState.search_text]);

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}
		timerRef.current = setTimeout(() => {
			if (isFavorites) {
				return favoritesService.setSearch(e.target.value);
			}
			setProductsParamsState(prev => ({ ...prev, search_text: e.target.value }));
		}, 250);
	};

	if (!appearance.computedNeedFilterSearch) return null;

	return (
		<>
			<div className={"input-bottom-border-search-icon"}>
				<MDBIcon fas icon={"search"} />
			</div>
			<input
				size={1}
				ref={inputRef}
				className={"input-bottom-border"}
				placeholder={localisation.menu.searchProducts}
				type="text"
				onChange={onChange}
			/>
		</>
	);
}
