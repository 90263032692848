import React, { useCallback, useEffect, useMemo, useState } from "react";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

import { NotAvailableProduct, Order } from "../../../../api/shop/order/types";
import { useSelectedStoreContext } from "../../../SelectedStore/context";
import Alert from "@mui/material/Alert";
import { Box, Button, Link, Typography } from "@mui/material";
import useAppContext from "../../../../useAppContext";
import changePage from "../../../../helpers/changePage";
import useScreenService from "../../../../services/useScreenService";
import ShopReferral from "../../../ShopReferral";
import { Check } from "../../../../features/Check";
import { ReviewProposal } from "../../../review";
import api from "../../../../api";
import SelectFriend from "../OrderStepper/SelectFriend";
import { IFriend } from "../../../../api/shop/friends/types";
import Interweave from "../../../../features/Interweave";
import OrderContentGift from "./OrderContentTypes/OrderContentGift";
import WalletIcon from "@mui/icons-material/Wallet";
import { TgLink } from "../../../../helpers/ThemeComponents";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import OrderContentTopup from "./OrderContentTypes/OrderContentTopup";
import { IUsePaymentsService } from "../../../payments/hooks/useNewPaymentsService";
import { PaymentsModal } from "../../../payments/Payment/Payments";

interface IOrderContentProps {
	order: Order;
	paymentService: IUsePaymentsService;
	orderToken?: string | null | undefined;
}

let confirmPayInterval: any = null;

export default function OrderContent(props: IOrderContentProps) {
	const {
		lang,
		localisation,
		groupService: { group },
		authService: { user },
		showError,
		brandInfo,
		scanReceiptService,
	} = useAppContext();
	const { isMobile } = useScreenService();

	const { selectedStore } = useSelectedStoreContext();

	const [notAvailableProducts, setNotAvailableProducts] = useState<NotAvailableProduct[]>([]);

	const notAvailableCallback = (products: NotAvailableProduct[]) => {
		setNotAvailableProducts(products);
	};

	const [friendName, setFriendName] = useState<string | null>(props.order.friend_pending_name);
	const [orderUserName, setOrderUserName] = useState<string | null>(null);
	const [viewOrder, setViewOrder] = useState<boolean>(true);
	const [showPayments, setShowPayments] = useState<boolean>(false);

	const isSameUser = props.order.user_id === user?.id;

	useEffect(() => {
		if (!isSameUser) setViewOrder(false);
	}, [isSameUser]);

	const setSums = props.paymentService.setSums;
	useEffect(() => {
		if (props.order.status_pay === "must_pay") {
			setSums({
				totalSum: props.order.total_sum,
				sumToPay: props.order.sum_to_pay,
			});
		}
	}, [props.order.status_pay, props.order.sum_to_pay, props.order.total_sum, setSums]);

	const computedPaymentName = useMemo(() => {
		if (props.order.order_payment) {
			if (props.order.order_payment.incust_account_name) {
				return props.order.order_payment.incust_account_name;
			}
			if (props.order.order_payment.payment_method === "friend") {
				return localisation.global.paymentFriendText;
			}
			if (
				props.order.order_payment.payment_method === "cash" &&
				props.order.shipment.base_type === "in_store" &&
				!props.order.order_payment.name
			) {
				return localisation.global.paymentLaterText;
			}
			return props.order.order_payment.name;
		}
		return "";
	}, [
		props.order.order_payment,
		props.order.shipment.base_type,
		localisation.global.paymentFriendText,
		localisation.global.paymentLaterText,
	]);

	const computedDeliveryName = useMemo(() => {
		if (!!props.order.shipment.name) return props.order.shipment.name;

		switch (props.order.shipment.base_type) {
			case "delivery":
				return localisation.global.deliveryText;
			case "in_store":
				return localisation.global.inStoreText;
			case "pickup":
				return localisation.global.pickupText;
			default:
				return "";
		}
	}, [
		localisation.global.deliveryText,
		localisation.global.inStoreText,
		localisation.global.pickupText,
		props.order.shipment.base_type,
		props.order.shipment.name,
	]);

	const computedAddress = useMemo(() => {
		const text = [
			props.order.delivery_address,
			props.order.address_flat,
			props.order.address_floor,
			props.order.address_entrance,
			props.order.address_comment,
		]
			.filter(x => !!x)
			.join(", ");
		if (!text && props.order.shipment.base_type === "pickup") {
			return (
				selectedStore.custom_fields.find(x => x.name === "address")?.value?.toString() || ""
			);
		}
		return text;
	}, [
		props.order.address_comment,
		props.order.address_entrance,
		props.order.address_flat,
		props.order.address_floor,
		props.order.delivery_address,
		props.order.shipment.base_type,
		selectedStore.custom_fields,
	]);

	const waitConfirmPay = useCallback(() => {
		confirmPayInterval = setInterval(async () => {
			try {
				const response = await api.shop.basic.checkPaymentStatus(
					props.order.token,
					props.order.id
				);
				if (props.order.status_pay !== response.data.payment_status) {
					props.order.status_pay = response.data.payment_status;
					changePage(`/shop/${selectedStore.id}/orders/${props.order.id}`);

					if (response.data.payment_status === "payed") {
						clearInterval(confirmPayInterval);
					}
				}
			} catch (err) {
				console.log(err);
				showError(err || "unknown error");
			}
		}, 5000);
	}, [props.order, selectedStore.id, showError]);

	useEffect(() => {
		if (
			props.order.order_payment?.payment_method === "friend" &&
			props.order.status_pay !== "payed"
		) {
			waitConfirmPay();
		}
		return () => {
			clearInterval(confirmPayInterval);
		};
	}, [props.order.order_payment?.payment_method, props.order.status_pay, waitConfirmPay]);

	const handleSelectFriendEnd = (friend: IFriend) => {
		setFriendName(friend.name);
	};

	useEffect(() => {
		const load = async () => {
			if (user && !isSameUser) {
				const response = await api.shop.friends.getFriend(props.order.user_id);
				setOrderUserName(response.data.name);
			}
		};
		load().then();
	}, [user, props.order.user_id, user?.id, isSameUser]);

	const computedFriendHeader = useMemo(() => {
		if (
			props.order.order_payment?.payment_method === "friend" &&
			props.order.status_pay !== "payed" &&
			!isSameUser
		) {
			return (
				<Alert severity={"warning"} sx={{ mt: 2 }}>
					{localisation.orders.payFriendHeader.replace("{name}", orderUserName || "")}
				</Alert>
			);
		}
		return "";
	}, [
		props.order.order_payment?.payment_method,
		props.order.status_pay,
		isSameUser,
		localisation.orders.payFriendHeader,
		orderUserName,
	]);

	const computedFriendStatus = useMemo(() => {
		if (
			props.order.order_payment?.payment_method !== "friend" ||
			props.order.status_pay === "payed"
		)
			return "";

		if (isSameUser)
			return (
				<>
					<Alert severity={"warning"} sx={{ mt: 2 }}>
						{localisation.orders.payWaitToPayFriend + " " + friendName}
					</Alert>

					<SelectFriend
						orderId={props.order.id}
						onSelectFriendEnd={handleSelectFriendEnd}
						paymentService={props.paymentService}
					/>
				</>
			);
	}, [
		props.order.order_payment?.payment_method,
		props.order.status_pay,
		props.order.id,
		props.paymentService,
		isSameUser,
		localisation.orders.payWaitToPayFriend,
		friendName,
	]);

	const setPaymentNoLongerAvailable = props.paymentService.setPaymentNoLongerAvailable;
	const setSelectedProvider = props.paymentService.setSelectedProvider;
	useEffect(() => {
		if (props.order.order_payment && props.order.user_id === user?.id) {
			const provider = props.paymentService.methods.find(
				x => x.settings_id === props.order.order_payment?.payment_settings_id
			);
			if (provider) {
				setPaymentNoLongerAvailable(false);
				if (!props.paymentService.selectedProvider) {
					setSelectedProvider(provider);
				}
			} else {
				setPaymentNoLongerAvailable(true);
			}
		}
	}, [
		props.order.order_payment,
		props.order.user_id,
		props.paymentService,
		setPaymentNoLongerAvailable,
		setSelectedProvider,
		user?.id,
	]);

	return (
		<Box sx={props.order.type === "regular" ? { p: 3 } : {}}>
			{computedFriendHeader}
			{props.order.type === "regular" && (
				<Check
					data={{
						header: selectedStore.name,
						date: new Date(props.order.create_date).toLocaleString(lang || "en", {
							year: "numeric",
							month: "2-digit",
							day: "2-digit",
							hour: "2-digit",
							minute: "2-digit",
							timeZone: group?.timezone,
						}),
						status: props.order.status,
						status_pay: props.order.status_pay,
						showStatusPay: true,
						toName:
							props.order.first_name && props.order.last_name
								? `${props.order.first_name} ${props.order.last_name}`
								: props.order.first_name || props.order.last_name,
						email: props.order.email,
						phone: props.order.phone,
						paymentMethodName: computedPaymentName,
						paymentMethodCommentLabel: props.order.order_payment?.label_comment,
						paymentMethodComment: props.order.order_payment?.comment,
						paymentMethodInfoLabel: props.order.order_payment?.label_comment,
						paymentMethodInfo: props.order.order_payment?.post_payment_info,
						deliveryMethodName: computedDeliveryName,
						deliveryMethodBaseType: props.order.shipment.base_type,
						deliveryPickupPointName: selectedStore.name,
						deliveryAddress: computedAddress,
						deliveryMethodCommentLabel: props.order.shipment.label_comment,
						deliveryMethodComment: props.order.shipment.comment,
						desiredDeliveryDate:
							props.order.desired_delivery_date &&
							new Date(props.order.desired_delivery_date).toLocaleDateString(
								lang || "en",
								{
									year: "numeric",
									month: "2-digit",
									day: "2-digit",
								}
							) +
								(props.order.shipment.delivery_datetime_mode === "datetime"
									? ` ${props.order.desired_delivery_time}`
									: ""),
						comment: props.order.comment,
						items: props.order.order_products.map(orderProduct => ({
							code: orderProduct.product.product_id,
							name: orderProduct.product.name,
							quantity: orderProduct.quantity,
							price: orderProduct.price,
							sum:
								Math.round(
									(orderProduct.price * orderProduct.quantity + Number.EPSILON) *
										100
								) / 100,
							discount_sum: orderProduct.discount_sum,
							onClick: () =>
								changePage(
									`/shop/${selectedStore.id}/menu?product_id=${orderProduct.product.id}`
								),
							thumbnail_url: orderProduct.product.thumbnail_url,
							attributes: orderProduct.attributes.map(attribute => ({
								code: attribute.attribute_code,
								name: attribute.name,
								quantity: attribute.quantity,
								price: attribute.price_impact,
								sum:
									Math.round(
										(attribute.price_impact * attribute.quantity +
											Number.EPSILON) *
											100
									) / 100,
							})),
						})),
						subtotal: props.order.order_products.reduce(
							(acc, orderProduct) => acc + orderProduct.before_loyalty_sum,
							0
						),
						totalDiscount: props.order.discount_and_bonuses,
						paymentPrice: props.order.order_payment?.price,
						deliveryPrice: props.order.shipment.price,
						isDeliveryPaymentSeparately: props.order.shipment.is_paid_separately,
						totalAmount: props.order.total_sum,
						tips: props.order.tips_sum,
						totalAmountWithTips: props.order.sum_to_pay,
						isLoyaltyAwards:
							!user || isSameUser
								? !!props.order.original_incust_loyalty_check
										?.bonuses_added_amount ||
									!!props.order.original_incust_loyalty_check
										?.special_accounts_charges?.length ||
									!!props.order.original_incust_loyalty_check?.emitted_coupons
										?.length
								: undefined,
						bonuses:
							!user || isSameUser
								? props.order.original_incust_loyalty_check?.bonuses_added_amount
								: null,
						coupons: !user || isSameUser ? props.order.incust_vouchers : null,
						customerAccounts:
							!user || isSameUser
								? props.order.original_incust_loyalty_check
										?.special_accounts_charges
								: null,
						viewOrder: viewOrder,
						addressLat: props.order.address_lat,
						addressLng: props.order.address_lng,
						placeId: props.order.address_place_id,
						type: props.order.type,
						token: props.order.token,
						payer_fee: props.order.payer_fee,
						payer_fee_row: props.order.payer_fee,
						paid_sum: props.order.paid_sum || props.order.sum_to_pay || 0,
						extraFee: props.order.extraFee || [],
						total_sum_with_extra_fee: props.order.total_sum_with_extra_fee || 0,
					}}
				/>
			)}

			{props.order.type === "gift" && <OrderContentGift order={props.order} />}

			{props.order.type === "topup" && <OrderContentTopup order={props.order} />}

			<Box sx={props.order.type === "regular" ? {} : { px: 3, pb: 3 }}>
				{props.order.status !== "canceled" &&
					props.order.status !== "terminated_by_user" && (
						<>
							{brandInfo?.scan_receipts_settings?.enabled &&
								props.order.type === "regular" && (
									<Box mt={2}>
										<Typography color={"text.secondary"}>
											{localisation.receipt.scanReceiptOrderHeader}
										</Typography>
										<TgLink
											onClick={() => {
												scanReceiptService.setShowModal(true);
												scanReceiptService.setOrderId(props.order.id);
											}}
										>
											<QrCodeScannerIcon sx={{ mr: 2 }} />
											{localisation.receipt.scanButton}
										</TgLink>
									</Box>
								)}
						</>
					)}

				<Box mt={2}>
					{props.order.incust_vouchers && (
						<Box>
							<TgLink
								onClick={() =>
									changePage(`/shop/${selectedStore.id}/profile/loyalty`)
								}
							>
								<WalletIcon sx={{ mr: 2 }} />
								{localisation.global.showWallet}
							</TgLink>
						</Box>
					)}

					{props.order.type === "gift" && (
						<Alert
							severity={"success"}
							sx={{ mt: 2 }}
							icon={<CardGiftcardOutlinedIcon />}
						>
							{localisation.orders.thanksForOrderGift}
						</Alert>
					)}
				</Box>

				{props.order.status_pay === "payed" &&
					props.order.type !== "gift" &&
					props.order.type !== "topup" && (
						<Alert severity={"success"} sx={{ mt: 2 }}>
							{localisation.orders.paySuccess}
						</Alert>
					)}

				{props.order.status_pay !== "payed" && <>{computedFriendStatus}</>}

				<Box mt={2} textAlign={"start"}>
					{!isSameUser && (
						<Button
							className={isMobile ? "w-100 mb-2" : ""}
							sx={{ mr: isMobile ? 0 : 2 }}
							onClick={() => {
								setViewOrder(!viewOrder);
							}}
						>
							{!viewOrder
								? localisation.orders.viewOrderButton
								: localisation.orders.hideOrderButton}
						</Button>
					)}

					{!props.paymentService.checkIsOrderPaymentIsOnlyOne(
						props.order.order_payment?.payment_settings_id
					) &&
						props.order.status_pay !== "payed" &&
						(props.order.status === "new" ||
							props.order.status === "open_unconfirmed" ||
							props.order.status === "open_confirmed") && (
							<>
								{!(notAvailableProducts.length > 0) && (
									<Box
										width={"100%"}
										sx={{
											textAlign:
												props.order.order_payment?.payment_method ===
													"friend" && !isSameUser
													? "center"
													: "start",
										}}
										ml={2}
									>
										{props.order.order_payment?.payment_method === "friend" &&
										!isSameUser ? (
											<Button
												variant={"contained"}
												fullWidth
												size={"large"}
												onClick={() => setShowPayments(true)}
											>
												{localisation.orders.payButton}
											</Button>
										) : (
											<Link
												type={"button"}
												// size={"large"}
												onClick={() => setShowPayments(true)}
												className={isMobile ? "w-100" : ""}
											>
												{localisation.orders.payOtherMethodButton}
											</Link>
										)}
									</Box>
								)}

								{showPayments && (
									<PaymentsModal
										show={showPayments}
										setShow={setShowPayments}
										orderId={props.order.id}
										orderToken={props.orderToken}
										type={"order"}
										selectedStoreId={selectedStore.id}
										paymentService={props.paymentService}
										createdOrder={props.order}
										onlyOnlinePayments={
											props.order.order_payment?.payment_method === "friend"
										}
									/>
								)}
							</>
						)}
					{notAvailableProducts.length > 0 && (
						<div className={"small fw-bold text-danger mt-2"}>
							<Interweave
								content={localisation.orders.cantPayCauseAvailability.replace(
									"{products}",
									notAvailableProducts.map(p => p.name).join(", ")
								)}
							/>
						</div>
					)}

					<ShopReferral align={"start"} />
					{isSameUser && <ReviewProposal mt={4} fontSize={"1rem"} />}
				</Box>
			</Box>
		</Box>
	);
}
