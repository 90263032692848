import useSearchParamsFixed from "../../../features/hooks/useSearchParamsFixed";
import { SelectedProductParams } from "./types";
import { useMemo } from "react";

export default function useSelectedProductParams() {
	const [searchParams] = useSearchParamsFixed();
	return useMemo(() => {
		return SelectedProductParams.parse(Object.fromEntries(searchParams.entries()));
	}, [searchParams]);
}
