const isLocaleClockType24h = (
	languageOrLocale?: string | undefined, // can be language code or full locale
	countryCode?: string | null | undefined // if languageOrLocale is full locale, will be ignored
) => {
	// "In basic use without specifying a locale, DateTimeFormat
	// uses the default locale and default options."
	// Ref: developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat#using_datetimeformat
	// To be sure of the browser's language (set by the user
	// and may be different than the operating system's default language)
	// set the 'languages' parameter to 'navigator.language'.
	// E.g. isBrowserLocaleClockType24h(navigator.language);
	if (!languageOrLocale) {
		languageOrLocale = "en";
	}

	const getResultFromLocale = (locale: string) => {
		try {
			const hourCycle = new Intl.Locale(locale).hourCycle;
			if (!hourCycle) return null;
			return ["h23", "h24"].includes(hourCycle);
		} catch {
			return null;
		}
	};

	let result: boolean | null = null;

	if (countryCode && !languageOrLocale.includes("-")) {
		result = getResultFromLocale(
			`${languageOrLocale.toLowerCase()}-${countryCode.toUpperCase()}`
		);
	}
	if (!result) {
		result = getResultFromLocale(languageOrLocale.toLowerCase());
	}
	if (result) return result;

	// The value of 'hr' will be in the format '0', '1', ... up to '24'
	// for a 24-hour clock type (depending on a clock type of
	// 'h23' or 'h24'. See:
	// developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale
	// Intl.Locale.prototype.hourCycles
	// Returns an Array of hour cycle identifiers, indicating either
	// the 12-hour format ("h11", "h12") or
	// the 24-hour format ("h23", "h24").

	// A 12-hour clock type value has the format '7 AM', '10 PM', or
	// '7 π.μ.' (if the locale language is Greek as specified
	// by the two letter ISO 639-1 code "el" or
	// three letter ISO 639-2 code "ell").

	const hr = new Intl.DateTimeFormat(languageOrLocale, { hour: "numeric" }).format();

	// If there's no space in the value of the 'hr' variable then
	// the value is a string representing a number between and
	// can include '0' and '24'. See comment above regarding "hourCycles".
	// Return 'true' if a space exists.
	//if (!hr.match(/\s/)) { return true; }
	// Or simply:
	// return !hr.match(/\s/);

	// Alternatively, check if the value of 'hr' is an integer.
	// E.g. Number.isInteger(Number('10 AM')) returns 'false'
	// E.g. Number.isInteger(Number('7 π.μ.')) returns 'false'
	// E.g. Number.isInteger(Number('10')) returns 'true'
	return Number.isInteger(Number(hr));
};
export { isLocaleClockType24h };
