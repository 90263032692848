import { useEffect, useMemo } from "react";
import { useSelectedStoreContext } from "../../SelectedStore/context";
import { ViewServiceType } from "../../../services/useViewService";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { filterBarVisibleState, productsViewTypeState } from "../state";
import useAppContext from "../../../useAppContext";
import ProductsParamsState from "../../services/useNewProductsService/ProductsParamsState";

export default function useFiltersBarSettings(
	viewService: ViewServiceType
): IUseFiltersBarSettings {
	const { appearance } = useAppContext();
	const { categoriesService, storeFilters } = useSelectedStoreContext();
	const productsParamsState = useRecoilValue(ProductsParamsState);
	const setFiltersBarVisibleState = useSetRecoilState(filterBarVisibleState);
	const setProductsViewType = useSetRecoilState(productsViewTypeState);

	const activeCategory = categoriesService.getActiveCategory();
	const filters = activeCategory?.filters;
	const needFiltersButton =
		!!filters || appearance.computedNeedFilterByPrice || !!storeFilters?.length;

	const computedIsFiltersBarVisible = useMemo(() => {
		return !(
			!appearance.computedNeedFilterSearch &&
			!viewService.toggleEnabled &&
			!needFiltersButton &&
			(!appearance.computedNeedFilterSort || productsParamsState.search_text)
		);
	}, [
		needFiltersButton,
		productsParamsState.search_text,
		appearance.computedNeedFilterSearch,
		appearance.computedNeedFilterSort,
		viewService.toggleEnabled,
	]);

	useEffect(() => {
		console.log("*** computedIsFiltersBarVisible", computedIsFiltersBarVisible);
		console.log("*** viewService.view", viewService.view);
		setFiltersBarVisibleState(computedIsFiltersBarVisible);
		setProductsViewType(viewService.view);
	}, [
		computedIsFiltersBarVisible,
		setFiltersBarVisibleState,
		setProductsViewType,
		viewService.view,
	]);

	return {
		needFiltersButton,
		viewService: viewService,
		searchEnabled: appearance.computedNeedFilterSearch,
		computedIsFiltersBarVisible,
	};
}

export interface IUseFiltersBarSettings {
	needFiltersButton: boolean;
	viewService: ViewServiceType;
	searchEnabled: boolean;
	computedIsFiltersBarVisible: boolean;
}
