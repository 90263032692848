import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../api";
import { DeleteUserAccountData } from "../../../api/auth/types";
import useAppContext from "../../../useAppContext";

export default function useDeleteAccount() {
	const {
		authService: { logout },
	} = useAppContext();

	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (payload: DeleteUserAccountData) =>
			api.auth.deleteAccount(payload).then(({ data }) => data),
		onSuccess: () => {
			logout();
			queryClient.invalidateQueries();
		},
	});
}
